export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },
  nav: {
    academy: "nav.academy",
    affiliate: "nav.affiliate",
    supportChats: "nav.supportChats",
    emails: "nav.emails",
    account: "nav.account",
    agents: "nav.agents",
    settings: "nav.settings",
    analytics: "nav.analytics",
    brands: "nav.brands",
    bankProvider: "nav.bankProvider",
    reports: "nav.reports",
    auth: "nav.auth",
    article: "nav.article",
    blog: "nav.blog",
    browse: "nav.browse",
    calendar: "nav.calendar",
    chat: "nav.chat",
    internalChat: "nav.internalChat",
    checkout: "nav.checkout",
    concepts: "nav.concepts",
    contact: "nav.contact",
    contactUs: "nav.contactUs",
    orderNow: "nav.orderNow",
    logs: "nav.logs",
    course: "nav.course",
    create: "nav.create",
    crypto: "nav.crypto",
    customers: "nav.customers",
    call: "nav.call",
    dashboard: "nav.dashboard",
    details: "nav.details",
    demo: "nav.demo",
    dataEntry: "nav.dataEntry",
    ecommerce: "nav.ecommerce",
    edit: "nav.edit",
    error: "nav.error",
    injection: "nav.injection",
    offers: "nav.offers",
    feed: "nav.feed",
    fileManager: "nav.fileManager",
    files: "nav.files",
    finance: "nav.finance",
    fleet: "nav.fleet",
    forgotPassword: "nav.forgotPassword",
    integration: "nav.integration",
    invoiceList: "nav.invoices",
    jobList: "nav.jobList",
    kanban: "nav.kanban",
    lead: "nav.lead",
    leaderboard: "nav.leaderboard",
    list: "nav.list",
    login: "nav.login",
    logistics: "nav.logistics",
    wallets: "nav.wallets",
    mail: "nav.mail",
    management: "nav.management",
    marketing: "nav.marketing",
    merchant: "nav.merchant",
    orderList: "nav.orders",
    overview: "nav.overview",
    pages: "nav.pages",
    postCreate: "nav.postCreate",
    paymentService: "nav.paymentService",
    payment: "nav.payment",
    paymentType: "nav.paymentType",
    postDetails: "nav.postDetails",
    postList: "nav.postList",
    pricing: "nav.pricing",
    productList: "nav.products",
    profile: "nav.profile",
    risk: `nav.risk`,
    positions: `nav.positions`,
    transactions: `nav.transactions`,
    walletTransactions: `nav.walletTransactions`,
    record: `nav.record`,
    register: "nav.register",
    lawFirms: "nav.lawFirms",
    resetPassword: "nav.resetPassword",
    socialMedia: "nav.socialMedia",
    leads: "nav.leads",
    title: "nav.title",
    verifyCode: "nav.verifyCode",
    validationRules: "nav.validationRules",
    home: "nav.home",
    products: "nav.products",
    aboutUs: "nav.aboutUs",
    customerCare: "nav.customerCare",
    complianceRegulationAudit: "nav.complianceRegulationAudit",
    compliance: "nav.compliance",
    agentReport: "nav.agentReport",
    clientReport: "nav.clientReport",
    regulartoryReport: "nav.regulartoryReport",
  },
};
