export const SUPPORTCHATS = {
  CHATS: [
    {
      account_id: null,
      agent_ids: [54, 129],
      call_accounts: [],
      call_teams: [],
      calling: false,
      client_company_id: null,
      client_email: false,
      client_id: 88,
      client_label_ids: [0],
      client_name: "Olivia Martin",
      avatar: "/assets/avatars/avatar-miron-vitold.png",
      client_support: true,
      company_id: 1,
      conversation_id: 1513,
      created_at: "2024-08-28T18:01:43.390Z",
      desk_id: 3,
      first_name_d: "t00",
      followed_ids: [1, 6, 168, 5, 62],
      full_name_d: "Olivia Martin",
      id: 1483,
      in_call: false,
      internal_id: null,
      label_ids: [5],
      labels: [{
        account_id: null,
        color: "#000000",
        company_id: 1,
        created_at: "2024-08-28T18:01:43.390Z",
        default: true,
        general_team: true,
        id: 5,
        lft: null,
        name: "Support",
        updated_at: "2024-08-28T18:01:43.390Z",
      }],
      last_call: null,
      last_message: "Please try again. it will be good.",
      last_message_at: "2024-08-28T18:01:43.390Z",
      last_miss_call_at: null,
      last_name_d: "asdf",
      miss_call: false,
      open: true,
      participant_accounts: [],
      pending: false,
      priority: 1,
      product_id: null,
      rate: null,
      reminded_at: [],
      sort: ['we will check asap'],
      starred_ids: [],
      stringified_id: "",
      subject: "Support Chat",
      team: null,
      team_id: null,
      team_ids: [0],
      token: null,
      unassigned: true,
      unread: [],
      unread_count: 0,
      updated_at: "2024-08-28T18:01:43.390Z",
    }, 
  ],
  CLIENTS: {
    affiliate: "website form",
    client: {
      avatar: '/assets/avatars/avatar-miron-vitold.png',
      country: "GB",
      full_name: "Olivia Martin",
    },
    client_agents: [{
      avatar: null,
      id: 54,
      name: "demo account",
      teams: ['DEV0022']
    }, {
      avatar: null,
      id: 129,
      name: "as cas",
      teams: []
    }],
    client_company: null,
    client_fields: { 5: 'ull', 23: 'ull', 24: 'ull' },
    client_labels: [],
    client_teams: [],
    dashboard_domain: "app.octolit.com/login?token=168bec929ad00ecf8176c20ddd661df9edc5be2a2d2530e6559c6b74ba539989d809e2e8d7cb3f4181e211dac11891ea74e259acf02376700c1a9faa9b1ee14e",
    email_conversation_id: 1510,
    emails: [
      {
        id: 70,
        value: "te2st@gmail.com"
      },
      {
        id: 115,
        value: "test@test.test"
      },
      {
        id: 116,
        value: "asdf@fdsafsa"
      }
    ],
    leads: [{
      account_id: 79,
      account_name: "tezst ",
      agent_id: null,
      agent_name: " ",
      brand_id: null,
      brand_name: null,
      brand_status: "",
      campaign: "",
      campaign_error: null,
      campaign_validated: true,
      client_id: 88,
      client_name: "fsdafa asdf",
      company_id: 1,
      country: null,
      created_at: "2024-08-28T18:01:43.390Z",
      deposit: null,
      deposit_error: null,
      deposit_f: 0,
      deposit_validated: true,
      description: "Description",
      description_error: null,
      description_validated: true,
      desk_id: null,
      duplicate: true,
      email: "asdf@fdsafsa",
      email_error: "Email is invalid",
      email_validated: false,
      first_name: "fsdafa",
      ftd_amount: null,
      ftd_amount_error: null,
      ftd_amount_f: 0,
      ftd_amount_validated: true,
      ftd_date: null,
      ftd_date_error: null,
      ftd_date_validated: true,
      ftd_update_date: null,
      id: 5270,
      ip_address: null,
      ip_address_error: null,
      ip_address_validated: true,
      label_ids: null,
      labels: null,
      language: null,
      language_error: null,
      language_validated: true,
      last_ftd_updated_at: null,
      last_name: "asdf",
      lead_fields: null,
      lead_file_id: null,
      lead_file_internal_id: null,
      note: "",
      phone: "+380939213877",
      phone_error: null,
      phone_validated: true,
      registration_date: null,
      registration_date_error: null,
      registration_date_validated: true,
      sort: [5270],
      source: "API injection",
      source_brand: "",
      status: "Error",
      status_error: "Could not find a right brand for this lead. check Your brands configuration!",
      team_id: null,
      team_name: null,
      updated_at: "2024-08-28T18:01:43.390Z",
      verified: false,
      verified_error: "Wrong data!",
      _id: "5270",
      _index: "leads_development_20240705102106731",
      _score: null,
      _type: "_doc"
    }],
    link: "live.octolit.com/login?token=168bec929ad00ecf8176c20ddd661df9edc5be2a2d2530e6559c6b74ba539989d809e2e8d7cb3f4181e211dac11891ea74e259acf02376700c1a9faa9b1ee14e",
    password: "123",
    phone_numbers: [{
      id: 128, value: '+447778838310', timezone: [
        "Europe/Guernsey",
        "Europe/Isle_of_Man",
        "Europe/London"]
    }],
    phone_status: {
      area_code: null,
      carrier: "Vodafone",
      country: "GB",
      geo_name: null,
      human_type: "Mobile",
      local_number: "838310"
    },
    reaction_time: "No first call!",
    ticket_accounts: [],
    ticket_count: 6,
    total_deposit: "0.0",
    total_withdrawal: "0.0"
  },
  THREADS: {
    aggs: null,
    messages: [{
      account: {
        active: true,
        ai: false,
        avatar: null,
        first_name: "Navid",
        id: 1,
        last_name: "F.",
        on_duty: false,
        timezone: "Asia/Kuwait"
      },
      account_i: 1,
      call_summer: null,
      channel_i: null,
      channel_typ: 1,
      clien: null,
      client_i: null,
      company_i: 1,
      conversation_i: 1513,
      created_a: "2024-08-28T18:01:43.390Z",
      delete: false,
      descriptio: "hi",
      files_url: null,
      flagged_transcrip: null,
      html_descriptio: null,
      i: 1519,
      mi: null,
      not: false,
      raw_descriptio: null,
      repl: null,
      reply_i: null,
      sent_emai: false,
      sm: false,
      sor: [1729528442991],
      syste: false,
      system_even: null,
      system_event_accoun: null,
      system_event_account_i: null,
      transcript_dat: null,
      transcript_topi: null,
      updated_a: "2024-08-28T18:01:43.390Z",
      visito: null,
      visitor_i: null,
      _i: "1519",
      _inde: "messages_development_20241018065109336",
      _scor: null,
      _typ: "_doc"
    }, {
      account: {
        active: true,
        ai: false,
        avatar: null,
        first_name: "Navid",
        id: 1,
        last_name: "F.",
        on_duty: false,
        timezone: "Asia/Kuwait"
      },
      account_i: 1,
      call_summer: null,
      channel_i: null,
      channel_typ: 1,
      clien: null,
      client_i: null,
      company_i: 1,
      conversation_i: 1513,
      created_a: "2024-08-28T18:01:43.390Z",
      delete: false,
      descriptio: "hi",
      files_url: null,
      flagged_transcrip: null,
      html_descriptio: null,
      i: 1519,
      mi: null,
      not: false,
      raw_descriptio: null,
      repl: null,
      reply_i: null,
      sent_emai: false,
      sm: false,
      sor: [1729528442991],
      syste: false,
      system_even: null,
      system_event_accoun: null,
      system_event_account_i: null,
      transcript_dat: null,
      transcript_topi: null,
      updated_a: "2024-08-28T18:01:43.390Z",
      visito: null,
      visitor_i: null,
      _i: "1519",
      _inde: "messages_development_20241018065109336",
      _scor: null,
      _typ: "_doc"
    }],
    next_page: null,
    took: 0,
    total_count: 2,
    total_pages: 1
  },
  TICKET: {
    client_phone_numbers
      :
      [{
        country: "GB",
        id: 128,
        name: "+447778838310"
      }],
    email: "asdf@fdsafsa",
    starred: false,
    ticket: {
      account_id: null,
      auto_assigned: false,
      call_joined: false,
      calling: false,
      client_company_id: null,
      client_id: 88,
      company_id: 1,
      conversation: {
        acc_add_admin: false,
        acc_add_member: true,
        acc_delete_all_message: true,
        acc_delete_conversation: true,
        acc_delete_own_message: true,
        acc_edit_all_message: true,
        acc_edit_avatar: true,
        acc_edit_chat_history: true,
        acc_edit_delete_duration: false,
        acc_edit_name: true,
        acc_edit_own_message: true,
        acc_edit_public: false,
        acc_edit_titles: false,
        acc_export_chat_history: true,
        acc_pin_messages: true,
        acc_remove_admin: false,
        acc_remove_member: false,
        acc_send_media: true,
        acc_send_message: true,
        ai_hold_at: null,
        ai_party: false,
        ai_wait: false,
        auto_delete: null,
        avatar: null,
        call_destination: null,
        call_sid: null,
        chat_history: null,
        client_call: false,
        company_id: 1,
        conferences_sid: null,
        conversation_count: 0,
        conversation_type: 2,
        created_at: "2024-10-21T16:34:02.885Z",
        delete_duration: null,
        id: 1513,
        internal_conferences_sid: null,
        name: "Support Chat",
        public: false,
        sent_notification: false,
        summery: null,
        ticket_call: false,
        title_edited: false,
        token: "a428eb28fd12379b1c345908d8ccc83025cd1cfc47ae5d33137d3d4ffd53",
        updated_at: "2024-10-21T16:34:02.885Z",
        visitor_call: false
      },
      conversation_id: 1513,
      created_at: "2024-10-21T16:34:02.885Z",
      default: true,
      dial_count: null,
      facebook_page_id: null,
      id: 1483,
      in_call: false,
      inbound_call: false,
      internal_id: null,
      labels: [{
        account_id: null,
        color: "#000000",
        company_id: 1,
        created_at: "2024-10-21T16:34:02.885Z",
        default: true,
        general_team: true,
        id: 5,
        lft: null,
        name: "Support",
        updated_at: "2024-10-21T16:34:02.885Z",
      }],
      last_call: null,
      last_dial_at: null,
      last_miss_call_at: null,
      miss_call: false,
      online: false,
      open: true,
      pending: false,
      priority: 1,
      product_id: null,
      rate: null,
      rate_account: null,
      rate_description: null,
      subject: "Support Chat",
      team_id: null,
      unassigned: true,
      updated_at: "2024-10-21T16:34:02.885Z",
    }
  },
  ACCOUNT: [{
    acc_add_admin: false,
    acc_add_member: true,
    acc_delete_all_message: true,
    acc_delete_conversation: true,
    acc_delete_own_message: true,
    acc_edit_all_message: true,
    acc_edit_avatar: true,
    acc_edit_chat_history: true,
    acc_edit_delete_duration: false,
    acc_edit_members_access: false,
    acc_edit_name: true,
    acc_edit_own_message: true,
    acc_edit_public: false,
    acc_edit_titles: false,
    acc_export_chat_history: true,
    acc_pin_messages: true,
    acc_remove_admin: false,
    acc_remove_member: false,
    acc_send_media: true,
    acc_send_message: true,
    account_id: 62,
    avatar: "/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaTBDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--109ee2ced1f138a64ae0dff31a3f7c7497e544a5/localhost_3000_dashboard_account.png",
    client_id: null,
    company_id: 1,
    conversation_id: 1513,
    created_at: "2025-01-05T13:17:10.465Z",
    email: "jianli@octolit.com",
    full_name: "jian li",
    hold: false,
    id: 559,
    in_call: false,
    incognito: false,
    last_message_at: null,
    mute: false,
    notification: true,
    owner: false,
    participant: false,
    participant_label: null,
    sort: [-9223372036854776000],
    speech: false,
    updated_at: "2025-01-05T13:17:10.465Z",
    visitor_id: null,
    _id: "559",
    _index: "conversation_accounts_development_20240827165646651",
    _score: null,
    _type: "_doc"
  }],
  CONTENT: [{
    account:
    {
      active: true,
      ai: false,
      avatar: null,
      first_name: "Navid",
      id: 1,
      last_name: "F.",
      on_duty: false,
      timezone: "Asia/Kuwait",
    },
    account_id: 1,
    call_summery: null,
    channel_id: null,
    channel_type: 1,
    client: null,
    client_id: null,
    company_id: 1,
    conversation_id: 1513,
    created_at: "2024-10-21T16:34:02.991Z",
    deleted: false,
    description: "hi",
    files_urls: null,
    flagged_transcript: null,
    html_description: null,
    id: 1519,
    mid: null,
    note: false,
    raw_description: null,
    reply: null,
    reply_id: null,
    sent_email: false,
    sms: false,
    sort: [1729528442991],
    system: false,
    system_event: null,
    system_event_account: null,
    system_event_account_id: null,
    transcript_data: null,
    transcript_topic: null,
    updated_at: "2024-10-21T16:34:02.991Z",
    visitor: null,
    visitor_id: null,
    _id: "1519",
    _index: "messages_development_20241018065109336",
    _score: null,
    _type: "_doc",
  }, {
    account: {
      active: true,
      ai: false,
      avatar: null,
      first_name: "Navid",
      id: 1,
      last_name: "F.",
      on_duty: false,
      timezone: "Asia/Kuwait",
    },
    account_id: 1,
    call_summery: null,
    channel_id: null,
    channel_type: 1,
    client: null,
    client_id: null,
    company_id: 1,
    conversation_id: 1513,
    created_at: "2024-10-21T16:34:13.885Z",
    deleted: false,
    description: "I have one question about crm",
    files_urls: null,
    flagged_transcript: null,
    html_description: null,
    id: 1522,
    mid: null,
    note: false,
    raw_description: null,
    reply: null,
    reply_id: null,
    sent_email: false,
    sms: false,
    sort: [1729528453885],
    system: false,
    system_event: null,
    system_event_account: null,
    system_event_account_id: null,
    transcript_data: null,
    transcript_topic: null,
    updated_at: "2024-10-21T16:34:13.885Z",
    visitor: null,
    visitor_id: null,
    _id: "1522",
    _index: "messages_development_20241018065109336",
    _score: null,
    _type: "_doc",
  }]
  //   CUSTOMERINFO:{
  //     affiliate
  // : 
  // "website form"
  // client
  // : 
  // {about_d
  //   : 
  //   null
  //   active
  //   : 
  //   true
  //   active_trader
  //   : 
  //   true
  //   address_d
  //   : 
  //   null
  //   applied_swap
  //   : 
  //   null
  //   arbitrage_current_balance
  //   : 
  //   "0.0"
  //   arbitrage_starting_balance
  //   : 
  //   "0.0"
  //   arbitrage_starting_date
  //   : 
  //   null
  //   arbitrage_yield
  //   : 
  //   "0.0 NaN%"
  //   avatar
  //   : 
  //   null
  //   balance
  //   : 
  //   "0.0"
  //   brand_status_array
  //   : 
  //   null
  //   call_conversation_id
  //   : 
  //   1511
  //   call_summery
  //   : 
  //   "Agent John from Xtrade contacted a potential client, Mark, who had previously shown interest in financial markets. Mark has some past trading experience and was exploring Xtrade's platform. John explained Xtrade's regulatory credentials and suggested comparing broker spreads for profitability. Mark inquired about investment guarantees, specifically in Bitcoin, but John clarified that no profit promises could be made. The call ended with Mark stating he would call back later due to time constraints."
  //   city_d
  //   : 
  //   null
  //   client_company
  //   : 
  //   null
  //   client_company_id
  //   : 
  //   0
  //   client_rank
  //   : 
  //   2
  //   client_rank_text
  //   : 
  //   "General2"
  //   company_id
  //   : 
  //   1
  //   contact_email
  //   : 
  //   null
  //   country
  //   : 
  //   "GB"
  //   country_d
  //   : 
  //   null
  //   created_at
  //   : 
  //   "2024-01-26T10:33:08.623Z"
  //   dash_pass
  //   : 
  //   "123"
  //   default_chat_set
  //   : 
  //   true
  //   deleted
  //   : 
  //   false
  //   desk_id
  //   : 
  //   3
  //   desk_name
  //   : 
  //   "Deskaa"
  //   display_client_name
  //   : 
  //   null
  //   email
  //   : 
  //   false
  //   facebook
  //   : 
  //   false
  //   filled_forms
  //   : 
  //   []
  //   first_assigned_agent_at
  //   : 
  //   "2024-02-08T21:01:21.000Z"
  //   first_assigned_agent_id
  //   : 
  //   129
  //   first_call_at
  //   : 
  //   null
  //   first_call_by
  //   : 
  //   null
  //   first_desk
  //   : 
  //   "5"
  //   first_name
  //   : 
  //   "t00"
  //   first_status_changed_at
  //   : 
  //   null
  //   frd_date
  //   : 
  //   null
  //   frd_owner_id
  //   : 
  //   null
  //   freeze_trading
  //   : 
  //   false
  //   full_name
  //   : 
  //   "sales test"
  //   gravatar
  //   : 
  //   null
  //   gravatar_try
  //   : 
  //   false
  //   id
  //   : 
  //   88
  //   id_type
  //   : 
  //   null
  //   id_verified
  //   : 
  //   false
  //   internal_brand_id
  //   : 
  //   1
  //   internal_brand_name
  //   : 
  //   "Tradeasapro"
  //   kyc_billing_status
  //   : 
  //   null
  //   kyc_billing_url
  //   : 
  //   null
  //   kyc_id_back_url
  //   : 
  //   null
  //   kyc_id_front_url
  //   : 
  //   null
  //   kyc_id_status
  //   : 
  //   null
  //   last_assigned_agent_at
  //   : 
  //   "2024-01-29T09:12:59.000Z"
  //   last_assigned_agent_id
  //   : 
  //   null
  //   last_assigned_desk_at
  //   : 
  //   "2024-12-10T13:00:44.000Z"
  //   last_assigned_team_at
  //   : 
  //   null
  //   last_call
  //   : 
  //   null
  //   last_call_at
  //   : 
  //   null
  //   last_call_by
  //   : 
  //   null
  //   last_desk
  //   : 
  //   null
  //   last_ip
  //   : 
  //   "46.251.112.40"
  //   last_lead_id
  //   : 
  //   null
  //   last_login
  //   : 
  //   null
  //   last_message_at
  //   : 
  //   null
  //   last_name
  //   : 
  //   "asdf"
  //   last_online
  //   : 
  //   "2024-11-06T17:44:02.000Z"
  //   last_pass_recovery
  //   : 
  //   null
  //   last_status_changed_at
  //   : 
  //   null
  //   lead
  //   : 
  //   false
  //   lead_file_id
  //   : 
  //   null
  //   local_time
  //   : 
  //   "01:46 PM - GMT 0"
  //   m_id
  //   : 
  //   null
  //   note
  //   : 
  //   null
  //   phone_number_d
  //   : 
  //   null
  //   pin_code
  //   : 
  //   "230925"
  //   postal_code_d
  //   : 
  //   null
  //   profile_pic
  //   : 
  //   null
  //   qr_code
  //   : 
  //   null
  //   rate
  //   : 
  //   null
  //   reminded_at
  //   : 
  //   null
  //   request_count
  //   : 
  //   350
  //   required_forms
  //   : 
  //   []
  //   residence_country
  //   : 
  //   null
  //   second_assigned_agent_at
  //   : 
  //   null
  //   second_assigned_agent_id
  //   : 
  //   null
  //   second_call_at
  //   : 
  //   null
  //   second_call_by
  //   : 
  //   null
  //   second_desk
  //   : 
  //   "4"
  //   sigup_set
  //   : 
  //   false
  //   staking_setting
  //   : 
  //   null
  //   t_account_type_id
  //   : 
  //   2
  //   third_assigned_agent_at
  //   : 
  //   null
  //   third_assigned_agent_id
  //   : 
  //   null
  //   third_call_at
  //   : 
  //   null
  //   third_call_by
  //   : 
  //   null
  //   third_desk
  //   : 
  //   null
  //   total_brand_status
  //   : 
  //   null
  //   total_called
  //   : 
  //   null
  //   trader_currency
  //   : 
  //   null
  //   updated_at
  //   : 
  //   "2024-12-10T13:00:44.250Z"}
  // client_agents
  // : 
  // [{avatar
  //   : 
  //   null
  //   id
  //   : 
  //   54
  //   name
  //   : 
  //   "demo account"
  //   teams
  //   : 
  //   ['DEV0022']},{
  //     avatar
  // : 
  // null
  // id
  // : 
  // 129
  // name
  // : 
  // "as cas"
  // teams
  // : 
  // []
  //   }]
  // client_company
  // : 
  // null
  // client_fields
  // : 
  // {54: null, 233: null, 234: null, 235: null, 236: null, 237: null, 238: null, 239: null, 240: null, 241: null, 242: null, 243: null, 244: null}
  // client_labels:[]
  // client_teams:[]
  // dashboard_domain:"app.octolit.com/login?token=1852f54ac9aeab9210485c5a2ff4c22214eed8621e9520f5485ed475cf1f480e6f8c4d3a3e78ae478273ec76ffb70a203df8ead19dfe437b0084c6855b43ca08"
  // email_conversation_id:1510
  // emails:[
  //   {id: 70, value: 'te2st@gmail.com'},
  //   {id: 115, value: 'test@test.test'},
  //   {id: 116, value: 'asdf@fdsafsa'}]
  // leads:[{account_id:  79
  //   account_name:  "tezst "
  //   agent_id:  null
  //   agent_name:  " "
  //   brand_id:  null
  //   brand_name:  null
  //   brand_status:  ""
  //   campaign:  ""
  //   campaign_error:  null
  //   campaign_validated:  true
  //   client_id:  88
  //   client_name:  "fsdafa asdf"
  //   company_id:  1
  //   country:  null
  //   created_at:  "2024-02-28T:.900Z"
  //   deposit:  null
  //   deposit_error:  null
  //   deposit_f:  0
  //   deposit_validated:  true
  //   description:  "Description"
  //   description_error:  null
  //   description_validated:  true
  //   desk_id:  null
  //   duplicate:  true
  //   email:  "asdf@fdsafsa"
  //   email_error:  "Email is invalid"
  //   email_validated:  false
  //   first_name:  "fsdafa"
  //   ftd_amount:  null
  //   ftd_amount_error:  null
  //   ftd_amount_f:  0
  //   ftd_amount_validated:  true
  //   ftd_date:  null
  //   ftd_date_error:  null
  //   ftd_date_validated:  true
  //   ftd_update_date:  null
  //   id:  5270
  //   ip_address:  null
  //   ip_address_error:  null
  //   ip_address_validated:  true
  //   label_ids:  null
  //   labels:  null
  //   language:  null
  //   language_error:  null
  //   language_validated:  true
  //   last_ftd_updated_at:  null
  //   last_name:  "asdf"
  //   lead_fields:  null
  //   lead_file_id:  null
  //   lead_file_internal_id:  null
  //   note:  ""
  //   phone:  "+380939213877"
  //   phone_error:  null
  //   phone_validated:  true
  //   registration_date:  null
  //   registration_date_error:  null
  //   registration_date_validated:  true
  //   sort:  [5270]
  //   source:  "API injection"
  //   source_brand:  ""
  //   status:  "Error"
  //   status_error:  "Could not find a right brand for this lead. check Your brands configuration!"
  //   team_id:  null
  //   team_name:  null
  //   updated_at:  "2024-03-09T:.957Z"
  //   verified:  false
  //   verified_error:  "Wrong data!"
  //   _id:  "5270"
  //   _index:  "leads_development_20240705102106731"
  //   _score:  null
  //   _type:  "_doc"},{
  //     account_i:79
  // account_nam:"tezst "
  // agent_i:null
  // agent_nam:" "
  // brand_i:null
  // brand_nam:null
  // brand_statu:""
  // campaig:""
  // campaign_error:null
  // campaign_validated:true
  // client_id:88
  // client_name:"fsdafa asdf"
  // company_id:1
  // country:null
  // created_at:"2024-02-28T1:.927Z"
  // deposit:null
  // deposit_error:null
  // deposit_f:0
  // deposit_validated:true
  // description:"Description"
  // description_error:null
  // description_validated:true
  // desk_id:null
  // duplicate:true
  // email:"test@test.test"
  // email_error:"Email is invalid. Test data detected"
  // email_validated:false
  // first_name:"Test"
  // ftd_amount:null
  // ftd_amount_error:null
  // ftd_amount_f:0
  // ftd_amount_validated:true
  // ftd_date:null
  // ftd_date_error:null
  // ftd_date_validated:true
  // ftd_update_date:null
  // id:5269
  // ip_address:null
  // ip_address_error:null
  // ip_address_validated:true
  // label_ids:null
  // labels:null
  // language:null
  // language_error:null
  // language_validated:true
  // last_ftd_updated_at:null
  // last_name:"Test"
  // lead_fields:null
  // lead_file_id:null
  // lead_file_internal_id:null
  // note:""
  // phone:"+380939213877"
  // phone_error:null
  // phone_validated:true
  // registration_date:null
  // registration_date_error:null
  // registration_date_validated:true
  // sort:[5269]
  // source:"API injection"
  // source_brand:""
  // status:"Error"
  // status_error:"Could not find a right brand for this lead. check Your brands configuration!"
  // team_id:null
  // team_name:null
  // updated_at:"2024-03-09T1:.126Z"
  // verified:false
  // verified_error:"Wrong data!"
  // _id:"5269"
  // _index:"leads_development_20240705102106731"
  // _score:null
  // _type:"_doc"
  //   }]
  // link:"live.octolit.com/login?token=1852f54ac9aeab9210485c5a2ff4c22214eed8621e9520f5485ed475cf1f480e6f8c4d3a3e78ae478273ec76ffb70a203df8ead19dfe437b0084c6855b43ca08"
  // password:"123"
  // phone_numbers:[{id:  128
  //   value:  "+447778838310"}]
  // phone_status:{area_code:  null
  //   carrier:  "Vodafone"
  //   country:  "GB"
  //   geo_name:  null
  //   human_type:  "Mobile"
  //   local_number:  "838310"
  //   timezone:  ['Europe/Guernsey', 'Europe/Isle_of_Man', 'Europe/London']}
  // reaction_time:"No first call!"
  // ticket_accounts:[]
  // ticket_count:6
  // total_deposit:"0.0"
  // total_withdrawal:"0.0"
  //   }
}