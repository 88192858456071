import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import RefreshCcw01Icon from '@untitled-ui/icons-react/build/esm/RefreshCcw01';
import SvgIcon from '@mui/material/SvgIcon';

import { ChatThreadItem } from '../internal-chat/chat-thread-item';
import { mockedChatData } from 'src/utils/constant/mock-data';

export const OverviewChat = () => {
  return (
    <Card>
      <CardHeader
        title="Internal Chat"
        action={(
          <IconButton
            color="inherit">
            <SvgIcon fontSize="small">
              <RefreshCcw01Icon />
            </SvgIcon>
          </IconButton>
        )}
      />
      <Divider />
      <List disablePadding>
        {mockedChatData.map((chat, index) => (
          <ChatThreadItem
            key={`${chat.id}-chat-${index}`}
            id={`${chat.id}-chat-${index}`}
            thread={chat}
          />
        ))}
      </List>
      <Divider />
      <CardActions>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon>
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
        >
          Go to chat
        </Button>
      </CardActions>
    </Card>
  );
};
