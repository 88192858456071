import { useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Scrollbar } from "src/components/scrollbar";
import { getAPIUrl } from "src/config";
import { useSettings } from "src/hooks/use-settings";
import { useCssVars } from "src/layouts/dashboard/vertical-layout/side-nav";

export const LeaderboardEvents = ({
  events,
  isNewEvent,
  setIsNewEvent,
  soundEnabled,
  leaderSound,
}) => {
  const settings = useSettings();
  const cssVars = useCssVars(settings.navColor);

  useEffect(() => {
    if (isNewEvent && events?.length) {
      const event = events?.[0];
      const textToSpeech = `User ${event?.agent_first_name} ${event?.agent_last_name} deposit ${event?.amount} in`;
      const defaultSound = "/assets/sounds/event.wav";
      const soundUrl = leaderSound
        ? leaderSound?.includes("http")
          ? leaderSound
          : `${getAPIUrl()}/${leaderSound}`
        : defaultSound;

      const audio = new Audio(soundUrl);
      const speech = new SpeechSynthesisUtterance(textToSpeech);

      speech.onend = () => {
        if (!soundEnabled) return null;
        audio.play();

        audio.onended = () => {
          setIsNewEvent(false);
        };
      };

      audio.pause();
      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(speech);
    }
  }, [isNewEvent, events, soundEnabled, leaderSound]);

  return (
    <Card
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        width: 1,
      }}
    >
      <Stack alignItems="flex-start" spacing={3} width={1}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
          }}
        >
          Events
        </Typography>
        <Scrollbar sx={{ maxHeight: "650px", width: 1 }}>
          <Stack spacing={2} width={1}>
            {events?.map((event, idx) => (
              <Stack
                key={event?.id}
                sx={{
                  ...cssVars,
                  backgroundColor: settings.paletteMode == "dark" ?"var(--nav-bg)": 'background.default',
                  borderRadius: 2,
                  boxShadow: 2,
                  px: 3,
                  py: 2,
                  border: idx === 0 ? "2px solid #16B364" : "",
                  "@keyframes flash-border": {
                    "0%": {
                      borderColor: "#16B364",
                    },
                    "50%": {
                      borderColor: "#F04438",
                    },
                    "100%": {
                      borderColor: "#16B364",
                    },
                  },
                  animation:
                    isNewEvent && idx === 0
                      ? "flash-border 1s infinite"
                      : "none",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar src={event?.avatar} alt="event avatar" />
                  <Typography fontWeight={600} fontSize={25}>
                    {event?.agent_first_name ?? ""}{" "}
                    {event?.agent_last_name ?? ""}
                  </Typography>
                </Stack>
                <Typography fontWeight={600} fontSize={25}>
                  ${event?.amount}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Scrollbar>
      </Stack>
    </Card>
  );
};
