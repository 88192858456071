import { Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";

import { Seo } from "src/components/seo"
import { Leaderboard } from "src/sections/landing/leaderboard";

const Page = () => (
  <>
    <Seo title="Leaderboard" />
    <Box component="main" sx={{ flexGrow: 1, pt: 8, pb: 2, flex: "1 1 auto", position: "relative" }}>
      <Container maxWidth="xxl">
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h4">Leaderboards</Typography>
          </Stack>
          <Leaderboard />
        </Stack>
      </Container>
    </Box>
  </>
);

export default Page;