import { useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import SettingIcon from "src/icons/untitled-ui/duocolor/setting";
import Iconify from "src/components/iconify";
import { useSettings } from "src/hooks/use-settings";

const mockedDesk = [
  {
    value: 1,
    label: 'Desk 1',
  },
  {
    value: 2,
    label: 'Desk 2',
  },
  {
    value: 3,
    label: 'Desk 3',
  },
];

export const LeaderboardFilters = ({
  selectedDesk,
  setSelectedDesk,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const settings = useSettings();
  
  const handleFullScreenOpen = () => {
    const elem = document?.documentElement;
    if (elem?.requestFullscreen) {
      elem?.requestFullscreen();
    } else if (elem?.webkitRequestFullscreen) {
      elem?.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem?.msRequestFullscreen();
    }
    settings?.handleSideNavClose();
    setIsExpanded(true);
  };

  const handleFullScreenClose = () => {
    if (document?.exitFullscreen) {
      document?.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document?.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document?.msExitFullscreen();
    }
    settings?.handleSideNavOpen();
    setIsExpanded(false);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      px={1}
      width={1}
      sx={{ flexDirection: { xl: "row", xs: "column" } }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "4px",
          width: { xl: "auto", xs: 1 },
        }}
      >
        <Autocomplete
          sx={{ width: { xs: "auto", md: 260 }, flexGrow: { xs: 1, xl: 0 } }}
          options={mockedDesk}
          value={
            mockedDesk?.find((item) => item?.value == selectedDesk?.value) ?? {
              label: "",
              value: "",
            }
          }
          onChange={(event, value) => {
            if (value) {
              setSelectedDesk(value);
            }
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box key={option.label} value={option.label} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              hiddenLabel
              placeholder="Select a desk"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ display: { xl: "none", xs: "block" } }}
        >
          <Tooltip title="Leaderboard Setting">
            <IconButton>
              <SvgIcon>
                <SettingIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={isExpanded ? "Small Screen" : "Full Screen"}>
            <IconButton
              onClick={
                isExpanded ? handleFullScreenClose : handleFullScreenOpen
              }
            >
              <Iconify
                icon={
                  isExpanded
                    ? "ant-design:compress-outlined"
                    : "ant-design:expand-outlined"
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack
        alignItems="center"
        gap={2}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          width: { lg: "auto", xs: 1 },
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <DatePicker
            format="dd/MM/yyyy"
            label="Start Date"
            value={new Date()}
            sx={{ width: { xs: 1, md: "auto" } }}
            slotProps={{ textField: { size: "small" } }}
          />
          <TimePicker
            format="h:mm a"
            label="Start Time"
            value={new Date()}
            sx={{ my: 1 / 2 }}
            slotProps={{ textField: { size: "small" } }}
          />
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <DatePicker
            format="dd/MM/yyyy"
            label="End Date"
            value={new Date()}
            sx={{ width: { xs: 1, md: "auto" } }}
            slotProps={{ textField: { size: "small" } }}
          />
          <TimePicker
            format="h:mm a"
            label="End Time"
            value={new Date()}
            sx={{ my: 1 / 2 }}
            slotProps={{ textField: { size: "small" } }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ display: { xl: "flex", xs: "none" } }}
        >
          <Tooltip title="Leaderboard Setting">
            <IconButton>
              <SvgIcon>
                <SettingIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={isExpanded ? "Small Screen" : "Full Screen"}>
            <IconButton
              onClick={
                isExpanded ? handleFullScreenClose : handleFullScreenOpen
              }
            >
              <Iconify
                icon={
                  isExpanded
                    ? "ant-design:compress-outlined"
                    : "ant-design:expand-outlined"
                }
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};
