import {
  Avatar,
  Divider,
  IconButton,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import { useDispatch } from 'react-redux';
import actionCable from "actioncable";

import { ChatContainer } from "src/sections/dashboard/chat/chat-container";
import { Seo } from "src/components/seo";
import { paths } from "src/paths";
import { thunks as agentThunk } from "src/thunks/contact_list";
import { useAuth } from "src/hooks/use-auth";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { useSearchParams } from "src/hooks/use-search-params";
import { useSettings } from 'src/hooks/use-settings';
import { ChatSidebar } from "src/sections/landing/support-chats/chat-sidebar";
import { ChatThread } from "src/sections/landing/support-chats/chat-thread";
import ReactCountryFlag from "react-country-flag";
import Iconify from "src/components/iconify";
import { getBaseSocketUrl } from "src/config";
import { SUPPORTCHATS } from "../../api/mock/_support_chats";

const CableApp = {};
CableApp.cable = actionCable.createConsumer(
  `${getBaseSocketUrl()}/cable?token=${localStorage.getItem("token")}`
);

const useSidebar = () => {
  const searchParams = useSearchParams();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [open, setOpen] = useState(mdUp);

  const handleScreenResize = useCallback(() => {
    if (!mdUp) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [mdUp]);

  useEffect(() => {
    handleScreenResize();
  }, [mdUp]);

  const handeParamsUpdate = useCallback(() => {
    if (!mdUp) {
      setOpen(false);
    }
  }, [mdUp]);

  useEffect(() => {
    handeParamsUpdate();
  }, [searchParams]);

  const handleToggle = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    handleToggle,
    handleClose,
    open,
  };
};

const Page = () => {
  const settings = useSettings();
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const searchParams = useSearchParams();
  const compose = searchParams.get("compose") === "true";
  const threadKey = searchParams.get("conversationId") || undefined;
  const sidebar = useSidebar();
  const { user } = useAuth();

  const router = useRouter();

  useEffect(() => {
    if (user?.acc?.acc_v_support_chats === false) {
      router?.push(paths.notFound);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(agentThunk.resetList());
    }
  }, []);

  usePageView();

  const view = threadKey ? "conversation" : compose ? "compose" : "blank";

  return (
    <>
      <Seo title="Support Chats" />
      <Divider />
      <Box
        component="main"
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Stack
          ref={rootRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            height: {
              xs: 'calc(100vh - 100px)',
              md: settings?.layout === 'horizontal' ? 'calc(100vh - 140px)' : 'calc(100vh - 80px)'
            }
          }}
        >
          <ChatSidebar
            container={rootRef.current}
            onClose={sidebar.handleClose}
            open={sidebar.open}
            chats={SUPPORTCHATS.CHATS}
          />
          <ChatContainer open={sidebar.open}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: 2 }}
            >
              <IconButton onClick={sidebar.handleToggle}>
                <SvgIcon>
                  <Menu01Icon />
                </SvgIcon>
              </IconButton>

              {SUPPORTCHATS.CLIENTS?.client ?
                <Stack direction='row' gap={0.5} alignItems='center'>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    pl={0.5}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Avatar
                      src={SUPPORTCHATS.CLIENTS?.client?.avatar}
                      width={28}
                      height={28}
                    />
                    <Typography>{SUPPORTCHATS.CLIENTS?.client?.full_name}</Typography>
                    <ReactCountryFlag
                      svg
                      style={{ height: "20px", width: "25px" }}
                      countryCode={SUPPORTCHATS.CLIENTS?.client?.country}
                    />
                  </Stack>

                  <IconButton
                    sx={{ '&:hover': { color: 'primary.main' }, color: 'text.primary' }}
                  >
                    <Iconify icon="carbon:arrow-right" />
                  </IconButton>
                </Stack>
                :
                null}
              <Stack></Stack>
            </Stack>
            <Divider />
            {view === "conversation" && (
              <ChatThread
                threadKey={threadKey} cable={CableApp.cable} />
            )}
          </ChatContainer>
        </Stack>
      </Box>
    </>
  );
};

export default Page;
