import { useState } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";

import { LeaderboardCharts } from "./widgets/leaderboard-charts";
import { LeaderboardEvents } from "./widgets/leaderboard-events";
import { LeaderboardFilters } from "./widgets/leaderboard-filters";
import { LeaderboardTable } from "./widgets/leaderboard-table";
import { leaderboardMockedData } from "src/utils/constant/mock-data";

export const Leaderboard = () => {
  const [selectedDesk, setSelectedDesk] = useState({
    value: 1,
    label: 'Desk 1',
  });

  const [isNewEvent, setIsNewEvent] = useState(false);

  return (
    <Stack direction='column' gap={2} width={1}>
      <Stack
        alignItems="center"
        direction="row"
        sx={{ gap: 4 }}
      >
        <LeaderboardFilters
          selectedDesk={selectedDesk}
          setSelectedDesk={setSelectedDesk}
        />
      </Stack>

      <Grid container spacing={2} width={1} margin={0}>
        <Grid xs={12} xl={8}>
          <LeaderboardTable
            selectedDesk={selectedDesk}
            setSelectedDesk={setSelectedDesk}
            tableData={leaderboardMockedData?.agents_list}
          />
        </Grid>
        <Grid
          xs={12}
          xl={4}
          sx={{
            display: 'flex',
            flexDirection: { xl: 'column', lg: 'row', xs: 'column' },
            gap: 2,
            width: 1,
          }}
        >
          <LeaderboardCharts {...{
            chartSeries: [{
              data: leaderboardMockedData?.total_deposit_chart,
            }],
            totalDepositCount: leaderboardMockedData?.total_deposit_count,
            totalDeposit: leaderboardMockedData?.total_deposit,
            totalGoal: leaderboardMockedData?.total_goal,
            remainingGoal: leaderboardMockedData?.remaining_goal,
            totalPercentage: leaderboardMockedData?.complete_percentage,
          }} />
          <LeaderboardEvents
            {...{
              events: leaderboardMockedData?.events,
              isNewEvent,
              setIsNewEvent,
              soundEnabled: leaderboardMockedData?.leader_sound_enable,
              leaderSound: leaderboardMockedData?.leader_sound,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
