export const productMockedData = [
  {
    header: "CRM",
    description: "Experience the efficiency of a modular framework CRM, tailored for both small scale access and large-scale enterprise operations. Keep your client data secure and within your control, offering peace of mind in an era where data privacy is paramount. Fully customizable to meet your unique business needs, this CRM transforms client management into a seamless, personalized experience.",
    detailDescription: [
      {
        title: "The Ultimate Client Management Solution",
        description: `<p>In today's dynamic business landscape, managing client relationships is more critical than ever. Our <strong>CRM (Customer Relationship Management)</strong> system is crafted to empower businesses of all sizes, from small startups to large-scale enterprises, with a versatile and modular framework that adapts to your unique needs.</p>`
      },
      {
        title: "Scalable and Flexible",
        description: "Our CRM grows with your business. Whether you're managing a few clients or an expansive network, it offers the flexibility to scale up or down seamlessly, ensuring that you always have the right tools for the job."
      },
      {
        title: "Data Security and Privacy",
        description: "We prioritize your data security. With advanced encryption and secure servers, your client information remains safe and under your control, meeting the highest standards of data privacy and compliance."
      },
      {
        title: "Personalized Experience",
        description: "Every business is unique, and so are its CRM needs. Our system is fully customizable, allowing you to tailor workflows, data fields, and reports to reflect your specific processes and goals. From lead tracking to customer engagement, it's designed to work the way you do."
      },
      {
        title: "Enhanced Efficiency",
        description: "Streamline your operations with an intuitive interface and automated processes. Reduce manual tasks, enhance communication, and improve team collaboration, all while delivering a superior experience to your clients."
      },
      {
        title: "Insights at Your Fingertips",
        description: `<p>Transform raw data into actionable insights. Our CRM provides robust analytics and reporting tools, enabling you to make data-driven decisions and optimize your business strategies.
        <br>
        Invest in a CRM that not only manages client data but also fosters stronger relationships, improves efficiency, and drives growth. Experience the difference of a system designed with your business in mind, and take your client management to the next level.</p>`
      },
    ],
  },
  {
    header: "Compliance and KYC",
    description: "Revolutionize your compliance process with our advanced AI-driven Compliance and KYC solution. This cutting-edge feature transcribes and analyzes calls and emails between agents and clients, providing detailed insights and flagging potential compliance risks in real-time. Empower your compliance team with actionable intelligence, ensuring transparency, regulatory adherence, and enhanced operational efficiency. Tailored for brokers, this solution keeps you ahead of industry standards while fostering trust and integrity in your operations.",
    detailDescription: [
      {
        title: "Empowering Transparency and Trust",
        description: `In the ever-evolving regulatory landscape, staying compliant is no longer optional—it's essential. Our Compliance and KYC solution leverages advanced AI to transform how businesses handle regulatory processes, ensuring efficiency, transparency, and peace of mind.`
      },
      {
        title: "Real-Time Call and Email Analysis",
        description: `Our solution utilizes state-of-the-art AI technology to transcribe and analyze communications, including calls and emails, between agents and clients. By identifying key patterns and flagging potential compliance risks, your team is empowered to address issues before they escalate.`
      },
      {
        title: "Actionable Insights for Compliance Teams",
        description: `Designed with brokers in mind, this feature delivers comprehensive insights that streamline decision-making. Gain a deeper understanding of client interactions, monitor adherence to policies, and ensure every interaction aligns with regulatory standards.`
      },
      {
        title: "Proactive Risk Mitigation",
        description: `With automated risk detection and alert systems, your compliance team can focus on strategy rather than firefighting. Reduce the chances of regulatory breaches and maintain your reputation as a trustworthy organization.`
      },
      {
        title: "Seamless Integration",
        description: `Our solution integrates effortlessly into your existing systems, ensuring a smooth adoption process. Tailor the platform to your specific compliance needs without disrupting your workflow.`
      },
      {
        title: "Staying Ahead of Industry Standards",
        description: `Compliance isn't just about meeting regulations—it's about exceeding them. This tool equips your business to not only adhere to current standards but also anticipate and prepare for future changes in the regulatory environment.`
      },
      {
        title: "Building Client Trust",
        description: `By demonstrating a commitment to transparency and compliance, you build stronger relationships with clients. Show them that their security and trust are at the heart of your operations.
        Embrace the future of compliance with a tool designed to simplify, streamline, and strengthen your operations. With our Compliance and KYC solution, you’re not just meeting standards—you’re setting them.`
      },
    ],
  },
  {
    header: "Comprehensive Bridge Data Reporting",
    description: "Bridge Data Reporting Harness the power of comprehensive trading insights with our data reporting solution. We collect and analyze data from various third-party trading bridges and deliver detailed reports directly to brokers through our app. This service ensures brokers have accurate, timely information at their fingertips to make informed decisions and maintain a competitive edge in the fast-paced financial market.",
    detailDescription: [
      {
        title: "Empowering Brokers with Actionable Insights",
        description: `In the ever-changing financial landscape, timely and accurate information is the cornerstone of success. Our Bridge Data Reporting solution delivers unparalleled trading insights, enabling brokers to make informed decisions with confidence and precision.`
      },
      {
        title: "Seamless Data Integration",
        description: `We gather and analyze data from multiple third-party trading bridges, consolidating it into one cohesive and user-friendly platform. This integration ensures brokers have a holistic view of trading activities without navigating through disparate systems.`
      },
      {
        title: "Detailed and Timely Reports",
        description: `Access detailed reports enriched with actionable insights. Our solution transforms complex data into easy-to-understand formats, delivered directly to brokers via our intuitive app. Stay ahead with real-time updates and never miss critical market trends.`
      },
      {
        title: "Informed Decision-Making",
        description: `In the fast-paced financial market, having the right information at the right time can be a game-changer. With accurate and comprehensive data at your fingertips, brokers can identify opportunities, manage risks, and maintain a competitive edge with confidence.`
      },
      {
        title: "Enhanced Market Competitiveness",
        description: `Gain a strategic advantage with insights tailored to your trading operations. Our reporting solution equips brokers with the tools to adapt to market changes, optimize strategies, and stay ahead of the competition.`
      },
      {
        title: "Intuitive App Interface",
        description: `Delivered through our easy-to-use app, the reports are accessible anytime, anywhere. The mobile-friendly design ensures brokers stay connected and informed, even on the go.
        Elevate your trading operations with our Comprehensive Bridge Data Reporting. It’s more than just data—it’s the intelligence you need to thrive in the competitive world of finance. Stay informed, stay ahead.`
      },
    ],
  },
  {
    header: "Lead Management",
    description: "Streamline your lead management process with our advanced solution, designed to validate leads and rigorously check for inaccurate or bad data. This system enhances your marketing efforts by providing robust tools to manage affiliate access effectively, ensuring optimal collaboration and control. Its user-friendly integration capabilities make it a breeze to incorporate into your existing workflows, simplifying lead management without sacrificing efficiency.",
    detailDescription: [
      {
        title: "Streamlined, Accurate, and Effective",
        description: `In today’s competitive business environment, effective lead management is crucial for maximizing growth opportunities. Our Lead Management solution offers the tools and precision you need to handle leads efficiently and enhance your marketing efforts.`
      },
      {
        title: "Accurate Lead Validation",
        description: `Say goodbye to wasted time and resources on bad or inaccurate data. Our solution rigorously validates leads, ensuring you work with genuine and actionable opportunities.`
      },
      {
        title: "Optimized Affiliate Collaboration",
        description: `Manage affiliate access with confidence and control. Our robust tools streamline affiliate interactions, fostering seamless collaboration while maintaining strict oversight of lead quality and data integrity.`
      },
      {
        title: "Simplified Integration",
        description: `Designed for ease of use, our system integrates effortlessly into your existing workflows. Enjoy a hassle-free setup and transition, with no disruption to your operations.`
      },
      {
        title: "Enhanced Marketing Efficiency",
        description: `By filtering out bad data and providing reliable lead insights, this solution empowers your marketing teams to focus on high-quality prospects, boosting conversion rates and ROI.`
      },
      {
        title: "User-Friendly Interface",
        description: `Our intuitive platform is built with the user in mind. Access and manage leads effortlessly, allowing your team to operate more effectively and stay ahead in a fast-paced market.
        Streamline, validate, and optimize your lead management process with a solution designed to improve efficiency and drive better results. Take control of your leads and ensure your marketing efforts deliver maximum impact.`
      },
    ],
  },
  {
    header: "Regulatory Reporting Solution",
    description: "Streamline compliance with our Regulatory Reporting Solution, designed for brokers to generate and audit reports for over 100 regulatory bodies, including CySEC. We automate report creation in regulator-approved formats, conduct pre-submission audits, and provide seamless integration for effortless compliance management. Save time, ensure accuracy, and stay ahead of regulatory demands.",
    detailDescription: [
      {
        title: "Regulatory Reporting Solution: Simplified Compliance for Brokers",
        description: "Navigating the complex world of regulatory compliance is now effortless with our Regulatory Reporting Solution. Tailored for brokers, this innovative service ensures accurate, timely, and fully compliant reporting for over 100 regulatory bodies, including CySEC and many others worldwide."
      },
      {
        title: "Automated Report Generation",
        description: "We simplify the reporting process by automating the generation of reports in the exact format required by each regulatory authority. From transaction data to compliance metrics, every detail is meticulously prepared to meet specific standards."
      },
      {
        title: "Pre-Submission Audit",
        description: "Before submission, our system conducts a thorough audit of all reports to identify discrepancies or errors. This ensures the highest level of accuracy and reliability, reducing the risk of compliance issues."
      },
      {
        title: "Multi-Regulator Compatibility",
        description: "Our solution supports reporting for a wide array of regulatory bodies, making it the perfect choice for brokers operating in multiple jurisdictions. Whether it’s CySEC, FCA, or others, we’ve got you covered."
      },
      {
        title: "Ease of Integration",
        description: "Integrate our solution seamlessly into your existing systems. Our flexible design ensures compatibility and smooth adoption, minimizing disruptions to your workflow."
      },
      {
        title: "Comprehensive Insights and Analytics",
        description: "Gain valuable insights into your regulatory data with our detailed analytics. Understand patterns, trends, and areas for improvement to stay ahead in the compliance game."
      },
      {
        title: "Save Time and Resources",
        description: `<p>Eliminate manual processes and reduce the burden on your compliance team. With our solution, you save valuable time and resources, allowing you to focus on growing your business.
        Stay compliant, confident, and ahead of the curve with our <strong>Regulatory Reporting Solution</strong>. It’s more than a service—it’s your partner in navigating the complexities of global regulatory requirements.</p>`
      },
    ],
  },
  {
    header: "Client Dashboard",
    description: "Upgrade client engagement with our fully customizable Client Dashboard. Designed for complete personalization, it aligns with your unique branding and color scheme. Clients can easily upload KYC documents, with flexible customization to meet diverse needs. The dashboard also functions as an interactive hub for posting updates, engaging in support chats, and highlighting key announcements or leaderboards. More than a dashboard, it's an integral tool for enhancing client interaction.",
    detailDescription: [
      {
        title: "Elevate Engagement and Personalization",
        description: `<p>Transform client interactions with our <strong>fully customizable Client Dashboard</strong>, a comprehensive solution designed to enhance engagement and streamline communication. Tailored to align with your brand, it delivers a seamless and interactive experience that meets diverse client needs.</p>`
      },
      {
        title: "Complete Personalization",
        description: `Reflect your unique branding with a dashboard that adapts to your colors, style, and preferences. Deliver a consistent and professional image while making clients feel connected to your brand.`
      },
      {
        title: "Streamlined KYC Management",
        description: `Simplify compliance with a user-friendly interface that allows clients to upload KYC documents easily. Customizable workflows ensure the process fits your specific requirements, enhancing efficiency and satisfaction.`
      },
      {
        title: "Interactive Communication Hub",
        description: `Stay connected with clients through real-time support chats, ensuring timely assistance and problem resolution. Post updates, announcements, and leaderboards to keep your clients informed and engaged.`
      },
      {
        title: "Enhanced Client Engagement",
        description: `Turn the dashboard into a central hub for interaction. Share key information, recognize top performers, and encourage active participation with features that foster a sense of community and involvement.`
      },
      {
        title: "Flexible and Scalable",
        description: `Whether you’re catering to a small client base or a large audience, the dashboard grows with you, adapting to your needs and offering a consistent experience across all user levels.
        More than just a tool, the Client Dashboard is your gateway to fostering trust, improving communication, and elevating client satisfaction. Build stronger relationships and take client interaction to the next level.`
      },
    ],
  },
  {
    header: "Server Proxy",
    description: "Enhance your online security with our Server Proxy solution. It provides a dashboard to manage a proxy server, safeguarding your real server IP and guarding against DDoS attacks for reliable online activity. Features include comprehensive reporting and the option to pay with cryptocurrency for added anonymity. This Server Proxy is a formidable defense for your digital operations.",
    detailDescription: [
      {
        title: "Secure, Private, and Reliable",
        description: `Protect your digital operations with our Server Proxy solution, a robust system designed to safeguard your online activity and enhance security. Offering advanced features and flexibility, it’s the ultimate shield for your digital infrastructure.`
      },
      {
        title: "Secure Your Server IP",
        description: `Keep your real server IP hidden to prevent unauthorized access and potential threats. Our proxy solution ensures your operations remain private and secure.`
      },
      {
        title: "Defense Against DDoS Attacks",
        description: `Mitigate the risk of disruptive DDoS attacks with a reliable proxy server that absorbs malicious traffic, ensuring uninterrupted and smooth online activity.`
      },
      {
        title: "Intuitive Dashboard Management",
        description: `Manage your proxy server with ease through a user-friendly dashboard. Gain full control over settings, monitor activity, and optimize performance—all in one place.`
      },
      {
        title: "Comprehensive Reporting",
        description: `Stay informed with detailed reports on server activity, traffic patterns, and potential threats. This insight allows you to proactively address vulnerabilities and maintain peak performance.`
      },
      {
        title: "Cryptocurrency Payment Options",
        description: `For added anonymity, enjoy the option to pay with cryptocurrency. This feature aligns with modern privacy preferences, offering an additional layer of discretion.`
      },
      {
        title: "Reliable and Versatile",
        description: `<p>Whether for personal use or large-scale operations, our Server Proxy solution adapts to your needs, delivering unmatched reliability and security.
        <br>Secure your digital environment and enhance privacy with a solution designed to protect and empower. The Server Proxy is more than a service—it’s a comprehensive defense for your online presence.</p>`
      },
    ],
  },
  {
    header: "Custom Financial Software Development",
    description: "Transform your financial services with our Custom Development service, specializing in creating tailor-made websites, mobile apps, and applications for the financial sector. Our expert team ensures your unique requirements are met with innovative, user-friendly digital solutions. This service is dedicated to elevating your financial business through bespoke software that not only meets but exceeds expectations.",
    detailDescription: [
      {
        title: "Tailored Solutions for Success",
        description: `<p>Empower your financial business with our <strong>Custom Financial Software Development</strong> service, designed to provide innovative, user-centric digital solutions that align with your unique needs. From websites to mobile apps and comprehensive applications, we deliver excellence at every stage.</p>`
      },
      {
        title: "Tailored for the Financial Sector",
        description: `We specialize in crafting software solutions exclusively for the financial industry. Our deep understanding of the sector enables us to create tools that address your challenges and amplify your strengths.`
      },
      {
        title: "Bespoke Website Development",
        description: `Establish a strong digital presence with a custom-built website tailored to your branding and business objectives. Ensure a seamless user experience that engages clients and drives results.`
      },
      {
        title: "Innovative Mobile Apps",
        description: `Stay ahead in the mobile-first world with intuitive, feature-rich mobile applications. Our designs focus on functionality and ease of use, enabling clients to access your services on the go.`
      },
      {
        title: "Innovative Mobile Apps",
        description: `Stay ahead in the mobile-first world with intuitive, feature-rich mobile applications. Our designs focus on functionality and ease of use, enabling clients to access your services on the go.`
      },
      {
        title: "Comprehensive Applications",
        description: `From risk management tools to trading platforms, our applications are built to handle complex financial tasks with precision. Enjoy the benefits of streamlined workflows and enhanced productivity.`
      },
      {
        title: "User-Friendly Design",
        description: `Our solutions are crafted with the end user in mind. We combine functionality with aesthetics, ensuring your clients have an engaging and hassle-free experience.`
      },
      {
        title: "User-Friendly Design",
        description: `Our solutions are crafted with the end user in mind. We combine functionality with aesthetics, ensuring your clients have an engaging and hassle-free experience.`
      },
      {
        title: "Expert Team, Exceptional Results",
        description: `Our experienced developers collaborate closely with you to understand your needs and deliver software that exceeds expectations. From concept to deployment, we prioritize quality, innovation, and reliability.
        Transform your financial services and set new industry standards with custom software solutions that reflect your vision and drive growth. With our expertise, your success is just a step away.`
      },
    ],
  },
]