import { forwardRef } from 'react';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SvgIcon from '@mui/material/SvgIcon';

export const ChatSidebarSearch = forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{ p: 2, pt: 1 }}
      {...props}>
      <OutlinedInput
        fullWidth
        placeholder="Search support chats"
        startAdornment={(
          <InputAdornment position="start">
            <SvgIcon>
              <SearchMdIcon />
            </SvgIcon>
          </InputAdornment>
        )}
      />
    </Box>
  );
});
