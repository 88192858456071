import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/system";
import { Chart } from "src/components/chart";
import useChart from "src/hooks/use-chart";

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [alpha(theme.palette.error.main, 0.4)],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
      type: "gradient",
    },
    grid: {
      show: false,
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };
};

export const LeaderboardCharts = ({
  chartSeries,
  totalDeposit,
  totalDepositCount,
  totalGoal,
  remainingGoal,
  totalPercentage,
}) => {
  const theme = useTheme();
  const colors = [theme.palette.info.main, theme.palette.info.dark];

  const areaChartOptions = useChartOptions();

  const radialChartOptions = useChart({
    chart: {
      offsetY: -16,
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: 24,
        bottom: 24,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          size: "56%",
        },
        dataLabels: {
          name: {
            offsetY: 8,
          },
          value: {
            offsetY: -15,
            fontSize: 16,
          },
          total: {
            label: "",
            color: theme.palette.text.disabled,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0], opacity: 1 },
          { offset: 100, color: colors[1], opacity: 1 },
        ],
      },
    },
  });

  return (
    <Card
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        width: 1,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
        }}
      >
        Analytics
      </Typography>
      <Grid 
        container 
        columnSpacing={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid xs={4}>
          <Stack alignItems="flex-start" spacing={1}>
            <Typography variant="subtitle1">Total deposit:</Typography>
            <Typography variant="subtitle1">Total count:</Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Stack alignItems="flex-start" spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              ${totalDeposit ?? "0.00"}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              {totalDepositCount ?? 0}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Chart
            height={120}
            options={areaChartOptions}
            series={chartSeries}
            type="area"
          />
        </Grid>
      </Grid>

      <Grid 
        container 
        columnSpacing={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid xs={4}>
          <Stack alignItems="flex-start" spacing={1}>
            <Typography variant="subtitle1">Total goal:</Typography>
            <Typography variant="subtitle1">Remaining goal:</Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Stack alignItems="flex-start" spacing={1}>
            <Typography variant="subtitle1" fontWeight={600}>
              ${totalGoal ?? "0.00"}
            </Typography>
            <Typography variant="subtitle1" fontWeight={600}>
              ${remainingGoal ?? "0.00"}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Chart
            dir="ltr"
            type="radialBar"
            series={[totalPercentage ?? 0]}
            options={radialChartOptions}
            width="100%"
            height={190}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
