import { subDays, subHours, subMinutes, subSeconds } from 'date-fns';

const now = new Date();

export const walletList = [
  {
    key: "tron",
    name: 'Tron',
    label: "Tron",
    contract: "trx",
    symbol: 'TRXUSD',
    logo: '/assets/wallets/tron.svg',
    unit: 'TRX',
    tempValue: 16213.20,
  },
  {
    key: "trc20",
    name: 'Tether',
    label: "Tether (trc20)",
    contract: "trc20",
    symbol: 'TRXUSDT',
    logo: '/assets/wallets/tether.svg',
    unit: 'USDT',
    tempValue: 9626.80,
  },
  {
    key: "ethereum",
    name: 'Ethereum',
    label: 'Ethereum',
    contract: "eth",
    symbol: 'ETHUSD',
    logo: '/assets/wallets/eth.svg',
    unit: 'ETH',
    tempValue: 10076.25,
  },
  {
    key: "erc20",
    name: 'Tether',
    label: "Tether (erc20)",
    contract: "erc20",
    symbol: 'ETHUSDT',
    logo: '/assets/wallets/tether.svg',
    unit: 'USDT',
    tempValue: 8076.00,
  },
  {
    key: "binance_smart_chain",
    name: 'Binance',
    label: "BNB",
    contract: "bsc",
    symbol: 'BNBUSD',
    logo: '/assets/wallets/bnb.svg',
    unit: 'BNB',
    tempValue: 8876.81,
  },
  {
    key: "bsc20",
    name: 'Tether',
    label: "Tether (bep20)",
    contract: "bsc20",
    symbol: 'BNBUSDT',
    logo: '/assets/wallets/tether.svg',
    unit: 'USDT',
    tempValue: 12576.76,
  },
  {
    key: "usdc_eth",
    name: 'USDC-ETH',
    label: "ETH-USDC",
    contract: "eth",
    symbol: 'ETHUSDC',
    logo: '/assets/wallets/usdc.svg',
    unit: 'USDC',
    tempValue: 1676.20,
  },
  {
    key: "usdc_bsc",
    name: 'USDC-BSC',
    label: "BNB-USDC",
    contract: "bsc",
    symbol: 'BNBUSDC',
    logo: '/assets/wallets/usdc.svg',
    unit: 'USDC',
    tempValue: 13756.00,
  },
  {
    key: "solana",
    name: 'Solana',
    label: "SOL",
    contract: "sol",
    symbol: 'SOLUSD',
    logo: '/assets/wallets/solana.svg',
    unit: 'SOL',
    tempValue: 13756.00,
  },
];

export const customerMockedList = [
  {
    id: 1,
    full_name: 'Jane Smith',
    country: 'UA',
    company: "Visionary Dynamics Solutions Ltd.",
    emails: ['jane.smith@yahoo.com'],
    phone_numbers: ['20-1234-5678'],
    desk_name: 'Retention',
    desk_color: "#FF5733",
    internal_brand_name: 'Wuckert Inc',
    client_labels: [
      {
        name: 'Conversion',
        color: '#00A76F'
      }
    ],
    campaign: "Momentum Manifesto",
    status: 1,
    balance: 750.50,
    affiliate_names: ['Angelique Morse'],
    affiliate_color: "#06AED4",
    agents: [{ name: 'Samuel Thomas', avatar: '/assets/avatars/avatar-alcides-antonio.png' }],
    client_teams: [{ name: 'Software Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 2,
    full_name: 'John Doe',
    country: 'US',
    company: "Quantum Innovations Group",
    emails: ['john.doe@hotmail.com'],
    phone_numbers: ['30-9876-5432'],
    desk_name: 'Sales',
    desk_color: "#3366FF",
    internal_brand_name: 'ABC Corp.',
    client_labels: [
      {
        name: 'Sales',
        color: '#637381'
      }
    ],
    campaign: "Unity Uplift Project",
    status: 2,
    balance: 500.75,
    affiliate_names: ['Affiliate Y'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Mia Garcia', avatar: '/assets/avatars/avatar-marcus-finn.png' }],
    client_teams: [{ name: 'Customer Care' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 3,
    full_name: 'Alice Johnson',
    country: 'CA',
    company: "Nexus Synergy Technologies",
    emails: ['alice.johnson@gmail.com'],
    phone_numbers: ['40-5678-1234'],
    desk_name: 'Support',
    desk_color: "#FFC300",
    internal_brand_name: 'Supportive Solutions',
    client_labels: [
      {
        name: 'Customer Support',
        color: '#06AED4'
      }
    ],
    campaign: "Celestial Harmony Campaign",
    status: 3,
    balance: 1200.25,
    affiliate_names: ['Affiliate Z'],
    affiliate_color: "#10B981",
    agents: [{ name: 'Benjamin Clark', avatar: '/assets/avatars/avatar-carson-darrin.png' }],
    client_teams: [{ name: 'Support Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 4,
    full_name: 'Robert White',
    country: 'GB',
    company: "AlphaPulse Innovations",
    emails: ['robert.white@gmail.com'],
    phone_numbers: ['50-4321-8765'],
    desk_name: 'Marketing',
    desk_color: "#FF5733",
    internal_brand_name: 'Marketing Pro',
    client_labels: [
      {
        name: 'Marketing',
        color: '#22C55E'
      }
    ],
    campaign: "Galaxy Gala Event",
    status: 1,
    balance: 900.75,
    affiliate_names: ['Affiliate Marketing'],
    affiliate_color: "#637381",
    agents: [{ name: 'Ava Martinez', avatar: '/assets/avatars/avatar-fran-perez.png' }],
    client_teams: [{ name: 'Marketing Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 5,
    full_name: 'Emily Johnson',
    country: 'AU',
    company: "Global Horizon Enterprises",
    emails: ['emily.johnson@gmail.com'],
    phone_numbers: ['60-9876-1234'],
    desk_name: 'Development',
    desk_color: "#3366FF",
    internal_brand_name: 'Tech Innovators',
    client_labels: [
      {
        name: 'Development',
        color: '#3366FF'
      }
    ],
    campaign: "Aurora Ascendancy",
    status: 2,
    balance: 650.50,
    affiliate_names: ['Tech Solutions'],
    affiliate_color: "#10B981",
    agents: [{ name: 'James White', avatar: '/assets/avatars/avatar-penjani-inyene.png' }],
    client_teams: [{ name: 'Development Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 6,
    full_name: 'David Brown',
    country: 'US',
    company: "OptiWave Systems Inc.",
    emails: ['david.brown@hotmail.com'],
    phone_numbers: ['70-5678-4321'],
    desk_name: 'HR',
    desk_color: "#FFC300",
    internal_brand_name: 'People Connect',
    client_labels: [
      {
        name: 'Human Resources',
        color: '#10B981'
      }
    ],
    campaign: "Lunar Luminary Launch",
    status: 3,
    balance: 1100.25,
    affiliate_names: ['People Solutions'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Sophia Anderson', avatar: '/assets/avatars/avatar-omar-darboe.png' }],
    client_teams: [{ name: 'HR Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 7,
    full_name: 'Sophie Adams',
    country: 'CA',
    company: "Infinity Nexus Holdings",
    emails: ['sophie.adams@gmail.com'],
    phone_numbers: ['80-8765-4321'],
    desk_name: 'IT',
    desk_color: "#FF5733",
    internal_brand_name: 'Tech Masters',
    client_labels: [
      {
        name: 'Information Technology',
        color: '#637381'
      }
    ],
    campaign: "Stellar Impact Drive",
    status: 1,
    balance: 800.75,
    affiliate_names: ['Tech Wizards'],
    affiliate_color: "#06AED4",
    agents: [{ name: 'William Taylor', avatar: '/assets/avatars/avatar-siegbert-gottfried.png' }],
    client_teams: [{ name: 'IT Support' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 8,
    full_name: 'William Taylor',
    country: 'GB',
    company: "QuantumLeap Dynamics",
    emails: ['william.taylor@outlook.com'],
    phone_numbers: ['90-1234-5678'],
    desk_name: 'Finance',
    desk_color: "#3366FF",
    internal_brand_name: 'Financial Experts',
    client_labels: [
      {
        name: 'Finance',
        color: '#3366FF'
      }
    ],
    campaign: "Zenith Zephyr Campaign",
    status: 2,
    balance: 900.50,
    affiliate_names: ['Financial Partners'],
    affiliate_color: "#637381",
    agents: [{ name: 'Emma Wilson', avatar: '/assets/avatars/avatar-iulia-albu.png' }],
    client_teams: [{ name: 'Finance Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 9,
    full_name: 'Olivia Martin',
    country: 'AU',
    company: "Synergetic Solutions Ltd.",
    emails: ['olivia.martin@gmail.com'],
    phone_numbers: ['100-5678-1234'],
    desk_name: 'Legal',
    desk_color: "#FFC300",
    internal_brand_name: 'Legal Advisors',
    client_labels: [
      {
        name: 'Legal',
        color: '#637381'
      }
    ],
    campaign: "Radiant Ripple Initiative",
    status: 3,
    balance: 1100.25,
    affiliate_names: ['Legal Solutions'],
    affiliate_color: "#10B981",
    agents: [{ name: 'Robert Miller', avatar: '/assets/avatars/avatar-nasimiyu-danai.png' }],
    client_teams: [{ name: 'Legal Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 10,
    full_name: 'Henry Turner',
    country: 'US',
    company: "Astral Innovate Solutions",
    emails: ['henry.turner@gmail.com'],
    phone_numbers: ['110-9876-5432'],
    desk_name: 'Research',
    desk_color: "#FF5733",
    internal_brand_name: 'Research Hub',
    client_labels: [
      {
        name: 'Research',
        color: '#10B981'
      }
    ],
    campaign: "Velocity Vista Promo",
    status: 1,
    balance: 850.75,
    affiliate_names: ['Research Partners'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Olivia Davis', avatar: '/assets/avatars/avatar-miron-vitold.png' }],
    client_teams: [{ name: 'Research Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 11,
    full_name: 'Ella Cooper',
    country: 'CA',
    company: "Zenith Dynamics Corporation",
    emails: ['ella.cooper@gmail.com'],
    phone_numbers: ['120-5678-1234'],
    desk_name: 'Quality',
    desk_color: "#FF5733",
    internal_brand_name: 'Quality Experts',
    client_labels: [
      {
        name: 'Quality Assurance',
        color: '#3366FF'
      }
    ],
    campaign: "Nebula Quest Campaign",
    status: 2,
    balance: 600.50,
    affiliate_names: ['Quality Solutions'],
    affiliate_color: "#22C55E",
    agents: [{ name: 'Michael Brown', avatar: '/assets/avatars/avatar-miron-vitold.png' }],
    client_teams: [{ name: 'QA Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 12,
    full_name: 'Jackie Miller',
    country: 'GB',
    company: "Eclipse Synergy Tech",
    emails: ['jackie.miller@gmail.com'],
    phone_numbers: ['130-1234-5678'],
    desk_name: 'Public Relations',
    desk_color: "#FFC300",
    internal_brand_name: 'PR Masters',
    client_labels: [
      {
        name: 'Public',
        color: '#06AED4'
      }
    ],
    campaign: "Eclipse Innovate Drive",
    status: 3,
    balance: 950.25,
    affiliate_names: ['PR Partners'],
    affiliate_color: "#637381",
    agents: [{ name: 'Emily Johnson', avatar: '/assets/avatars/avatar-neha-punita.png' }],
    client_teams: [{ name: 'PR Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 13,
    full_name: 'Daniel Turner',
    country: 'AU',
    company: "Pinnacle Ventures International",
    emails: ['daniel.turner@gmail.com'],
    phone_numbers: ['140-5678-1234'],
    desk_name: 'Security',
    desk_color: "#FF5733",
    internal_brand_name: 'Security Solutions',
    client_labels: [
      {
        name: 'Security',
        color: '#10B981'
      }
    ],
    campaign: "Horizon Harmony Campaign",
    status: 1,
    balance: 800.75,
    affiliate_names: ['Security Experts'],
    affiliate_color: "#06AED4",
    agents: [{ name: 'Alex Smith', avatar: '/assets/avatars/avatar-omar-darboe.png' }],
    client_teams: [{ name: 'Security Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 14,
    full_name: 'Sophia Turner',
    country: 'US',
    company: "Omega Nexus Holdings",
    emails: ['sophia.turner@gmail.com'],
    phone_numbers: ['150-9876-5432'],
    desk_name: 'Analytics',
    desk_color: "#3366FF",
    internal_brand_name: 'Data Analytics Pro',
    client_labels: [
      {
        name: 'Analytics',
        color: '#3366FF'
      }
    ],
    campaign: "Quantum Leap Launch",
    status: 2,
    balance: 700.50,
    affiliate_names: ['Analytics Solutions'],
    affiliate_color: "#00A76F",
    agents: [{ name: 'Jane Doe', avatar: '/assets/avatars/avatar-penjani-inyene.png' }],
    client_teams: [{ name: 'Analytics Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 15,
    full_name: 'Ariana Lang',
    country: 'SA',
    company: "Nexus Synergy Technologies",
    emails: ['avery43@hotmail.com'],
    phone_numbers: ['408-439-8033'],
    desk_name: 'Support',
    desk_color: "#FFC300",
    internal_brand_name: 'Supportive Solutions',
    client_labels: [
      {
        name: 'Customer Support',
        color: '#06AED4'
      }
    ],
    campaign: "Skyline Showcase",
    status: 3,
    balance: 1200.25,
    affiliate_names: ['Affiliate Z'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Agent Brown', avatar: '/assets/avatars/avatar-siegbert-gottfried.png' }],
    client_teams: [{ name: 'Support Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
];

export const agentsMockList = [
  {
    id: 1,
    name: 'Angelique Morse',
    avatar: '/assets/avatars/avatar-alcides-antonio.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 5 * 60 * 1000),
    teams: [
      { name: 'Sales Team', color: '#06AED4' },
    ],
    desks: [
      { name: 'Finance', color: '#00A76F' },
    ],
    role: 'Customer Service RepreSentative',
    assigned_tickets: 8,
    close_tickets: 4,
    pending_tickets: 12,
    contact_visitor: 5,
    calls: 4,
    internal_calls: 1,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 14,
    internal_message: 7,
    email_sent: 5,
  },
  {
    id: 2,
    name: 'Jane Smith',
    avatar: '/assets/avatars/avatar-marcus-finn.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Support Team', color: '#3366FF' },
    ],
    desks: [
      { name: 'Legal', color: '#637381' },
    ],
    role: 'Technical Support Agent',
    assigned_tickets: 4,
    close_tickets: 2,
    pending_tickets: 4,
    contact_visitor: 12,
    calls: 5,
    internal_calls: 2,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 45,
    internal_message: 1,
    email_sent: 8,
  },
  {
    id: 3,
    name: 'Robert White',
    avatar: '/assets/avatars/avatar-carson-darrin.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Marketing Team', color: '#10B981' },
    ],
    desks: [
      { name: 'Research', color: '#06AED4' },
    ],
    role: 'Call Center Operator',
    assigned_tickets: 8,
    close_tickets: 15,
    pending_tickets: 10,
    contact_visitor: 8,
    calls: 4,
    internal_calls: 14,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 7,
    visitor_message: 8,
    internal_message: 5,
    email_sent: 6,
  },
  {
    id: 4,
    name: 'Brycen Jimenez',
    avatar: '/assets/avatars/avatar-fran-perez.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 12 * 60 * 1000),
    teams: [
      { name: 'Development Team', color: '#637381' },
    ],
    desks: [
      { name: 'Development', color: '#3366FF' },
    ],
    role: 'Help Desk Specialist',
    assigned_tickets: 12,
    close_tickets: 8,
    pending_tickets: 14,
    contact_visitor: 6,
    calls: 8,
    internal_calls: 4,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 6,
    internal_message: 5,
    email_sent: 14,
  },
  {
    id: 5,
    name: 'Chase Day',
    avatar: '/assets/avatars/avatar-penjani-inyene.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'HR Team', color: '#3366FF' },
    ],
    desks: [
      { name: 'Public Relations', color: '#22C55E' },
    ],
    role: 'Live Chat Support Agent',
    assigned_tickets: 15,
    close_tickets: 10,
    pending_tickets: 5,
    contact_visitor: 8,
    calls: 7,
    internal_calls: 6,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 14,
    internal_message: 10,
    email_sent: 8,
  },
  {
    id: 6,
    name: 'Sophie Adams',
    avatar: '/assets/avatars/avatar-omar-darboe.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Finance Team', color: '#06AED4' },
    ],
    desks: [
      { name: 'Security', color: '#637381' },
    ],
    role: 'Customer Support Specialist',
    assigned_tickets: 8,
    close_tickets: 4,
    pending_tickets: 12,
    contact_visitor: 9,
    calls: 5,
    internal_calls: 14,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 12,
    visitor_message: 16,
    internal_message: 12,
    email_sent: 5,
  },
  {
    id: 7,
    name: 'Cortez Herring',
    avatar: '/assets/avatars/avatar-siegbert-gottfried.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 5 * 60 * 1000),
    teams: [
      { name: 'IT Team', color: '#637381' },
    ],
    desks: [
      { name: 'Analytics', color: '#3366FF' },
    ],
    role: 'Sales Support RepreSentative',
    assigned_tickets: 14,
    close_tickets: 10,
    pending_tickets: 8,
    contact_visitor: 6,
    calls: 4,
    internal_calls: 3,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 4,
    internal_message: 12,
    email_sent: 16,
  },
  {
    id: 8,
    name: 'William Taylor',
    avatar: '/assets/avatars/avatar-iulia-albu.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 6 * 60 * 1000),
    teams: [
      { name: 'Customer Success Team', color: '#10B981' },
    ],
    desks: [
      { name: 'Support', color: '#637381' },
    ],
    role: 'Billing Support Agent',
    assigned_tickets: 5,
    close_tickets: 8,
    pending_tickets: 12,
    contact_visitor: 4,
    calls: 16,
    internal_calls: 14,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 4,
    visitor_message: 5,
    internal_message: 12,
    email_sent: 8,
  },
  {
    id: 9,
    name: 'Deja Brady',
    avatar: '/assets/avatars/avatar-nasimiyu-danai.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Product Team', color: '#3366FF' },
    ],
    desks: [
      { name: 'Retention', color: '#10B981' },
    ],
    role: 'Customer Success Manager',
    assigned_tickets: 16,
    close_tickets: 14,
    pending_tickets: 12,
    contact_visitor: 16,
    calls: 5,
    internal_calls: 14,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 12,
    visitor_message: 5,
    internal_message: 8,
    email_sent: 3,
  },
  {
    id: 10,
    name: 'Giana Brandt',
    avatar: '/assets/avatars/avatar-miron-vitold.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Quality Assurance Team', color: '#22C55E' },
    ],
    desks: [
      { name: 'Sales', color: '#3366FF' },
    ],
    role: 'Social Media Support Specialist',
    assigned_tickets: 5,
    close_tickets: 10,
    pending_tickets: 8,
    contact_visitor: 5,
    calls: 16,
    internal_calls: 8,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 5,
    visitor_message: 24,
    internal_message: 8,
    email_sent: 4,
  },
  {
    id: 11,
    name: 'Harrison Stein',
    avatar: '/assets/avatars/avatar-neha-punita.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 26 * 60 * 1000),
    teams: [
      { name: 'Operations Team', color: '#637381' },
    ],
    desks: [
      { name: 'Support', color: '#06AED4' },
    ],
    role: 'Escalation Specialist',
    assigned_tickets: 10,
    close_tickets: 14,
    pending_tickets: 6,
    contact_visitor: 4,
    calls: 19,
    internal_calls: 16,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 4,
    internal_message: 8,
    email_sent: 9,
  },
  {
    id: 12,
    name: 'Jayvion Simon',
    avatar: '/assets/avatars/avatar-omar-darboe.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Legal Team', color: '#06AED4' },
    ],
    desks: [
      { name: 'Marketing', color: '#10B981' },
    ],
    role: 'Quality Assurance Analyst',
    assigned_tickets: 8,
    close_tickets: 8,
    pending_tickets: 5,
    contact_visitor: 4,
    calls: 4,
    internal_calls: 2,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 17,
    visitor_message: 15,
    internal_message: 4,
    email_sent: 12,
  },
  {
    id: 13,
    name: 'Lainey Davidson',
    avatar: '/assets/avatars/avatar-penjani-inyene.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 12 * 60 * 1000),
    teams: [
      { name: 'Design Team', color: '#00A76F' },
    ],
    desks: [
      { name: 'Development', color: '#3366FF' },
    ],
    role: 'Complaints Resolution Agent',
    assigned_tickets: 4,
    close_tickets: 2,
    pending_tickets: 8,
    contact_visitor: 4,
    calls: 5,
    internal_calls: 21,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 12,
    visitor_message: 7,
    internal_message: 2,
    email_sent: 8,
  },
  {
    id: 14,
    name: 'Olivia Martin',
    avatar: '/assets/avatars/avatar-seo-hyeon-ji.png',
    online: false,
    onlineTime: new Date(),
    teams: [
      { name: 'Research Team', color: '#3366FF' },
    ],
    desks: [
      { name: 'HR', color: '#06AED4' },
    ],
    role: 'Client Relations Coordinator',
    assigned_tickets: 8,
    close_tickets: 5,
    pending_tickets: 8,
    contact_visitor: 4,
    calls: 7,
    internal_calls: 4,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 16,
    visitor_message: 2,
    internal_message: 5,
    email_sent: 10,
  },
  {
    id: 15,
    name: 'Lucian Obrien',
    avatar: '/assets/avatars/avatar-siegbert-gottfried.png',
    online: true,
    onlineTime: new Date(new Date().getTime() - 16 * 60 * 1000),
    teams: [
      { name: 'Training Team', color: '#10B981' },
    ],
    desks: [
      { name: 'Support', color: '#637381' },
    ],
    role: 'Service Desk Analyst',
    assigned_tickets: 4,
    close_tickets: 1,
    pending_tickets: 2,
    contact_visitor: 3,
    calls: 4,
    internal_calls: 1,
    call_duration: '00:00:00',
    internal_call_duration: '00:00:00',
    ticket_message: 8,
    visitor_message: 2,
    internal_message: 2,
    email_sent: 5,
  },
];

export const leadMockedList = [
  {
    "id": 1,
    "client_id": 2568,
    "status": "Sent",
    "duplicate": true,
    "verified": true,
    "first_name": "Alice",
    "last_name": "Johnson",
    "email": "alice.johnson@outlook.com",
    "phone": "49-8623-1547",
    "country": "UA",
    "language": "uk",
    "campaign": "My Campaign",
    "description": "Exclusive Note",
    "deposit": 120,
    "ftd_amount": 80,
    "ftd_date": new Date(),
    "source_brand": "Source Brand",
    "registration_date": new Date(),
    "note": "Exclusive Note",
    "brand_name": "My Brand",
    "brand_status": "My Brand",
    "affiliate_name": "Security Experts",
    "team_name": "Security Team",
    "agent_name": "Agent Brown",
    "labels": [
      { "name": "Customer Support", "color": "#06AED4" },
      { "name": "Conversion", "color": "#3366FF" }
    ],
    "source": "Source",
    "ip_address": "192.168.16.1",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 2,
    "client_id": 1598,
    "status": "on hold",
    "duplicate": false,
    "verified": true,
    "first_name": "Bob",
    "last_name": "Smith",
    "email": "bob.smith@outlook.com",
    "phone": "57-3098-6241",
    "country": "US",
    "language": "en",
    "campaign": "Another Campaign",
    "description": "Additional Note",
    "deposit": 150,
    "ftd_amount": 100,
    "ftd_date": new Date(),
    "source_brand": "Another Source Brand",
    "registration_date": new Date(),
    "note": "Additional Note",
    "brand_name": "Another Brand",
    "brand_status": "Another Brand Status",
    "affiliate_name": "Another Affiliate",
    "team_name": "Another Team",
    "agent_name": "Another Agent",
    "labels": [
      { "name": "Sales", "color": "#3366FF" },
      { "name": "Customer Care", "color": "#637381" }
    ],
    "source": "Another Source",
    "ip_address": "10.0.16.2",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 3,
    "client_id": 4585,
    "status": "on hold",
    "duplicate": true,
    "verified": false,
    "first_name": "Charlie",
    "last_name": "Davis",
    "email": "charlie.davis@outlook.com",
    "phone": "62-1854-7390",
    "country": "CA",
    "language": "fr",
    "campaign": "Yet Another Campaign",
    "description": "Yet Another Note",
    "deposit": 200,
    "ftd_amount": 120,
    "ftd_date": new Date(),
    "source_brand": "Yet Another Source Brand",
    "registration_date": new Date(),
    "note": "Yet Another Note",
    "brand_name": "Yet Another Brand",
    "brand_status": "Yet Another Brand Status",
    "affiliate_name": "Yet Another Affiliate",
    "team_name": "Yet Another Team",
    "agent_name": "Yet Another Agent",
    "labels": [
      { "name": "Support", "color": "#10B981" },
      { "name": "Supportive Solutions", "color": "#06AED4" }
    ],
    "source": "Yet Another Source",
    "ip_address": "172.16.16.3",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 4,
    "client_id": 1498,
    "status": "Sent",
    "duplicate": false,
    "verified": false,
    "first_name": "David",
    "last_name": "Miller",
    "email": "david.miller@gmail.com",
    "phone": "34-7265-9182",
    "country": "DE",
    "language": "de",
    "campaign": "Special Campaign",
    "description": "Special Note",
    "deposit": 180,
    "ftd_amount": 90,
    "ftd_date": new Date(),
    "source_brand": "Special Source Brand",
    "registration_date": new Date(),
    "note": "Special Note",
    "brand_name": "Special Brand",
    "brand_status": "Special Brand Status",
    "affiliate_name": "Special Affiliate",
    "team_name": "Special Team",
    "agent_name": "Special Agent",
    "labels": [
      { "name": "Marketing", "color": "#FF5733" },
      { "name": "Development", "color": "#22C55E" }
    ],
    "source": "Special Source",
    "ip_address": "192.168.0.16",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 5,
    "client_id": 4521,
    "status": "on hold",
    "duplicate": true,
    "verified": true,
    "first_name": "Eva",
    "last_name": "Williams",
    "email": "eva.williams@gmail.com",
    "phone": "81-5049-3627",
    "country": "FR",
    "language": "fr",
    "campaign": "Exclusive Campaign",
    "description": "Exclusive Note",
    "deposit": 160,
    "ftd_amount": 110,
    "ftd_date": new Date(),
    "source_brand": "Exclusive Source Brand",
    "registration_date": "2024-02-12T08:45:00Z",
    "note": "Exclusive Note",
    "brand_name": "Exclusive Brand",
    "brand_status": "Exclusive Brand Status",
    "affiliate_name": "Exclusive Affiliate",
    "team_name": "Exclusive Team",
    "agent_name": "Exclusive Agent",
    "labels": [
      { "name": "Human Resources", "color": "#3366FF" },
      { "name": "Connect", "color": "#637381" }
    ],
    "source": "Exclusive Source",
    "ip_address": "10.16.0.5",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 6,
    "client_id": 6287,
    "status": "Sent",
    "duplicate": false,
    "verified": true,
    "first_name": "Frank",
    "last_name": "Wilson",
    "email": "frank.wilson@outlook.com",
    "phone": "28-9176-4035",
    "country": "IT",
    "language": "it",
    "campaign": "Special Promotion",
    "description": "Special Promotion Note",
    "deposit": 140,
    "ftd_amount": 95,
    "ftd_date": new Date(),
    "source_brand": "Special Promotion Source Brand",
    "registration_date": new Date(),
    "note": "Special Promotion Note",
    "brand_name": "Special Promotion Brand",
    "brand_status": "Special Promotion Brand Status",
    "affiliate_name": "Special Promotion Affiliate",
    "team_name": "Special Promotion Team",
    "agent_name": "Special Promotion Agent",
    "labels": [
      { "name": "Development", "color": "#10B981" },
      { "name": "Marketing", "color": "#3366FF" }
    ],
    "source": "Special Promotion Source",
    "ip_address": "192.16.0.6",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 7,
    "client_id": 5757,
    "status": "Sent",
    "duplicate": true,
    "verified": false,
    "first_name": "Grace",
    "last_name": "Martin",
    "email": "grace.martin@gmail.com",
    "phone": "93-2405-8167",
    "country": "ES",
    "language": "es",
    "campaign": "Year-End Sale",
    "description": "Year-End Sale Note",
    "deposit": 170,
    "ftd_amount": 130,
    "ftd_date": new Date(),
    "source_brand": "Year-End Sale Source Brand",
    "registration_date": new Date(),
    "note": "Year-End Sale Note",
    "brand_name": "Year-End Sale Brand",
    "brand_status": "Year-End Sale Brand Status",
    "affiliate_name": "Year-End Sale Affiliate",
    "team_name": "Year-End Sale Team",
    "agent_name": "Year-End Sale Agent",
    "labels": [
      { "name": "Customer Support", "color": "#3366FF" },
      { "name": "Development", "color": "#637381" }
    ],
    "source": "Year-End Sale Source",
    "ip_address": "10.1.16.7",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 8,
    "client_id": 2498,
    "status": "Error",
    "duplicate": false,
    "verified": true,
    "first_name": "Eva",
    "last_name": "Anderson",
    "email": "eva.anderson@gmail.com",
    "phone": "46-7312-5890",
    "country": "GB",
    "language": "en",
    "campaign": "Special Campaign",
    "description": "Special Note",
    "deposit": 180,
    "ftd_amount": 90,
    "ftd_date": new Date(),
    "source_brand": "Special Source Brand",
    "registration_date": new Date(),
    "note": "Special Note",
    "brand_name": "Special Brand",
    "brand_status": "Special Brand Status",
    "affiliate_name": "Special Affiliate",
    "team_name": "Special Team",
    "agent_name": "Special Agent",
    "labels": [
      { "name": "Development", "color": "#637381" },
      { "name": "Marketing", "color": "#22C55E" }
    ],
    "source": "Special Source",
    "ip_address": "172.16.0.8",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 9,
    "client_id": 2580,
    "status": "on hold",
    "duplicate": true,
    "verified": false,
    "first_name": "David",
    "last_name": "Miller",
    "email": "david.miller@gmail.com",
    "phone": "75-1890-4326",
    "country": "DE",
    "language": "de",
    "campaign": "Exclusive Campaign",
    "description": "Exclusive Note",
    "deposit": 220,
    "ftd_amount": 110,
    "ftd_date": new Date(),
    "source_brand": "Exclusive Source Brand",
    "registration_date": new Date(),
    "note": "Exclusive Note",
    "brand_name": "Exclusive Brand",
    "brand_status": "Exclusive Brand Status",
    "affiliate_name": "Exclusive Affiliate",
    "team_name": "Exclusive Team",
    "agent_name": "Exclusive Agent",
    "labels": [
      { "name": "Information", "color": "#3366FF" },
      { "name": "Human Resources", "color": "#10B981" }
    ],
    "source": "Exclusive Source",
    "ip_address": "192.16.1.9",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 10,
    "client_id": 1596,
    "status": "Error",
    "duplicate": false,
    "verified": true,
    "first_name": "Fiona",
    "last_name": "Taylor",
    "email": "fiona.taylor@outlook.com",
    "phone": "19-5437-2086",
    "country": "FR",
    "language": "fr",
    "campaign": "Exclusive Deal",
    "description": "Exclusive Note 2",
    "deposit": 200,
    "ftd_amount": 100,
    "ftd_date": new Date(),
    "source_brand": "Exclusive Deal Source Brand",
    "registration_date": new Date(),
    "note": "Exclusive Note 2",
    "brand_name": "Exclusive Deal Brand",
    "brand_status": "Exclusive Deal Brand Status",
    "affiliate_name": "Exclusive Deal Affiliate",
    "team_name": "Exclusive Deal Team",
    "agent_name": "Exclusive Deal Agent",
    "labels": [
      { "name": "IT Support", "color": "#FF5733" },
      { "name": "Information", "color": "#06AED4" }
    ],
    "source": "Exclusive Deal Source",
    "ip_address": "10.16.1.10",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 11,
    "client_id": 2047,
    "status": "Sent",
    "duplicate": true,
    "verified": false,
    "first_name": "George",
    "last_name": "Brown",
    "email": "george.brown@gmail.com",
    "phone": "68-3207-9154",
    "country": "IT",
    "language": "it",
    "campaign": "Special Deal",
    "description": "Special Note 2",
    "deposit": 180,
    "ftd_amount": 90,
    "ftd_date": new Date(),
    "source_brand": "Special Deal Source Brand",
    "registration_date": new Date(),
    "note": "Special Note 2",
    "brand_name": "Special Deal Brand",
    "brand_status": "Special Deal Brand Status",
    "affiliate_name": "Special Deal Affiliate",
    "team_name": "Special Deal Team",
    "agent_name": "Special Deal Agent",
    "labels": [
      { "name": "Finance", "color": "#3366FF" },
      { "name": "Legal", "color": "#637381" }
    ],
    "source": "Special Deal Source",
    "ip_address": "192.17.16.11",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 12,
    "client_id": 4625,
    "status": "Sent",
    "duplicate": false,
    "verified": true,
    "first_name": "Helen",
    "last_name": "Jones",
    "email": "helen.jones@gmail.com",
    "phone": "53-6941-8072",
    "country": "ES",
    "language": "es",
    "campaign": "Exclusive Offer",
    "description": "Exclusive Note 3",
    "deposit": 220,
    "ftd_amount": 110,
    "ftd_date": new Date(),
    "source_brand": "Exclusive Offer Source Brand",
    "registration_date": new Date(),
    "note": "Exclusive Note 3",
    "brand_name": "Exclusive Offer Brand",
    "brand_status": "Exclusive Offer Brand Status",
    "affiliate_name": "Exclusive Offer Affiliate",
    "team_name": "Exclusive Offer Team",
    "agent_name": "Exclusive Offer Agent",
    "labels": [
      { "name": "Research", "color": "#10B981" },
      { "name": "Customer", "color": "#3366FF" }
    ],
    "source": "Exclusive Offer Source",
    "ip_address": "10.2.16.12",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 13,
    "client_id": 1357,
    "status": "Error",
    "duplicate": true,
    "verified": true,
    "first_name": "Ivan",
    "last_name": "Kovacs",
    "email": "ivan.kovacs@gmail.com",
    "phone": "37-8102-4659",
    "country": "HU",
    "language": "hu",
    "campaign": "Special Offer",
    "description": "Special Note 3",
    "deposit": 180,
    "ftd_amount": 90,
    "ftd_date": new Date(),
    "source_brand": "Special Offer Source Brand",
    "registration_date": new Date(),
    "note": "Special Note 3",
    "brand_name": "Special Offer Brand",
    "brand_status": "Special Offer Brand Status",
    "affiliate_name": "Special Offer Affiliate",
    "team_name": "Special Offer Team",
    "agent_name": "Special Offer Agent",
    "labels": [
      { "name": "Research", "color": "#3366FF" },
      { "name": "Quality", "color": "#FF5733" }
    ],
    "source": "Special Offer Source",
    "ip_address": "172.16.1.13",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 14,
    "client_id": 3036,
    "status": "on hold",
    "duplicate": false,
    "verified": true,
    "first_name": "Elena",
    "last_name": "Gomez",
    "email": "elena.gomez@outlook.com",
    "phone": "64-9753-2810",
    "country": "ES",
    "language": "es",
    "campaign": "Flash Sale",
    "description": "Exclusive Tech Bundle",
    "deposit": 180,
    "ftd_amount": 120,
    "ftd_date": new Date(),
    "source_brand": "Flash Sale Source Brand",
    "registration_date": new Date(),
    "note": "Flash Sale Tech Bundle",
    "brand_name": "Flash Sale Brand",
    "brand_status": "Flash Sale Brand Status",
    "affiliate_name": "Flash Sale Affiliate",
    "team_name": "Flash Sale Team",
    "agent_name": "Flash Sale Agent",
    "labels": [
      { "name": "Customer", "color": "#10B981" },
      { "name": "Conversion", "color": "#637381" }
    ],
    "source": "Flash Sale Source",
    "ip_address": "192.16.2.14",
    "created_at": new Date(),
    "updated_at": new Date()
  },
  {
    "id": 15,
    "client_id": 2498,
    "status": "on hold",
    "duplicate": false,
    "verified": true,
    "first_name": "Michael",
    "last_name": "Johnson",
    "email": "michael.johnson@gmail.com",
    "phone": "91-2840-6573",
    "country": "US",
    "language": "en",
    "campaign": "Special Offer",
    "description": "Limited Edition Gadget",
    "deposit": 150,
    "ftd_amount": 80,
    "ftd_date": new Date(),
    "source_brand": "Special Offer Source Brand",
    "registration_date": new Date(),
    "note": "Exclusive Gadget Note",
    "brand_name": "Special Offer Brand",
    "brand_status": "Special Offer Brand Status",
    "affiliate_name": "Special Offer Affiliate",
    "team_name": "Special Offer Team",
    "agent_name": "Special Offer Agent",
    "labels": [
      { "name": "Marketing", "color": "#06AED4" },
      { "name": "Support", "color": "#FFC300" }
    ],
    "source": "Special Offer Source",
    "ip_address": "10.16.2.15",
    "created_at": new Date(),
    "updated_at": new Date()
  }
];

export const affiliateMockedList = [
  {
    "id": 1,
    "full_name": "Adam Johnson",
    "active": true,
    "total_valid_leads": 13,
    "total_duplicates": 5,
    "total_ftd": 10,
    "total_leads": 50
  },
  {
    "id": 2,
    "full_name": "Sarah Miller",
    "active": true,
    "total_valid_leads": 20,
    "total_duplicates": 8,
    "total_ftd": 15,
    "total_leads": 60
  },
  {
    "id": 3,
    "full_name": "Alex Rodriguez",
    "active": false,
    "total_valid_leads": 8,
    "total_duplicates": 3,
    "total_ftd": 5,
    "total_leads": 30
  },
  {
    "id": 4,
    "full_name": "Emily Davis",
    "active": true,
    "total_valid_leads": 18,
    "total_duplicates": 7,
    "total_ftd": 12,
    "total_leads": 55
  },
  {
    "id": 5,
    "full_name": "Ryan Smith",
    "active": false,
    "total_valid_leads": 12,
    "total_duplicates": 4,
    "total_ftd": 8,
    "total_leads": 40
  },
  {
    "id": 6,
    "full_name": "Olivia Taylor",
    "active": true,
    "total_valid_leads": 15,
    "total_duplicates": 6,
    "total_ftd": 11,
    "total_leads": 48
  },
  {
    "id": 7,
    "full_name": "Ethan Clark",
    "active": true,
    "total_valid_leads": 25,
    "total_duplicates": 10,
    "total_ftd": 20,
    "total_leads": 70
  },
  {
    "id": 8,
    "full_name": "Ava Wilson",
    "active": false,
    "total_valid_leads": 10,
    "total_duplicates": 2,
    "total_ftd": 7,
    "total_leads": 35
  },
  {
    "id": 9,
    "full_name": "Daniel Brown",
    "active": true,
    "total_valid_leads": 22,
    "total_duplicates": 9,
    "total_ftd": 18,
    "total_leads": 65
  },
  {
    "id": 10,
    "full_name": "Mia Anderson",
    "active": false,
    "total_valid_leads": 14,
    "total_duplicates": 5,
    "total_ftd": 9,
    "total_leads": 42
  },
  {
    "id": 11,
    "full_name": "Matthew White",
    "active": true,
    "total_valid_leads": 17,
    "total_duplicates": 6,
    "total_ftd": 13,
    "total_leads": 52
  },
  {
    "id": 12,
    "full_name": "Sophia Turner",
    "active": false,
    "total_valid_leads": 11,
    "total_duplicates": 4,
    "total_ftd": 8,
    "total_leads": 38
  },
  {
    "id": 13,
    "full_name": "Lucas Garcia",
    "active": true,
    "total_valid_leads": 19,
    "total_duplicates": 7,
    "total_ftd": 15,
    "total_leads": 58
  },
  {
    "id": 14,
    "full_name": "Lily Martinez",
    "active": true,
    "total_valid_leads": 16,
    "total_duplicates": 5,
    "total_ftd": 12,
    "total_leads": 45
  },
  {
    "id": 15,
    "full_name": "Jackson Thompson",
    "active": false,
    "total_valid_leads": 9,
    "total_duplicates": 3,
    "total_ftd": 6,
    "total_leads": 33
  }
];

export const brandMockedList = [
  {
    "id": 1,
    "full_name": "Affinity Link",
    "active": true,
    "total_valid_leads": 13,
    "total_ftd": 10,
    "total_leads": 50
  },
  {
    "id": 2,
    "full_name": "Quantum Connect",
    "active": true,
    "total_valid_leads": 20,
    "total_ftd": 15,
    "total_leads": 60
  },
  {
    "id": 3,
    "full_name": "Stellar Referral",
    "active": false,
    "total_valid_leads": 8,
    "total_ftd": 5,
    "total_leads": 30
  },
  {
    "id": 4,
    "full_name": "Velocity Earn",
    "active": true,
    "total_valid_leads": 18,
    "total_ftd": 12,
    "total_leads": 55
  },
  {
    "id": 5,
    "full_name": "Precision Partners",
    "active": false,
    "total_valid_leads": 12,
    "total_ftd": 8,
    "total_leads": 40
  },
  {
    "id": 6,
    "full_name": "Zenith Affiliate",
    "active": true,
    "total_valid_leads": 15,
    "total_ftd": 11,
    "total_leads": 48
  },
  {
    "id": 7,
    "full_name": "Apex Referral Hub",
    "active": true,
    "total_valid_leads": 25,
    "total_ftd": 20,
    "total_leads": 70
  },
  {
    "id": 8,
    "full_name": "Quantum Leap Earnings",
    "active": false,
    "total_valid_leads": 10,
    "total_ftd": 7,
    "total_leads": 35
  },
  {
    "id": 9,
    "full_name": "Elite Linkage",
    "active": true,
    "total_valid_leads": 22,
    "total_ftd": 18,
    "total_leads": 65
  },
  {
    "id": 10,
    "full_name": "Pinnacle Partnership",
    "active": false,
    "total_valid_leads": 14,
    "total_ftd": 9,
    "total_leads": 42
  },
  {
    "id": 11,
    "full_name": "Swift Sync Affiliates",
    "active": true,
    "total_valid_leads": 17,
    "total_ftd": 13,
    "total_leads": 52
  },
  {
    "id": 12,
    "full_name": "Nexus Rewards",
    "active": false,
    "total_valid_leads": 11,
    "total_ftd": 8,
    "total_leads": 38
  },
  {
    "id": 13,
    "full_name": "Echelon Earnings",
    "active": true,
    "total_valid_leads": 19,
    "total_ftd": 15,
    "total_leads": 58
  },
  {
    "id": 14,
    "full_name": "Virtuoso Ventures",
    "active": true,
    "total_valid_leads": 16,
    "total_ftd": 12,
    "total_leads": 45
  },
  {
    "id": 15,
    "full_name": "Summit Affluence",
    "active": false,
    "total_valid_leads": 9,
    "total_ftd": 6,
    "total_leads": 33
  }
];

export const injectionMockedList = [
  {
    "id": 1,
    "name": "File1.xlsx",
    "brand": "Affinity Link",
    "affiliate": "Adam Johnson",
    "team": "Support Team",
    "agent": "Samuel Thomas",
    "client_labels": [
      {
        "name": "Customer Support",
        "color": "#06AED4"
      }
    ],
    "country": "US",
    "dripping": true,
    "internal_id": "a4e008a523b0",
    "total_count": 15,
    "validated_count": 12,
    "invalid_count": 3,
    "duplicate_emails": 0,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T00:00:00Z"
  },
  {
    "id": 2,
    "name": "Document2.csv",
    "brand": "Quantum Connect",
    "affiliate": "Sarah Miller",
    "team": "Sales Team",
    "agent": "Jessica Collins",
    "client_labels": [
      {
        "name": "Sales Support",
        "color": "#00A76F"
      }
    ],
    "country": "CA",
    "dripping": false,
    "internal_id": "b2f71cd8a9e4",
    "total_count": 20,
    "validated_count": 18,
    "invalid_count": 2,
    "duplicate_emails": 1,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T00:15:00Z"
  },
  {
    "id": 3,
    "name": "Report3.xlsx",
    "brand": "Stellar Referral",
    "affiliate": "Alex Rodriguez",
    "team": "Marketing Team",
    "agent": "Emily Harris",
    "client_labels": [
      {
        "name": "Marketing Support",
        "color": "#3366FF"
      }
    ],
    "country": "GB",
    "dripping": true,
    "internal_id": "c9d642f07e51",
    "total_count": 18,
    "validated_count": 15,
    "invalid_count": 3,
    "duplicate_emails": 2,
    "duplicate_phones": 1,
    "created_at": "2024-01-15T00:30:00Z"
  },
  {
    "id": 4,
    "name": "Data4.csv",
    "brand": "Velocity Earn",
    "affiliate": "Emily Davis",
    "team": "Tech Support",
    "agent": "Michael Turner",
    "client_labels": [
      {
        "name": "Tech Support",
        "color": "#637381"
      }
    ],
    "country": "AU",
    "dripping": false,
    "internal_id": "e87a9a5f3b5c",
    "total_count": 20,
    "validated_count": 17,
    "invalid_count": 3,
    "duplicate_emails": 0,
    "duplicate_phones": 2,
    "created_at": "2024-01-15T00:45:00Z"
  },
  {
    "id": 5,
    "name": "Sheet5.xlsx",
    "brand": "Precision Partners",
    "affiliate": "Ryan Smith",
    "team": "Finance Team",
    "agent": "Sophie Roberts",
    "client_labels": [
      {
        "name": "Financial Support",
        "color": "#10B981"
      }
    ],
    "country": "GN",
    "dripping": true,
    "internal_id": "f5a6dc9e2e4d",
    "total_count": 15,
    "validated_count": 13,
    "invalid_count": 2,
    "duplicate_emails": 1,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T01:00:00Z"
  },
  {
    "id": 6,
    "name": "Table6.csv",
    "brand": "Zenith Affiliate",
    "affiliate": "Olivia Taylor",
    "team": "Operations Team",
    "agent": "Daniel Johnson",
    "client_labels": [
      {
        "name": "Operations Support",
        "color": "#3366FF"
      }
    ],
    "country": "IN",
    "dripping": false,
    "internal_id": "ab8dc1f6ac7a",
    "total_count": 25,
    "validated_count": 22,
    "invalid_count": 3,
    "duplicate_emails": 0,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T01:15:00Z"
  },
  {
    "id": 7,
    "name": "Record7.xlsx",
    "brand": "Apex Referral Hub",
    "affiliate": "Ethan Clark",
    "team": "HR Team",
    "agent": "Sophia Turner",
    "client_labels": [
      {
        "name": "HR Support",
        "color": "#06AED4"
      }
    ],
    "country": "BR",
    "dripping": true,
    "internal_id": "c53f4b2d5a8f",
    "total_count": 22,
    "validated_count": 19,
    "invalid_count": 3,
    "duplicate_emails": 2,
    "duplicate_phones": 1,
    "created_at": "2024-01-15T01:30:00Z"
  },
  {
    "id": 8,
    "name": "List8.csv",
    "brand": "Quantum Leap Earnings",
    "affiliate": "Ava Wilson",
    "team": "Legal Team",
    "agent": "Jackson Brown",
    "client_labels": [
      {
        "name": "Legal Support",
        "color": "#637381"
      }
    ],
    "country": "FR",
    "dripping": false,
    "internal_id": "d27e3bf82456",
    "total_count": 18,
    "validated_count": 16,
    "invalid_count": 2,
    "duplicate_emails": 0,
    "duplicate_phones": 2,
    "created_at": "2024-01-15T01:45:00Z"
  },
  {
    "id": 9,
    "name": "Workbook9.xlsx",
    "brand": "Elite Linkage",
    "affiliate": "Daniel Brown",
    "team": "Product Team",
    "agent": "Isabella Davis",
    "client_labels": [
      {
        "name": "Product Support",
        "color": "#3366FF"
      }
    ],
    "country": "DE",
    "dripping": true,
    "internal_id": "e6a972d184fc",
    "total_count": 20,
    "validated_count": 17,
    "invalid_count": 3,
    "duplicate_emails": 1,
    "duplicate_phones": 1,
    "created_at": "2024-01-15T02:00:00Z"
  },
  {
    "id": 10,
    "name": "CSVFile10.csv",
    "brand": "Pinnacle Partnership",
    "affiliate": "Mia Anderson",
    "team": "QA Team",
    "agent": "William Garcia",
    "client_labels": [
      {
        "name": "Quality Support",
        "color": "#637381"
      }
    ],
    "country": "ES",
    "dripping": false,
    "internal_id": "f8db17a895b7",
    "total_count": 20,
    "validated_count": 17,
    "invalid_count": 3,
    "duplicate_emails": 2,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T02:15:00Z"
  },
  {
    "id": 11,
    "name": "Report11.xlsx",
    "brand": "Swift Sync Affiliates",
    "affiliate": "Matthew White",
    "team": "Development Team",
    "agent": "Lucy Martinez",
    "client_labels": [
      {
        "name": "Development Support",
        "color": "#10B981"
      }
    ],
    "country": "IT",
    "dripping": true,
    "internal_id": "a2f1e84c1e68",
    "total_count": 15,
    "validated_count": 13,
    "invalid_count": 2,
    "duplicate_emails": 1,
    "duplicate_phones": 1,
    "created_at": "2024-01-15T02:30:00Z"
  },
  {
    "id": 12,
    "name": "Data12.csv",
    "brand": "Nexus Rewards",
    "affiliate": "Sophia Turner",
    "team": "Security Team",
    "agent": "Ethan Garcia",
    "client_labels": [
      {
        "name": "Security Support",
        "color": "#3366FF"
      }
    ],
    "country": "RU",
    "dripping": false,
    "internal_id": "b9e0fb63c65e",
    "total_count": 25,
    "validated_count": 22,
    "invalid_count": 3,
    "duplicate_emails": 0,
    "duplicate_phones": 2,
    "created_at": "2024-01-15T02:45:00Z"
  },
  {
    "id": 13,
    "name": "File13.xlsx",
    "brand": "Echelon Earnings",
    "affiliate": "Lucas Garcia",
    "team": "Analytics Team",
    "agent": "Ava Thompson",
    "client_labels": [
      {
        "name": "Analytics Support",
        "color": "#10B981"
      }
    ],
    "country": "CN",
    "dripping": true,
    "internal_id": "c8d55f1c9a2b",
    "total_count": 22,
    "validated_count": 19,
    "invalid_count": 3,
    "duplicate_emails": 1,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T03:00:00Z"
  },
  {
    "id": 14,
    "name": "Sheet14.csv",
    "brand": "Virtuoso Ventures",
    "affiliate": "Lily Martinez",
    "team": "Creative Team",
    "agent": "Leo Wilson",
    "client_labels": [
      {
        "name": "Creative Support",
        "color": "#637381"
      }
    ],
    "country": "MX",
    "dripping": false,
    "internal_id": "d8b5f2c6a9d3",
    "total_count": 25,
    "validated_count": 22,
    "invalid_count": 3,
    "duplicate_emails": 0,
    "duplicate_phones": 1,
    "created_at": "2024-01-15T03:15:00Z"
  },
  {
    "id": 15,
    "name": "Document15.xlsx",
    "brand": "Summit Affluence",
    "affiliate": "Jackson Thompson",
    "team": "Management Team",
    "agent": "Olivia White",
    "client_labels": [
      {
        "name": "Management Support",
        "color": "#3366FF"
      }
    ],
    "country": "DE",
    "dripping": true,
    "internal_id": "e2d74f931bc6",
    "total_count": 20,
    "validated_count": 18,
    "invalid_count": 2,
    "duplicate_emails": 2,
    "duplicate_phones": 0,
    "created_at": "2024-01-15T03:30:00Z"
  }
];

export const offersMockedList = [
  {
    id: 1,
    name: "Special Deal",
    country: "US",
    cr: 58,
    apv: 75,
    cpi: 42,
    crg: 65,
    min: 58,
  },
  {
    id: 2,
    name: "Exclusive Offer",
    country: "DE",
    cr: 50,
    apv: 68,
    cpi: 37,
    crg: 58,
    min: 50,
  },
  {
    id: 3,
    name: "Limited Edition",
    country: "FR",
    cr: 62,
    apv: 80,
    cpi: 44,
    crg: 72,
    min: 62,
  },
  {
    id: 4,
    name: "Best Deal",
    country: "IT",
    cr: 55,
    apv: 72,
    cpi: 40,
    crg: 64,
    min: 55,
  }
];

export const positionsMockedList = [
  {
    "id": 1,
    "client_name": "Alice Johnson",
    "client_country": "GB",
    "client_status": {
      "message": "warning",
      "code": 1
    },
    "position_type": 1,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "BTC",
      "currency_symbol": "USD"
    },
    "status": 0,
    "market": "fx",
    "labels": [
      {
        "name": "Sales",
        "color": "#FF5733"
      }
    ],
    "amount": 125,
    "swap": 20,
    "unit": 15,
    "set_rate": 12,
    "opened_amount": 52954.26,
    "market_price": 42808.48,
    "volume": 1062.25,
    "leverage": 20,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 5.5214,
    "client_balance": 1250,
    "client_pl": 75.2839,
    "client_margine_level": 80,
    "client_free_margin": 65,
    "client_used_margin": 43
  },
  {
    "id": 2,
    "client_name": "Bob Smith",
    "client_country": "US",
    "client_status": {
      "message": "info",
      "code": 2
    },
    "position_type": 0,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "ETH",
      "currency_symbol": "USD"
    },
    "status": 1,
    "market": "crypto",
    "labels": [
      {
        "name": "Marketing",
        "color": "#637381"
      }
    ],
    "amount": 150,
    "swap": 25,
    "unit": 18,
    "set_rate": 15,
    "opened_amount": 72093.18,
    "market_price": 3123.65,
    "volume": 256.78,
    "leverage": 25,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -8.1123,
    "client_balance": 1500,
    "client_pl": -32.1987,
    "client_margine_level": 75,
    "client_free_margin": 68,
    "client_used_margin": 52
  },
  {
    "id": 3,
    "client_name": "Charlie Brown",
    "client_country": "CA",
    "client_status": {
      "message": "warning",
      "code": 1
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "XRP",
      "currency_symbol": "USD"
    },
    "status": 2,
    "market": "crypto",
    "labels": [
      {
        "name": "Support",
        "color": "#00A76F"
      }
    ],
    "amount": 100,
    "swap": 15,
    "unit": 10,
    "set_rate": 8,
    "opened_amount": 42036.75,
    "market_price": 1.25,
    "volume": 1050.50,
    "leverage": 15,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 12.4501,
    "client_balance": 1000,
    "client_pl": 120.5643,
    "client_margine_level": 90,
    "client_free_margin": 85,
    "client_used_margin": 42
  },
  {
    "id": 4,
    "client_name": "David White",
    "client_country": "AU",
    "client_status": {
      "message": "error",
      "code": 4
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "LTC",
      "currency_symbol": "USD"
    },
    "status": 0,
    "market": "stocks",
    "labels": [
      {
        "name": "Development",
        "color": "#06AED4"
      }
    ],
    "amount": 80,
    "swap": 12,
    "unit": 8,
    "set_rate": 7,
    "opened_amount": 30964.80,
    "market_price": 169.52,
    "volume": 613.20,
    "leverage": 12,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -5.9732,
    "client_balance": 800,
    "client_pl": -18.6924,
    "client_margine_level": 85,
    "client_free_margin": 75,
    "client_used_margin": 45
  },
  {
    "id": 5,
    "client_name": "Eva Green",
    "client_country": "FR",
    "client_status": {
      "message": "warning",
      "code": 1
    },
    "position_type": 0,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "BTC",
      "currency_symbol": "USD"
    },
    "status": 0,
    "market": "stocks",
    "labels": [
      {
        "name": "Sales",
        "color": "#637381"
      }
    ],
    "amount": 110,
    "swap": 18,
    "unit": 12,
    "set_rate": 10,
    "opened_amount": 48105.36,
    "market_price": 39762.80,
    "volume": 902.50,
    "leverage": 18,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 7.8912,
    "client_balance": 1100,
    "client_pl": 60.7756,
    "client_margine_level": 82,
    "client_free_margin": 70,
    "client_used_margin": 40
  },
  {
    "id": 6,
    "client_name": "Frank Miller",
    "client_country": "DE",
    "client_status": {
      "message": "info",
      "code": 2
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "ETH",
      "currency_symbol": "USD"
    },
    "status": 1,
    "market": "crypto",
    "labels": [
      {
        "name": "Marketing",
        "color": "#3366FF"
      }
    ],
    "amount": 160,
    "swap": 28,
    "unit": 20,
    "set_rate": 18,
    "opened_amount": 80023.42,
    "market_price": 2852.10,
    "volume": 214.50,
    "leverage": 28,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -15.3189,
    "client_balance": 1600,
    "client_pl": -47.1998,
    "client_margine_level": 72,
    "client_free_margin": 60,
    "client_used_margin": 54
  },
  {
    "id": 7,
    "client_name": "Grace Turner",
    "client_country": "ES",
    "client_status": {
      "message": "success",
      "code": 3
    },
    "position_type": 1,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "XRP",
      "currency_symbol": "USD"
    },
    "status": 2,
    "market": "cfd",
    "labels": [
      {
        "name": "Support",
        "color": "#10B981"
      }
    ],
    "amount": 90,
    "swap": 14,
    "unit": 9,
    "set_rate": 8,
    "opened_amount": 37520.55,
    "market_price": 1.45,
    "volume": 1035.00,
    "leverage": 14,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 10.1423,
    "client_balance": 900,
    "client_pl": 89.3557,
    "client_margine_level": 88,
    "client_free_margin": 78,
    "client_used_margin": 47
  },
  {
    "id": 8,
    "client_name": "Harry Turner",
    "client_country": "CA",
    "client_status": {
      "message": "error",
      "code": 4
    },
    "position_type": 1,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "LTC",
      "currency_symbol": "USD"
    },
    "status": 2,
    "market": "crypto",
    "labels": [
      {
        "name": "Development",
        "color": "#06AED4"
      }
    ],
    "amount": 75,
    "swap": 10,
    "unit": 7,
    "set_rate": 6,
    "opened_amount": 28045.90,
    "market_price": 188.25,
    "volume": 525.00,
    "leverage": 10,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -4.7201,
    "client_balance": 750,
    "client_pl": -14.9387,
    "client_margine_level": 83,
    "client_free_margin": 73,
    "client_used_margin": 39
  },
  {
    "id": 9,
    "client_name": "Ivy Martin",
    "client_country": "IT",
    "client_status": {
      "message": "warning",
      "code": 1
    },
    "position_type": 0,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "BTC",
      "currency_symbol": "USD"
    },
    "status": 0,
    "market": "fx",
    "labels": [
      {
        "name": "Sales",
        "color": "#637381"
      }
    ],
    "amount": 130,
    "swap": 22,
    "unit": 14,
    "set_rate": 11,
    "opened_amount": 58036.15,
    "market_price": 46239.60,
    "volume": 1189.75,
    "leverage": 22,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 6.9372,
    "client_balance": 1300,
    "client_pl": 67.8215,
    "client_margine_level": 78,
    "client_free_margin": 63,
    "client_used_margin": 41
  },
  {
    "id": 10,
    "client_name": "Jackie Turner",
    "client_country": "GB",
    "client_status": {
      "message": "info",
      "code": 2
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "ETH",
      "currency_symbol": "USD"
    },
    "status": 1,
    "market": "crypto",
    "labels": [
      {
        "name": "Marketing",
        "color": "#10B981"
      }
    ],
    "amount": 170,
    "swap": 30,
    "unit": 22,
    "set_rate": 20,
    "opened_amount": 88015.73,
    "market_price": 2975.45,
    "volume": 189.25,
    "leverage": 30,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -20.4156,
    "client_balance": 1700,
    "client_pl": -59.3184,
    "client_margine_level": 70,
    "client_free_margin": 58,
    "client_used_margin": 56
  },

  {
    "id": 11,
    "client_name": "Charlie Brown",
    "client_country": "CA",
    "client_status": {
      "message": "error",
      "code": 3
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "EUR",
      "currency_symbol": "USD"
    },
    "status": 1,
    "market": "commodities",
    "labels": [
      {
        "name": "Support",
        "color": "#3366FF"
      }
    ],
    "amount": 100,
    "swap": 15,
    "unit": 12,
    "set_rate": 10,
    "opened_amount": 34876.40,
    "market_price": 1.1205,
    "volume": 983.40,
    "leverage": 15,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -3.2148,
    "client_balance": 1100,
    "client_pl": -15.7692,
    "client_margine_level": 85,
    "client_free_margin": 62,
    "client_used_margin": 48
  },
  {
    "id": 12,
    "client_name": "David Wilson",
    "client_country": "AU",
    "client_status": {
      "message": "success",
      "code": 4
    },
    "position_type": 1,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "XRP",
      "currency_symbol": "USD"
    },
    "status": 0,
    "market": "crypto",
    "labels": [
      {
        "name": "Development",
        "color": "#6EB044"
      }
    ],
    "amount": 200,
    "swap": 30,
    "unit": 20,
    "set_rate": 18,
    "opened_amount": 92035.80,
    "market_price": 0.7552,
    "volume": 1500.20,
    "leverage": 30,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 12.8356,
    "client_balance": 2000,
    "client_pl": 92.1043,
    "client_margine_level": 75,
    "client_free_margin": 80,
    "client_used_margin": 55
  },
  {
    "id": 13,
    "client_name": "Eva Miller",
    "client_country": "DE",
    "client_status": {
      "message": "info",
      "code": 2
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "GBP",
      "currency_symbol": "USD"
    },
    "status": 1,
    "market": "cfd",
    "labels": [
      {
        "name": "Marketing",
        "color": "#3366FF"
      }
    ],
    "amount": 150,
    "swap": 25,
    "unit": 18,
    "set_rate": 15,
    "opened_amount": 72093.18,
    "market_price": 1.3597,
    "volume": 980.50,
    "leverage": 25,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -8.1123,
    "client_balance": 1500,
    "client_pl": -32.1987,
    "client_margine_level": 80,
    "client_free_margin": 68,
    "client_used_margin": 52
  },
  {
    "id": 14,
    "client_name": "Frank Davis",
    "client_country": "FR",
    "client_status": {
      "message": "success",
      "code": 4
    },
    "position_type": 1,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "LTC",
      "currency_symbol": "USD"
    },
    "status": 0,
    "market": "crypto",
    "labels": [
      {
        "name": "Sales",
        "color": "#06AED4"
      }
    ],
    "amount": 100,
    "swap": 15,
    "unit": 12,
    "set_rate": 10,
    "opened_amount": 34876.40,
    "market_price": 142.80,
    "volume": 980.40,
    "leverage": 15,
    "tp": 0.00,
    "sl": 0.00,
    "profit": 7.3124,
    "client_balance": 1100,
    "client_pl": 35.2198,
    "client_margine_level": 85,
    "client_free_margin": 62,
    "client_used_margin": 48
  },
  {
    "id": 15,
    "client_name": "Grace Turner",
    "client_country": "ES",
    "client_status": {
      "message": "warning",
      "code": 1
    },
    "position_type": 2,
    "created_at": new Date(),
    "ticker": {
      "base_currency_symbol": "JPY",
      "currency_symbol": "USD"
    },
    "status": 1,
    "market": "cfd",
    "labels": [
      {
        "name": "Support",
        "color": "#637381"
      }
    ],
    "amount": 120,
    "swap": 18,
    "unit": 14,
    "set_rate": 11,
    "opened_amount": 48293.60,
    "market_price": 111.35,
    "volume": 815.70,
    "leverage": 18,
    "tp": 0.00,
    "sl": 0.00,
    "profit": -5.9267,
    "client_balance": 1200,
    "client_pl": -22.0345,
    "client_margine_level": 75,
    "client_free_margin": 70,
    "client_used_margin": 50
  }
];

export const transactionMockedList = [
  {
    id: 1,
    full_name: "Robert White",
    client_country: "GB",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Jane Smith",
    currency: 1,
    provider: "Card Payment",
    amount: 200,
    labels: [
      {
        name: "Sales",
        color: "#06AED4"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 2,
    full_name: "Emily Brown",
    client_country: "US",
    status: 2,
    transaction_type: "Deposit",
    transaction_owners: "John Doe",
    currency: 2,
    provider: "Bank Transfer",
    amount: 300,
    labels: [
      {
        name: "Marketing",
        color: "#00A76F"
      }
    ],
    action_type: 2,
    credit: false,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 3,
    full_name: "Michael Johnson",
    client_country: "CA",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Alice White",
    currency: 1,
    provider: "PayPal",
    amount: 150,
    labels: [
      {
        name: "Development",
        color: "#3366FF"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 4,
    full_name: "Sophia Davis",
    client_country: "FR",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "David Wilson",
    currency: 2,
    provider: "Bank Transfer",
    amount: 250,
    labels: [
      {
        name: "Support",
        color: "#637381"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 5,
    full_name: "Oliver Smith",
    client_country: "AU",
    status: 2,
    transaction_type: "Deposit",
    transaction_owners: "Emma Turner",
    currency: 1,
    provider: "Card Payment",
    amount: 180,
    labels: [
      {
        name: "Sales",
        color: "#36CAAA"
      }
    ],
    action_type: 2,
    credit: false,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 6,
    full_name: "Ava Wilson",
    client_country: "DE",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Frank Davis",
    currency: 1,
    provider: "PayPal",
    amount: 120,
    labels: [
      {
        name: "Marketing",
        color: "#10B981"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 7,
    full_name: "William Johnson",
    client_country: "ES",
    status: 3,
    transaction_type: "Deposit",
    transaction_owners: "Grace Turner",
    currency: 2,
    provider: "Bank Transfer",
    amount: 300,
    labels: [
      {
        name: "Development",
        color: "#3366FF"
      }
    ],
    action_type: 2,
    credit: false,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 8,
    full_name: "Isabella Brown",
    client_country: "GB",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Henry Wilson",
    currency: 1,
    provider: "Card Payment",
    amount: 220,
    labels: [
      {
        name: "Support",
        color: "#06AED4"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 9,
    full_name: "James Smith",
    client_country: "US",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Ivy Miller",
    currency: 2,
    provider: "PayPal",
    amount: 180,
    labels: [
      {
        name: "Sales",
        color: "#637381"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 10,
    full_name: "Elizabeth Wilson",
    client_country: "CA",
    status: 2,
    transaction_type: "Deposit",
    transaction_owners: "Jack Davis",
    currency: 1,
    provider: "Bank Transfer",
    amount: 250,
    labels: [
      {
        name: "Marketing",
        color: "#3366FF"
      }
    ],
    action_type: 2,
    credit: false,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 11,
    full_name: "Olivia Turner",
    client_country: "FR",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Kevin Johnson",
    currency: 1,
    provider: "Card Payment",
    amount: 200,
    labels: [
      {
        name: "Development",
        color: "#637381"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 12,
    full_name: "Liam Brown",
    client_country: "AU",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Lily White",
    currency: 2,
    provider: "PayPal",
    amount: 150,
    labels: [
      {
        name: "Support",
        color: "#10B981"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 13,
    full_name: "Emma Davis",
    client_country: "DE",
    status: 4,
    transaction_type: "Deposit",
    transaction_owners: "Mason Smith",
    currency: 1,
    provider: "Bank Transfer",
    amount: 300,
    labels: [
      {
        name: "Sales",
        color: "#3366FF"
      }
    ],
    action_type: 2,
    credit: false,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 14,
    full_name: "Noah Wilson",
    client_country: "ES",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Olivia Turner",
    currency: 1,
    provider: "Card Payment",
    amount: 180,
    labels: [
      {
        name: "Marketing",
        color: "#10B981"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  },
  {
    id: 15,
    full_name: "Ava Smith",
    client_country: "GB",
    status: 1,
    transaction_type: "Withdraw",
    transaction_owners: "Peter Brown",
    currency: 2,
    provider: "PayPal",
    amount: 220,
    labels: [
      {
        name: "Development",
        color: "#637381"
      }
    ],
    action_type: 1,
    credit: true,
    approved_at: new Date(),
    created_at: new Date(),
  }
];

export const merchantMockedList = [
  {
    id: 1,
    name: 'Super Mart',
    active: true,
    created_at: new Date(),
  },
  {
    id: 2,
    name: 'Tech Emporium',
    active: true,
    created_at: new Date(),
  },
  {
    id: 3,
    name: 'Fashion Haven',
    active: false,
    created_at: new Date(),
  },
  {
    id: 4,
    name: 'Organic Delights',
    active: true,
    created_at: new Date(),
  },
  {
    id: 5,
    name: 'Gourmet Grocers',
    active: true,
    created_at: new Date(),
  },
  {
    id: 6,
    name: 'Book Nook',
    active: false,
    created_at: new Date(),
  },
  {
    id: 7,
    name: 'Home Essentials Co.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 8,
    name: 'Pet Paradise',
    active: true,
    created_at: new Date(),
  },
  {
    id: 9,
    name: 'Sports Zone',
    active: true,
    created_at: new Date(),
  },
  {
    id: 10,
    name: 'Beauty Boutique',
    active: false,
    created_at: new Date(),
  },
  {
    id: 11,
    name: 'ElectroHub',
    active: true,
    created_at: new Date(),
  },
  {
    id: 12,
    name: 'Crafty Creations',
    active: true,
    created_at: new Date(),
  },
  {
    id: 13,
    name: 'Health Hub',
    active: true,
    created_at: new Date(),
  },
  {
    id: 14,
    name: 'Auto Masters',
    active: false,
    created_at: new Date(),
  },
  {
    id: 15,
    name: 'Kid Corner',
    active: true,
    created_at: new Date(),
  },
];

export const bankMockedList = [
  {
    id: 1,
    name: 'Banking Elite',
    active: true,
    created_at: new Date(),
  },
  {
    id: 2,
    name: 'Digital Financiers',
    active: true,
    created_at: new Date(),
  },
  {
    id: 3,
    name: 'Secure Finance Solutions',
    active: false,
    created_at: new Date(),
  },
  {
    id: 4,
    name: 'Green Capital Bank',
    active: true,
    created_at: new Date(),
  },
  {
    id: 5,
    name: 'Gourmet Investments Bank',
    active: true,
    created_at: new Date(),
  },
  {
    id: 6,
    name: 'Bookkeeper Trust',
    active: false,
    created_at: new Date(),
  },
  {
    id: 7,
    name: 'Home Savings & Loans',
    active: true,
    created_at: new Date(),
  },
  {
    id: 8,
    name: 'Pet Funds Alliance',
    active: false,
    created_at: new Date(),
  },
  {
    id: 9,
    name: 'Sports Capital Bank',
    active: true,
    created_at: new Date(),
  },
  {
    id: 10,
    name: 'Beauty Finance Hub',
    active: true,
    created_at: new Date(),
  },
  {
    id: 11,
    name: 'ElectroBank',
    active: true,
    created_at: new Date(),
  },
  {
    id: 12,
    name: 'Crafty Capital',
    active: false,
    created_at: new Date(),
  },
  {
    id: 13,
    name: 'Health Wealth Bank',
    active: true,
    created_at: new Date(),
  },
  {
    id: 14,
    name: 'Auto Finance Masters',
    active: false,
    created_at: new Date(),
  },
  {
    id: 15,
    name: 'Kid Savings Corner',
    active: true,
    created_at: new Date(),
  },
];

export const paymentTypeMockedList = [
  {
    id: 1,
    name: 'Credit Card',
    active: true,
    created_at: new Date(),
  },
  {
    id: 2,
    name: 'Digital Wallet',
    active: true,
    created_at: new Date(),
  },
  {
    id: 3,
    name: 'Secure Payment Solutions',
    active: false,
    created_at: new Date(),
  },
  {
    id: 4,
    name: 'Green Capital Currency',
    active: true,
    created_at: new Date(),
  },
  {
    id: 5,
    name: 'Gourmet Investments Payment',
    active: true,
    created_at: new Date(),
  },
  {
    id: 6,
    name: 'Bookkeeper Trust',
    active: false,
    created_at: new Date(),
  },
  {
    id: 7,
    name: 'Home Payment & Loans',
    active: true,
    created_at: new Date(),
  },
  {
    id: 8,
    name: 'Pet Funds Transfer',
    active: false,
    created_at: new Date(),
  },
  {
    id: 9,
    name: 'Sports Capital Payment',
    active: true,
    created_at: new Date(),
  },
  {
    id: 10,
    name: 'Beauty Finance Hub',
    active: true,
    created_at: new Date(),
  },
  {
    id: 11,
    name: 'Electronic Transfer',
    active: true,
    created_at: new Date(),
  },
  {
    id: 12,
    name: 'Crafty Capital Cash',
    active: false,
    created_at: new Date(),
  },
  {
    id: 13,
    name: 'Health Wealth Payment',
    active: true,
    created_at: new Date(),
  },
  {
    id: 14,
    name: 'Auto Finance Payment',
    active: false,
    created_at: new Date(),
  },
  {
    id: 15,
    name: 'Kid Savings Corner',
    active: true,
    created_at: new Date(),
  },
];

export const validationTaskMockedList = [
  {
    id: 1,
    name: 'Credit Card Validation',
    description: 'Verify and validate credit card transactions for accuracy and security.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 2,
    name: 'Digital Wallet Verification',
    description: 'Confirm the integrity of digital wallet transactions and user data.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 3,
    name: 'Secure Payment Solutions Check',
    description: 'Evaluate the effectiveness of secure payment solutions for potential improvements.',
    active: false,
    created_at: new Date(),
  },
  {
    id: 4,
    name: 'Green Capital Currency Validation',
    description: 'Ensure the validity of transactions involving the Green Capital Currency.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 5,
    name: 'Gourmet Investments Payment Review',
    description: 'Review and validate payments related to gourmet investments and financial activities.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 6,
    name: 'Bookkeeper Trust Validation',
    description: 'Validate the trustworthiness of transactions managed by the Bookkeeper Trust.',
    active: false,
    created_at: new Date(),
  },
  {
    id: 7,
    name: 'Home Payment & Loans Verification',
    description: 'Verify and validate payments and loans associated with home financing.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 8,
    name: 'Pet Funds Transfer Validation',
    description: 'Validate fund transfers related to pet care and services.',
    active: false,
    created_at: new Date(),
  },
  {
    id: 9,
    name: 'Sports Capital Payment Check',
    description: 'Conduct checks on payments related to sports capital and financial transactions.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 10,
    name: 'Beauty Finance Hub Validation',
    description: 'Validate financial transactions and activities related to the Beauty Finance Hub.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 11,
    name: 'Electronic Transfer Verification',
    description: 'Verify the authenticity of electronic transfers and financial transactions.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 12,
    name: 'Crafty Capital Cash Validation',
    description: 'Validate cash transactions managed by Crafty Capital for accuracy and security.',
    active: false,
    created_at: new Date(),
  },
  {
    id: 13,
    name: 'Health Wealth Payment Review',
    description: 'Review and validate payments related to health and wealth management.',
    active: true,
    created_at: new Date(),
  },
  {
    id: 14,
    name: 'Auto Finance Payment Validation',
    description: 'Validate payments and financial transactions related to auto financing.',
    active: false,
    created_at: new Date(),
  },
  {
    id: 15,
    name: 'Kid Savings Corner Verification',
    description: 'Verify and validate financial transactions related to Kid Savings Corner.',
    active: true,
    created_at: new Date(),
  },
];

export const dataEntryMockedList = [
  {
    id: 1,
    name: 'Customer Information Update',
    status: true,
    invalid_records: 8,
    updated_count: 4,
    total_records: 20,
    updated_at: new Date(),
  },
  {
    id: 2,
    name: 'Product Price Adjustment',
    status: true,
    invalid_records: 2,
    updated_count: 10,
    total_records: 30,
    updated_at: new Date(),
  },
  {
    id: 3,
    name: 'Inventory Quantity Update',
    status: false,
    invalid_records: 5,
    updated_count: 15,
    total_records: 25,
    updated_at: new Date(),
  },
  {
    id: 4,
    name: 'Employee Contact Details Review',
    status: true,
    invalid_records: 0,
    updated_count: 8,
    total_records: 22,
    updated_at: new Date(),
  },
  {
    id: 5,
    name: 'Supplier Information Verification',
    status: true,
    invalid_records: 3,
    updated_count: 12,
    total_records: 18,
    updated_at: new Date(),
  },
  {
    id: 6,
    name: 'Order Shipment Tracking Update',
    status: false,
    invalid_records: 6,
    updated_count: 6,
    total_records: 24,
    updated_at: new Date(),
  },
  {
    id: 7,
    name: 'Billing Address Validation',
    status: true,
    invalid_records: 1,
    updated_count: 9,
    total_records: 21,
    updated_at: new Date(),
  },
  {
    id: 8,
    name: 'Customer Feedback Processing',
    status: false,
    invalid_records: 4,
    updated_count: 14,
    total_records: 26,
    updated_at: new Date(),
  },
  {
    id: 9,
    name: 'Product Category Update',
    status: true,
    invalid_records: 7,
    updated_count: 5,
    total_records: 19,
    updated_at: new Date(),
  },
  {
    id: 10,
    name: 'Payment Transaction Reconciliation',
    status: true,
    invalid_records: 9,
    updated_count: 3,
    total_records: 17,
    updated_at: new Date(),
  },
  {
    id: 11,
    name: 'Promotional Offer Validation',
    status: true,
    invalid_records: 2,
    updated_count: 11,
    total_records: 23,
    updated_at: new Date(),
  },
  {
    id: 12,
    name: 'Employee Attendance Record Update',
    status: false,
    invalid_records: 5,
    updated_count: 7,
    total_records: 27,
    updated_at: new Date(),
  },
  {
    id: 13,
    name: 'Customer Account Balance Adjustment',
    status: true,
    invalid_records: 0,
    updated_count: 10,
    total_records: 30,
    updated_at: new Date(),
  },
  {
    id: 14,
    name: 'Inventory Restocking Confirmation',
    status: false,
    invalid_records: 3,
    updated_count: 13,
    total_records: 22,
    updated_at: new Date(),
  },
  {
    id: 15,
    name: 'Product Review Ratings Update',
    status: true,
    invalid_records: 6,
    updated_count: 8,
    total_records: 18,
    updated_at: new Date(),
  },
];

export const recordMockedList = [
  {
    id: 1,
    source: {
      ai: true,
      code: false,
    },
    status: true,
    d_id: 1250,
    d_reference_id: 2048,
    labels: [
      {
        name: "Sales",
        color: '#637381'
      }
    ],
    d_account: "Robert White",
    d_client_name: "Cortez Herring",
    d_client_type: "Individual",
    d_account_provider_number: 1254,
    d_type: "Purchase",
    d_amount: 50,
    d_currency: "USD",
    d_remitter_name: "William Taylor",
    d_remitter_account: "Cortez Herring",
    d_beneficiary_name: "Deja Brady",
    d_beneficiary_type: "Individual",
    d_bic: "BIC123",
    d_description: "Some description",
    d_charge_type: "Standard",
    d_provider: "Giana Brandt",
    d_provider_id: 2541,
    d_partner_id: 2426,
    payment_type: "Paypal",
    fee_actual: 50,
    fee_expected: 50,
    cost_actual: 60,
    cost_expected: 60,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 2,
    source: {
      ai: false,
      code: true,
    },
    status: false,
    d_id: 1251,
    d_reference_id: 2049,
    labels: [
      {
        name: "Marketing",
        color: '#9c27b0'
      }
    ],
    d_account: "Emily Johnson",
    d_client_name: "John Doe",
    d_client_type: "Business",
    d_account_provider_number: 1255,
    d_type: "Sale",
    d_amount: 75,
    d_currency: "EUR",
    d_remitter_name: "Emma Davis",
    d_remitter_account: "John Doe",
    d_beneficiary_name: "Chris Brown",
    d_beneficiary_type: "Business",
    d_bic: "BIC456",
    d_description: "Another description",
    d_charge_type: "Express",
    d_provider: "Alex Smith",
    d_provider_id: 2542,
    d_partner_id: 2427,
    payment_type: "Credit Card",
    fee_actual: 65,
    fee_expected: 65,
    cost_actual: 80,
    cost_expected: 80,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 3,
    source: {
      ai: true,
      code: true,
    },
    status: true,
    d_id: 1252,
    d_reference_id: 2050,
    labels: [
      {
        name: "Support",
        color: '#2196f3'
      }
    ],
    d_account: "Michael Johnson",
    d_client_name: "Sara Smith",
    d_client_type: "Individual",
    d_account_provider_number: 1256,
    d_type: "Refund",
    d_amount: 30,
    d_currency: "GBP",
    d_remitter_name: "David Wilson",
    d_remitter_account: "Sara Smith",
    d_beneficiary_name: "Alice Green",
    d_beneficiary_type: "Individual",
    d_bic: "BIC789",
    d_description: "Refund for canceled service",
    d_charge_type: "Standard",
    d_provider: "Laura Miller",
    d_provider_id: 2543,
    d_partner_id: 2428,
    payment_type: "Bank Transfer",
    fee_actual: 40,
    fee_expected: 40,
    cost_actual: 50,
    cost_expected: 50,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 4,
    source: {
      ai: false,
      code: false,
    },
    status: true,
    d_id: 1253,
    d_reference_id: 2051,
    labels: [
      {
        name: "Sales",
        color: '#637381'
      },
      {
        name: "Promotion",
        color: '#4caf50'
      }
    ],
    d_account: "Sophia Davis",
    d_client_name: "Daniel Brown",
    d_client_type: "Business",
    d_account_provider_number: 1257,
    d_type: "Sale",
    d_amount: 90,
    d_currency: "USD",
    d_remitter_name: "Olivia Harris",
    d_remitter_account: "Daniel Brown",
    d_beneficiary_name: "Ethan White",
    d_beneficiary_type: "Business",
    d_bic: "BIC987",
    d_description: "Special promotion sale",
    d_charge_type: "Express",
    d_provider: "Mia Wilson",
    d_provider_id: 2544,
    d_partner_id: 2429,
    payment_type: "Credit Card",
    fee_actual: 70,
    fee_expected: 70,
    cost_actual: 80,
    cost_expected: 80,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 5,
    source: {
      ai: true,
      code: true,
    },
    status: false,
    d_id: 1254,
    d_reference_id: 2052,
    labels: [
      {
        name: "Support",
        color: '#2196f3'
      }
    ],
    d_account: "Ella Robinson",
    d_client_name: "Jason Miller",
    d_client_type: "Individual",
    d_account_provider_number: 1258,
    d_type: "Refund",
    d_amount: 40,
    d_currency: "EUR",
    d_remitter_name: "Aiden Thomas",
    d_remitter_account: "Jason Miller",
    d_beneficiary_name: "Lily Johnson",
    d_beneficiary_type: "Individual",
    d_bic: "BIC234",
    d_description: "Refund for defective product",
    d_charge_type: "Standard",
    d_provider: "Sophie Hall",
    d_provider_id: 2545,
    d_partner_id: 2430,
    payment_type: "Bank Transfer",
    fee_actual: 35,
    fee_expected: 35,
    cost_actual: 45,
    cost_expected: 45,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 6,
    source: {
      ai: false,
      code: false,
    },
    status: true,
    d_id: 1255,
    d_reference_id: 2053,
    labels: [
      {
        name: "Marketing",
        color: '#9c27b0'
      },
      {
        name: "Promotion",
        color: '#4caf50'
      }
    ],
    d_account: "Logan Harris",
    d_client_name: "Sophia Davis",
    d_client_type: "Business",
    d_account_provider_number: 1259,
    d_type: "Sale",
    d_amount: 60,
    d_currency: "GBP",
    d_remitter_name: "Mia Wilson",
    d_remitter_account: "Sophia Davis",
    d_beneficiary_name: "Lucas Wilson",
    d_beneficiary_type: "Business",
    d_bic: "BIC567",
    d_description: "Flash sale promotion",
    d_charge_type: "Express",
    d_provider: "Ava Moore",
    d_provider_id: 2546,
    d_partner_id: 2431,
    payment_type: "Credit Card",
    fee_actual: 55,
    fee_expected: 55,
    cost_actual: 70,
    cost_expected: 70,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 7,
    source: {
      ai: true,
      code: false,
    },
    status: false,
    d_id: 1256,
    d_reference_id: 2054,
    labels: [
      {
        name: "Support",
        color: '#2196f3'
      }
    ],
    d_account: "Lily Johnson",
    d_client_name: "Ella Robinson",
    d_client_type: "Individual",
    d_account_provider_number: 1260,
    d_type: "Refund",
    d_amount: 45,
    d_currency: "USD",
    d_remitter_name: "Sophie Hall",
    d_remitter_account: "Ella Robinson",
    d_beneficiary_name: "Aiden Thomas",
    d_beneficiary_type: "Individual",
    d_bic: "BIC876",
    d_description: "Refund for canceled service",
    d_charge_type: "Standard",
    d_provider: "Jason Miller",
    d_provider_id: 2547,
    d_partner_id: 2432,
    payment_type: "Bank Transfer",
    fee_actual: 40,
    fee_expected: 40,
    cost_actual: 50,
    cost_expected: 50,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    id: 8,
    source: {
      ai: false,
      code: true,
    },
    status: true,
    d_id: 1257,
    d_reference_id: 2055,
    labels: [
      {
        name: "Sales",
        color: '#637381'
      },
      {
        name: "Marketing",
        color: '#9c27b0'
      }
    ],
    d_account: "Olivia Harris",
    d_client_name: "David Wilson",
    d_client_type: "Business",
    d_account_provider_number: 1261,
    d_type: "Sale",
    d_amount: 80,
    d_currency: "EUR",
    d_remitter_name: "Ethan White",
    d_remitter_account: "David Wilson",
    d_beneficiary_name: "Sophia Davis",
    d_beneficiary_type: "Business",
    d_bic: "BIC345",
    d_description: "Special promotion sale",
    d_charge_type: "Express",
    d_provider: "Daniel Brown",
    d_provider_id: 2548,
    d_partner_id: 2433,
    payment_type: "Credit Card",
    fee_actual: 75,
    fee_expected: 75,
    cost_actual: 90,
    cost_expected: 90,
    upload_time: new Date(),
    d_created: new Date(),
    d_updated: new Date(),
  },
  {
    "id": 9,
    "source": {
      "ai": true,
      "code": false
    },
    "status": true,
    "d_id": 1259,
    "d_reference_id": 2057,
    "labels": [
      {
        "name": "Marketing",
        "color": "#e91e63"
      }
    ],
    "d_account": "Emma Davis",
    "d_client_name": "Oliver White",
    "d_client_type": "Individual",
    "d_account_provider_number": 1263,
    "d_type": "Sale",
    "d_amount": 80,
    "d_currency": "USD",
    "d_remitter_name": "Sophia Johnson",
    "d_remitter_account": "Oliver White",
    "d_beneficiary_name": "Ethan Turner",
    "d_beneficiary_type": "Individual",
    "d_bic": "BIC567",
    "d_description": "Retail purchase",
    "d_charge_type": "Standard",
    "d_provider": "Harper Martinez",
    "d_provider_id": 2550,
    "d_partner_id": 2435,
    "payment_type": "Debit Card",
    "fee_actual": 40,
    "fee_expected": 40,
    "cost_actual": 60,
    "cost_expected": 60,
    "upload_time": "2024-01-16T12:00:00Z",
    "d_created": "2024-01-16T12:05:00Z",
    "d_updated": "2024-01-16T12:10:00Z"
  },
  {
    "id": 11,
    "source": {
      "ai": false,
      "code": true
    },
    "status": false,
    "d_id": 1260,
    "d_reference_id": 2058,
    "labels": [
      {
        "name": "Support",
        "color": "#2196f3"
      }
    ],
    "d_account": "Olivia Smith",
    "d_client_name": "Mia Johnson",
    "d_client_type": "Business",
    "d_account_provider_number": 1264,
    "d_type": "Refund",
    "d_amount": 120,
    "d_currency": "GBP",
    "d_remitter_name": "Noah Wilson",
    "d_remitter_account": "Mia Johnson",
    "d_beneficiary_name": "Isabella Davis",
    "d_beneficiary_type": "Business",
    "d_bic": "BIC789",
    "d_description": "Product return",
    "d_charge_type": "Priority",
    "d_provider": "Aria Brown",
    "d_provider_id": 2551,
    "d_partner_id": 2436,
    "payment_type": "Bank Transfer",
    "fee_actual": 30,
    "fee_expected": 30,
    "cost_actual": 90,
    "cost_expected": 90,
    "upload_time": "2024-01-16T12:15:00Z",
    "d_created": "2024-01-16T12:20:00Z",
    "d_updated": "2024-01-16T12:25:00Z"
  },
  {
    "id": 11,
    "source": {
      "ai": true,
      "code": true
    },
    "status": true,
    "d_id": 1261,
    "d_reference_id": 2059,
    "labels": [
      {
        "name": "Development",
        "color": "#4caf50"
      }
    ],
    "d_account": "Ethan Turner",
    "d_client_name": "Aiden Harris",
    "d_client_type": "Individual",
    "d_account_provider_number": 1265,
    "d_type": "Purchase",
    "d_amount": 45,
    "d_currency": "USD",
    "d_remitter_name": "Zoe Adams",
    "d_remitter_account": "Aiden Harris",
    "d_beneficiary_name": "Liam Miller",
    "d_beneficiary_type": "Individual",
    "d_bic": "BIC123",
    "d_description": "Tech gadgets",
    "d_charge_type": "Express",
    "d_provider": "Noah Wilson",
    "d_provider_id": 2552,
    "d_partner_id": 2437,
    "payment_type": "Credit Card",
    "fee_actual": 20,
    "fee_expected": 20,
    "cost_actual": 60,
    "cost_expected": 60,
    "upload_time": "2024-01-16T12:30:00Z",
    "d_created": "2024-01-16T12:35:00Z",
    "d_updated": "2024-01-16T12:40:00Z"
  },
  {
    "id": 12,
    "source": {
      "ai": false,
      "code": false
    },
    "status": true,
    "d_id": 1262,
    "d_reference_id": 2060,
    "labels": [
      {
        "name": "Finance",
        "color": "#673ab7"
      }
    ],
    "d_account": "Mia Johnson",
    "d_client_name": "Ethan Turner",
    "d_client_type": "Individual",
    "d_account_provider_number": 1266,
    "d_type": "Sale",
    "d_amount": 90,
    "d_currency": "EUR",
    "d_remitter_name": "Sophia Johnson",
    "d_remitter_account": "Ethan Turner",
    "d_beneficiary_name": "Olivia Smith",
    "d_beneficiary_type": "Individual",
    "d_bic": "BIC456",
    "d_description": "Fashion items",
    "d_charge_type": "Standard",
    "d_provider": "Aiden Harris",
    "d_provider_id": 2553,
    "d_partner_id": 2438,
    "payment_type": "Debit Card",
    "fee_actual": 25,
    "fee_expected": 25,
    "cost_actual": 70,
    "cost_expected": 70,
    "upload_time": "2024-01-16T12:45:00Z",
    "d_created": "2024-01-16T12:50:00Z",
    "d_updated": "2024-01-16T12:55:00Z"
  },
  {
    "id": 13,
    "source": {
      "ai": true,
      "code": false
    },
    "status": false,
    "d_id": 1263,
    "d_reference_id": 2061,
    "labels": [
      {
        "name": "Operations",
        "color": "#607d8b"
      }
    ],
    "d_account": "Oliver White",
    "d_client_name": "Liam Miller",
    "d_client_type": "Individual",
    "d_account_provider_number": 1267,
    "d_type": "Refund",
    "d_amount": 75,
    "d_currency": "USD",
    "d_remitter_name": "Noah Wilson",
    "d_remitter_account": "Liam Miller",
    "d_beneficiary_name": "Emma Davis",
    "d_beneficiary_type": "Individual",
    "d_bic": "BIC789",
    "d_description": "Faulty product return",
    "d_charge_type": "Priority",
    "d_provider": "Harper Martinez",
    "d_provider_id": 2554,
    "d_partner_id": 2439,
    "payment_type": "Bank Transfer",
    "fee_actual": 35,
    "fee_expected": 35,
    "cost_actual": 85,
    "cost_expected": 85,
    "upload_time": "2024-01-16T13:00:00Z",
    "d_created": "2024-01-16T13:05:00Z",
    "d_updated": "2024-01-16T13:10:00Z"
  },
  {
    "id": 14,
    "source": {
      "ai": false,
      "code": true
    },
    "status": true,
    "d_id": 1264,
    "d_reference_id": 2062,
    "labels": [
      {
        "name": "Sales",
        "color": "#ff9800"
      }
    ],
    "d_account": "Aiden Harris",
    "d_client_name": "Isabella Davis",
    "d_client_type": "Individual",
    "d_account_provider_number": 1268,
    "d_type": "Purchase",
    "d_amount": 55,
    "d_currency": "GBP",
    "d_remitter_name": "Zoe Adams",
    "d_remitter_account": "Isabella Davis",
    "d_beneficiary_name": "Noah Wilson",
    "d_beneficiary_type": "Individual",
    "d_bic": "BIC567",
    "d_description": "Electronics",
    "d_charge_type": "Express",
    "d_provider": "Liam Miller",
    "d_provider_id": 2555,
    "d_partner_id": 2440,
    "payment_type": "Credit Card",
    "fee_actual": 45,
    "fee_expected": 45,
    "cost_actual": 75,
    "cost_expected": 75,
    "upload_time": "2024-01-16T13:15:00Z",
    "d_created": "2024-01-16T13:20:00Z",
    "d_updated": "2024-01-16T13:25:00Z"
  },
  {
    "id": 15,
    "source": {
      "ai": true,
      "code": true
    },
    "status": false,
    "d_id": 1265,
    "d_reference_id": 2063,
    "labels": [
      {
        "name": "Development",
        "color": "#4caf50"
      }
    ],
    "d_account": "Emma Davis",
    "d_client_name": "Zoe Adams",
    "d_client_type": "Individual",
    "d_account_provider_number": 1269,
    "d_type": "Sale",
    "d_amount": 70,
    "d_currency": "EUR",
    "d_remitter_name": "Sophia Johnson",
    "d_remitter_account": "Zoe Adams",
    "d_beneficiary_name": "Harper Martinez",
    "d_beneficiary_type": "Individual",
    "d_bic": "BIC123",
    "d_description": "Software license",
    "d_charge_type": "Standard",
    "d_provider": "Aria Brown",
    "d_provider_id": 2556,
    "d_partner_id": 2441,
    "payment_type": "Debit Card",
    "fee_actual": 30,
    "fee_expected": 30,
    "cost_actual": 60,
    "cost_expected": 60,
    "upload_time": "2024-01-16T13:30:00Z",
    "d_created": "2024-01-16T13:35:00Z",
    "d_updated": "2024-01-16T13:40:00Z"
  }
];

export const articleMockedList = [
  {
    id: 1,
    author: {
      avatar: '/assets/avatars/avatar-jie-yan-song.png',
      name: 'Jane Smith'
    },
    category: 'Programming',
    cover: '/assets/covers/business-2-4x4-large.png',
    publishedAt: subMinutes(subSeconds(now, 16), 45).getTime(),
    readTime: '5 min',
    shortDescription: 'coming soon.',
    title: 'For my business there are different drop-down & text fields, which we wanted to add in the CRM, so how can we add those NEW custom field in the CRM?'
  },
  {
    id: 2,
    author: {
      avatar: '/assets/avatars/avatar-omar-darboe.png',
      name: 'Omar Darobe'
    },
    category: 'Productivity',
    cover: '/assets/covers/abstract-2-4x4-large.png',
    publishedAt: subHours(subMinutes(subSeconds(now, 29), 51), 6).getTime(),
    readTime: '6 min',
    shortDescription: 'coming soon.',
    title: 'How to remove a user/employee from CRM?'
  },
  {
    id: 3,
    author: {
      avatar: '/assets/avatars/avatar-siegbert-gottfried.png',
      name: 'Siegbert Gottfried'
    },
    category: 'Entrepreneurs',
    cover: '/assets/covers/minimal-2-4x4-large.png',
    publishedAt: subHours(subMinutes(subSeconds(now, 6), 46), 16).getTime(),
    readTime: '3 min',
    shortDescription: 'coming soon.',
    title: 'In Manager role overview is accessible, but the standard manager role doesn’t allow the “Overview” menu.'
  },
  {
    id: 4,
    author: {
      avatar: '/assets/avatars/avatar-iulia-albu.png',
      name: 'Iulia Albu'
    },
    category: 'Innovation',
    cover: '/assets/covers/minimal-1-4x4-large.png',
    publishedAt: subDays(subHours(subMinutes(subSeconds(now, 52), 39), 7), 5).getTime(),
    readTime: '1 min',
    shortDescription: 'coming soon.',
    title: 'How to update a field for multiple clients?'
  }
];

export const clientLogsMockedList = [
  {
    "id": 13479,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "John Snow",
    "account_id": 6,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Old Note",
    "new_value": "New Note",
    "updated_at": "January 17, 2024 13:43:10"
  },
  {
    "id": 12903,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Benjamin Rodriguez",
    "account_id": 54,
    "company_id": 1,
    "field_name": "email",
    "old_value": "benjamin1@gmail.com",
    "new_value": "newbenjamin@gmail.com",
    "updated_at": "January 11, 2024 16:32:07"
  },
  {
    "id": 12902,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Emma Thompson",
    "account_id": 54,
    "company_id": 1,
    "field_name": "desk_id",
    "old_value": "1",
    "new_value": "13",
    "updated_at": "January 11, 2024 16:32:06"
  },
  {
    "id": 12901,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Mason Reynolds",
    "account_id": 54,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Note",
    "new_value": "New Note",
    "updated_at": "January 11, 2024 16:32:06"
  },
  {
    "id": 12900,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Mia Sullivan",
    "account_id": 54,
    "company_id": 1,
    "field_name": "agent_id",
    "old_value": "5",
    "new_value": "74",
    "updated_at": "January 11, 2024 16:32:01"
  },
  {
    "id": 12676,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Lucas Parker",
    "account_id": 1,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Old Note",
    "new_value": "New Note",
    "updated_at": "January 10, 2024 14:55:44"
  },
  {
    "id": 12675,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Isabella Chang",
    "account_id": 1,
    "company_id": 1,
    "field_name": "first_name",
    "old_value": "isabella",
    "new_value": "Isabella",
    "updated_at": "January 10, 2024 14:55:43"
  },
  {
    "id": 12674,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Noah Hernandez",
    "account_id": 1,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Old Note",
    "new_value": "New Note",
    "updated_at": "January 10, 2024 14:55:41"
  },
  {
    "id": 10881,
    "item_type": "client",
    "item_id": 73,
    "event": "update",
    "account_name": "Aiden Wilson",
    "account_id": 1,
    "company_id": 1,
    "field_name": "note",
    "old_value": "N/A",
    "new_value": "Old Note",
    "updated_at": "January 08, 2024 11:40:14"
  },
  {
    "id": 10604,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Abigail Patel",
    "account_id": 1,
    "company_id": 1,
    "field_name": "email",
    "old_value": "N/A",
    "new_value": "abigail@gmail.com",
    "updated_at": "January 04, 2024 14:54:10"
  }
];

export const transactionLogsMockedList = [
  {
    "id": 13242,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "update",
    "account_name": "Ethan Bennett",
    "account_id": 1,
    "company_id": 1,
    "field_name": "desk_id",
    "old_value": null,
    "new_value": "3",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13243,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "update",
    "account_name": "Sophia Nguyen",
    "account_id": 1,
    "company_id": 1,
    "field_name": "approved_at",
    "old_value": null,
    "new_value": "2024-01-16T10:38:45+00:00",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13242,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "update",
    "account_name": "Jackson Mitchell",
    "account_id": 1,
    "company_id": 1,
    "field_name": "approved_by",
    "old_value": null,
    "new_value": "61",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13240,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "update",
    "account_name": "Aria Taylor",
    "account_id": 1,
    "company_id": 1,
    "field_name": "converted",
    "old_value": null,
    "new_value": "100.0",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13239,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "create",
    "account_name": "Aiden Wilson",
    "account_id": 1,
    "company_id": 1,
    "field_name": "bonus",
    "old_value": "10",
    "new_value": "150",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13231,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "create",
    "account_name": "Jayden Kim",
    "account_id": 1,
    "company_id": 1,
    "field_name": "amount",
    "old_value": null,
    "new_value": "100.0",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13233,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "create",
    "account_name": "Carter Nguyen",
    "account_id": 1,
    "company_id": 1,
    "field_name": "status",
    "old_value": null,
    "new_value": "1",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13241,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "create",
    "account_name": "Wyatt Harris",
    "account_id": 1,
    "company_id": 1,
    "field_name": "deposit",
    "old_value": null,
    "new_value": "true",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13298,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "create",
    "account_name": "Landon Reed",
    "account_id": 1,
    "company_id": 1,
    "field_name": "currency",
    "old_value": null,
    "new_value": "1",
    "updated_at": "January 16, 2024 10:38:45"
  },
  {
    "id": 13258,
    "item_type": "ttransaction",
    "item_id": 1538,
    "event": "create",
    "account_name": "Brooklyn Lee",
    "account_id": 1,
    "company_id": 1,
    "field_name": "client_id",
    "old_value": null,
    "new_value": "75",
    "updated_at": "January 16, 2024 10:38:45"
  },
];

export const positionLogsMockedList = [
  {
    "id": 13219,
    "item_type": "position",
    "item_id": 52,
    "event": "update",
    "account_name": "Henry Foster",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:02+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13218,
    "item_type": "position",
    "item_id": 49,
    "event": "update",
    "account_name": "Victoria Harris",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:02+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13217,
    "item_type": "position",
    "item_id": 48,
    "event": "update",
    "account_name": "Samuel Kim",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:02+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13217,
    "item_type": "position",
    "item_id": 48,
    "event": "update",
    "account_name": "Samuel Kim",
    "account_id": null,
    "company_id": 1,
    "field_name": "l_spread_on",
    "old_value": "1",
    "new_value": "1",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13216,
    "item_type": "position",
    "item_id": 47,
    "event": "update",
    "account_name": "Riley Cooper",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:02+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13215,
    "item_type": "position",
    "item_id": 7,
    "event": "update",
    "account_name": "Penelope Foster",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:01+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13214,
    "item_type": "position",
    "item_id": 12,
    "event": "update",
    "account_name": "Brooklyn Lee",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:01+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13213,
    "item_type": "position",
    "item_id": 10,
    "event": "update",
    "account_name": "Natalie Martin",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:01+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13212,
    "item_type": "position",
    "item_id": 35,
    "event": "update",
    "account_name": "Wyatt Harris",
    "account_id": null,
    "company_id": 1,
    "field_name": "swap_at",
    "old_value": "2024-01-15T00:00:01+00:00",
    "new_value": "2024-01-16T00:00:02+00:00",
    "updated_at": "January 16, 2024 00:00:02"
  },
  {
    "id": 13212,
    "item_type": "position",
    "item_id": 35,
    "event": "update",
    "account_name": "Wyatt Harris",
    "account_id": null,
    "company_id": 1,
    "field_name": "l_spread_on",
    "old_value": "1",
    "new_value": "1",
    "updated_at": "January 16, 2024 00:00:02"
  },
];

export const emailLogsMockedList = [
  {
    "id": 13485,
    "item_type": "email",
    "item_id": 69,
    "event": "create",
    "account_name": "Wyatt Harris",
    "account_id": 6,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "January 17, 2024 15:03:26"
  },
  {
    "id": 13485,
    "item_type": "email",
    "item_id": 69,
    "event": "create",
    "account_name": "Wyatt Harris",
    "account_id": 6,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "password1234",
    "updated_at": "January 17, 2024 15:03:26"
  },
  {
    "id": 7992,
    "item_type": "email",
    "item_id": 55,
    "event": "create",
    "account_name": "Liam Patel",
    "account_id": null,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "medow5vfd572@gmail.com",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7992,
    "item_type": "email",
    "item_id": 55,
    "event": "create",
    "account_name": "Liam Patel",
    "account_id": null,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7962,
    "item_type": "email",
    "item_id": 54,
    "event": "create",
    "account_name": "Ella Chen",
    "account_id": null,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "meow55test72@gmail.com",
    "updated_at": "December 21, 2023 13:57:54"
  },
  {
    "id": 7962,
    "item_type": "email",
    "item_id": 54,
    "event": "create",
    "account_name": "Ella Chen",
    "account_id": null,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "December 21, 2023 13:57:54"
  },
  {
    "id": 7942,
    "item_type": "email",
    "item_id": 53,
    "event": "create",
    "account_name": "Aiden Wilson",
    "account_id": null,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "meow55test7@gmail.com",
    "updated_at": "December 21, 2023 13:57:27"
  },
  {
    "id": 7942,
    "item_type": "email",
    "item_id": 53,
    "event": "create",
    "account_name": "Aiden Wilson",
    "account_id": null,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "December 21, 2023 13:57:27"
  },
  {
    "id": 7925,
    "item_type": "email",
    "item_id": 52,
    "event": "create",
    "account_name": "Aria Taylor",
    "account_id": null,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "meow557@gmail.com",
    "updated_at": "December 21, 2023 13:57:14"
  },
  {
    "id": 7925,
    "item_type": "email",
    "item_id": 52,
    "event": "create",
    "account_name": "Aria Taylor",
    "account_id": null,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "December 21, 2023 13:57:14"
  },
];

export const phoneLogsMockedList = [
  {
    "id": 7994,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Aria Taylor",
    "account_id": null,
    "company_id": 1,
    "field_name": "id",
    "old_value": null,
    "new_value": "21",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7993,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Noah Hernandez",
    "account_id": null,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "+35799265111",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7994,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Jackson Mitchell",
    "account_id": null,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7994,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Jackson Mitchell",
    "account_id": null,
    "company_id": 1,
    "field_name": "created_at",
    "old_value": null,
    "new_value": "2023-12-21T14:02:54+00:00",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7994,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Jackson Mitchell",
    "account_id": null,
    "company_id": 1,
    "field_name": "updated_at",
    "old_value": null,
    "new_value": "2023-12-21T14:02:54+00:00",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7993,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Noah Hernandez",
    "account_id": null,
    "company_id": 1,
    "field_name": "id",
    "old_value": null,
    "new_value": "21",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7993,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Noah Hernandez",
    "account_id": null,
    "company_id": 1,
    "field_name": "value",
    "old_value": null,
    "new_value": "+35799265111",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7966,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Chloe Brown",
    "account_id": null,
    "company_id": 1,
    "field_name": "company_id",
    "old_value": null,
    "new_value": "1",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7923,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Abigail Patel",
    "account_id": null,
    "company_id": 1,
    "field_name": "created_at",
    "old_value": null,
    "new_value": "2023-12-21T14:02:54+00:00",
    "updated_at": "December 21, 2023 14:02:54"
  },
  {
    "id": 7903,
    "item_type": "phonenumber",
    "item_id": 21,
    "event": "create",
    "account_name": "Brayden Martinez",
    "account_id": null,
    "company_id": 1,
    "field_name": "updated_at",
    "old_value": null,
    "new_value": "2023-12-21T14:02:54+00:00",
    "updated_at": "December 21, 2023 14:02:54"
  },
];

export const commentLogsMockedList = [
  {
    "id": 13479,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "John Snow",
    "account_id": 6,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Old Note",
    "new_value": "New Note",
    "updated_at": "January 17, 2024 13:43:10"
  },
  {
    "id": 12903,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Benjamin Rodriguez",
    "account_id": 54,
    "company_id": 1,
    "field_name": "email",
    "old_value": "benjamin1@gmail.com",
    "new_value": "newbenjamin@gmail.com",
    "updated_at": "January 11, 2024 16:32:07"
  },
  {
    "id": 12902,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Emma Thompson",
    "account_id": 54,
    "company_id": 1,
    "field_name": "desk_id",
    "old_value": "1",
    "new_value": "13",
    "updated_at": "January 11, 2024 16:32:06"
  },
  {
    "id": 12901,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Mason Reynolds",
    "account_id": 54,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Note",
    "new_value": "New Note",
    "updated_at": "January 11, 2024 16:32:06"
  },
  {
    "id": 12900,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Mia Sullivan",
    "account_id": 54,
    "company_id": 1,
    "field_name": "agent_id",
    "old_value": "5",
    "new_value": "74",
    "updated_at": "January 11, 2024 16:32:01"
  },
  {
    "id": 12676,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Lucas Parker",
    "account_id": 1,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Old Note",
    "new_value": "New Note",
    "updated_at": "January 10, 2024 14:55:44"
  },
  {
    "id": 12675,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Isabella Chang",
    "account_id": 1,
    "company_id": 1,
    "field_name": "first_name",
    "old_value": "isabella",
    "new_value": "Isabella",
    "updated_at": "January 10, 2024 14:55:43"
  },
  {
    "id": 12674,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Noah Hernandez",
    "account_id": 1,
    "company_id": 1,
    "field_name": "note",
    "old_value": "Old Note",
    "new_value": "New Note",
    "updated_at": "January 10, 2024 14:55:41"
  },
  {
    "id": 10881,
    "item_type": "client",
    "item_id": 73,
    "event": "update",
    "account_name": "Aiden Wilson",
    "account_id": 1,
    "company_id": 1,
    "field_name": "note",
    "old_value": "N/A",
    "new_value": "Old Note",
    "updated_at": "January 08, 2024 11:40:14"
  },
  {
    "id": 10604,
    "item_type": "client",
    "item_id": 75,
    "event": "update",
    "account_name": "Abigail Patel",
    "account_id": 1,
    "company_id": 1,
    "field_name": "email",
    "old_value": "N/A",
    "new_value": "abigail@gmail.com",
    "updated_at": "January 04, 2024 14:54:10"
  }
];

export const walletTransactionMockedData = [
  {
    id: 1,
    client_id: 25,
    client_name: 'James Hook',
    company_id: 5,
    status: 1,
    wallet_chain: "Tron",
    contract: "trx",
    amount: "200.25",
    source_id: 21,
    source_address: "TMDoaqghe7GN67w484cZVEuYvn6xCTdKcE",
    destination_id: 35,
    destination_address: "TSGbjjWhXJZhMR9eBZ52fEhoVx7aQQiTw7",
    transaction_hash: "9a7ff5429b9bcfff",
    gas_fee: true,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 2,
    client_id: 30,
    client_name: 'Alice Wonderland',
    company_id: 8,
    status: 2,
    wallet_chain: "Ethereum",
    contract: "eth20",
    amount: "150.75",
    source_id: 24,
    source_address: "0x7a84FEdC4a2c1BbC976E8Fb6B7D41D74FBEe20Ea",
    destination_id: 40,
    destination_address: "0xAbcDef1234567890GhiJklMnopQrStUvWxYz",
    transaction_hash: "b2c8a1df67e89012",
    gas_fee: true,
    retry: true,
    submited: false,
    created_at: new Date(),
  },
  {
    id: 3,
    client_id: 22,
    client_name: 'John Doe',
    company_id: 10,
    status: 3,
    wallet_chain: "Bitcoin",
    contract: "bt",
    amount: "0.005",
    source_id: 35,
    source_address: "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq",
    destination_id: 45,
    destination_address: "3BMEXks93rNwjLMn8J47arSf9mE5g3J1tZ",
    transaction_hash: "c3a1e2d4b5f6a7e8",
    gas_fee: false,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 4,
    client_id: 18,
    client_name: 'Eve Smith',
    company_id: 15,
    status: 1,
    wallet_chain: "Tron",
    contract: "trx20",
    amount: "500.50",
    source_id: 47,
    source_address: "TALRCMzDyiLm3CkUfPfLQ7n1xBbXvBb7vE",
    destination_id: 50,
    destination_address: "TKfEvZ3aBx3EkfTCsbFmyE8cqbtRn6Cqhm",
    transaction_hash: "f9e8d7c6b5a4b3e2",
    gas_fee: true,
    retry: true,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 5,
    client_id: 29,
    client_name: 'Bob Johnson',
    company_id: 12,
    status: 2,
    wallet_chain: "Ethereum",
    contract: "eth",
    amount: "75.30",
    source_id: 35,
    source_address: "0xCaFEda3A2bcD4A5fC5bC6D6a7bB9bA8A8aF67BcD",
    destination_id: 55,
    destination_address: "0xDaBbCccDdEeFf00112233445566778899AaBbCcD",
    transaction_hash: "d2c1b9a8f7e6d5c4",
    gas_fee: false,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 6,
    client_id: 31,
    client_name: 'Grace Taylor',
    company_id: 18,
    status: 3,
    wallet_chain: "Bitcoin",
    contract: "bt",
    amount: "0.01",
    source_id: 18,
    source_address: "bc1qsam0srrr7xfkvy5l643lydnw9re59gtzzwf5zq",
    destination_id: 60,
    destination_address: "3BXYZks93rNwjLMn8J47arSf9mE5g3J1tZ",
    transaction_hash: "e5d4c3b2a1f09e8",
    gas_fee: true,
    retry: true,
    submited: false,
    created_at: new Date(),
  },
  {
    id: 7,
    client_id: 27,
    client_name: 'Charlie Brown',
    company_id: 20,
    status: 1,
    wallet_chain: "Tron",
    contract: "trx",
    amount: "300.75",
    source_id: 34,
    source_address: "TTRaDqghe7GN67w484cZVEuYvn6xCTdKcE",
    destination_id: 65,
    destination_address: "TSGhjjWhXJZhMR9eBZ52fEhoVx7aQQiTw7",
    transaction_hash: "abcd1234efgh5678",
    gas_fee: false,
    retry: true,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 8,
    client_id: 35,
    client_name: 'Olivia Davis',
    company_id: 25,
    status: 2,
    wallet_chain: "Ethereum",
    contract: "eth20",
    amount: "120.50",
    source_id: 62,
    source_address: "0x9a84FEdC4a2c1BbC976E8Fb6B7D41D74FBEe20Ea",
    destination_id: 70,
    destination_address: "0xGhiJklMnopQrStUvWxYzAbcDef1234567890",
    transaction_hash: "1234abcd5678efgh",
    gas_fee: true,
    retry: false,
    submited: false,
    created_at: new Date(),
  },
  {
    id: 9,
    client_id: 23,
    client_name: 'David Evans',
    company_id: 30,
    status: 3,
    wallet_chain: "Bitcoin",
    contract: "bt",
    amount: "0.008",
    source_id: 42,
    source_address: "bc1qzm0srrr7xfkvy5l643lydnw9re59gtzzwf5tyq",
    destination_id: 75,
    destination_address: "3B1234ks93rNwjLMn8J47arSf9mE5g3J1tZ",
    transaction_hash: "6789ijklmnop1234",
    gas_fee: false,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 10,
    client_id: 20,
    client_name: 'Emma White',
    company_id: 35,
    status: 1,
    wallet_chain: "Tron",
    contract: "trx20",
    amount: "450.90",
    source_id: 45,
    source_address: "TLMNqghe7GN67w484cZVEuYvn6xCTdKcE",
    destination_id: 80,
    destination_address: "TSGhhjWhXJZhMR9eBZ52fEhoVx7aQQiTw7",
    transaction_hash: "4567efgh1234abcd",
    gas_fee: true,
    retry: true,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 11,
    client_id: 28,
    client_name: 'Frank Miller',
    company_id: 40,
    status: 2,
    wallet_chain: "Ethereum",
    contract: "eth",
    amount: "80.25",
    source_id: 63,
    source_address: "0xGhAEdC4a2c1BbC976E8Fb6B7D41D74FBEe20Ea",
    destination_id: 85,
    destination_address: "0xZzAaBbCcDdEeFf00112233445566778899",
    transaction_hash: "ijklmnopqrst1234",
    gas_fee: false,
    retry: true,
    submited: false,
    created_at: new Date(),
  },
  {
    id: 12,
    client_id: 33,
    client_name: 'Helen Jones',
    company_id: 45,
    status: 3,
    wallet_chain: "Bitcoin",
    contract: "bt",
    amount: "0.015",
    source_id: 34,
    source_address: "bc1qYm0srrr7xfkvy5l643lydnw9re59gtzzwf5mrt",
    destination_id: 90,
    destination_address: "3BXYZks93rNwjLMn8J47arSf9mE5g3J1tZ",
    transaction_hash: "mnopqrstuv1234abcd",
    gas_fee: true,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 13,
    client_id: 26,
    client_name: 'Ian Smith',
    company_id: 50,
    status: 1,
    wallet_chain: "Tron",
    contract: "trx",
    amount: "600.40",
    source_id: 28,
    source_address: "TUOPqghe7GN67w484cZVEuYvn6xCTdKcE",
    destination_id: 95,
    destination_address: "TSGzxcjWhXJZhMR9eBZ52fEhoVx7aQQiTw7",
    transaction_hash: "5678efgh1234abcd",
    gas_fee: false,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 14,
    client_id: 32,
    client_name: 'Jack Johnson',
    company_id: 55,
    status: 2,
    wallet_chain: "Ethereum",
    contract: "eth20",
    amount: "200.80",
    source_id: 32,
    source_address: "0xZzAaBbCcDdEeFf00112233445566778899Gh",
    destination_id: 100,
    destination_address: "0xYyZzAaBbCcDdEeFf001122334455667788",
    transaction_hash: "qrstuv1234abcd5678",
    gas_fee: true,
    retry: true,
    submited: true,
    created_at: new Date(),
  },
  {
    id: 15,
    client_id: 24,
    client_name: 'Katherine Lee',
    company_id: 60,
    status: 3,
    source_id: 45,
    wallet_chain: "Bitcoin",
    contract: "bt",
    amount: "0.02",
    source_address: "bc1qWm0srrr7xfkvy5l643lydnw9re59gtzzwf5sdr",
    destination_id: 105,
    destination_address: "3B3456ks93rNwjLMn8J47arSf9mE5g3J1tZ",
    transaction_hash: "wxyz1234abcd5678",
    gas_fee: false,
    retry: false,
    submited: true,
    created_at: new Date(),
  },
];

export const leaderboardMockedData = {
  leader_sound_enable: true,
  total_deposit: 54320.75,
  total_deposit_count: 112,
  total_deposit_chart: [5000, 7500, 8000, 6000, 7000, 9000, 10820.75],
  total_goal: 100000,
  remaining_goal: 45679.25,
  complete_percentage: 54.32,
  leader_sound: "leader_sound.mp3",
  agents_list: [
    {
      agent_first_name: "Jane",
      agent_last_name: "Smith",
      agent_id: 1,
      deposit_count: 12,
      deposit_amount: 4500,
      deposit_amount_approved: 4000,
      deposit_amount_pending: 500.5,
      deposit_amount_rejected: 740,
      withdraw_amount: 2500,
      withdraw_amount_approved: 2000,
      withdraw_amount_pending: 500,
      withdraw_amount_rejected: 114,
      total_clients: 13,
      total_positions: 2982.14,
      transaction_chart: [300, 400, 500, 600, 700]
    },
    {
      agent_first_name: "John",
      agent_last_name: "Doe",
      agent_id: 5,
      deposit_count: 18,
      deposit_amount: 8000,
      deposit_amount_approved: 7500,
      deposit_amount_pending: 500,
      deposit_amount_rejected: 280,
      withdraw_amount: 3000,
      withdraw_amount_approved: 2800,
      withdraw_amount_pending: 200,
      withdraw_amount_rejected: 250,
      total_clients: 5,
      total_positions: 20119.74,
      transaction_chart: [500, 600, 700, 800, 900]
    },
    {
      agent_first_name: "Alice",
      agent_last_name: "Johnson",
      agent_id: 6,
      deposit_count: 10,
      deposit_amount: 3000,
      deposit_amount_approved: 2800,
      deposit_amount_pending: 200,
      deposit_amount_rejected: 640,
      withdraw_amount: 1200,
      withdraw_amount_approved: 1100,
      withdraw_amount_pending: 100,
      withdraw_amount_rejected: 300,
      total_clients: 4,
      total_positions: 800,
      transaction_chart: [400, 300, 500]
    },
    {
      agent_first_name: "Ella",
      agent_last_name: "Cooper",
      agent_id: 10,
      deposit_count: 15,
      deposit_amount: 4200,
      deposit_amount_approved: 4000,
      deposit_amount_pending: 200,
      deposit_amount_rejected: 450,
      withdraw_amount: 1500,
      withdraw_amount_approved: 1300,
      withdraw_amount_pending: 200,
      withdraw_amount_rejected: 450,
      total_clients: 2,
      total_positions: 500,
      transaction_chart: [200, 300, 400]
    },
    {
      agent_first_name: "Robert",
      agent_last_name: "White",
      agent_id: 25,
      deposit_count: 20,
      deposit_amount: 7000,
      deposit_amount_approved: 6500,
      deposit_amount_pending: 500,
      deposit_amount_rejected: 800,
      withdraw_amount: 2000,
      withdraw_amount_approved: 1800,
      withdraw_amount_pending: 200,
      withdraw_amount_rejected: 700,
      total_clients: 5,
      total_positions: 100,
      transaction_chart: [500, 400, 600]
    },
    {
      agent_first_name: "Emily",
      agent_last_name: "Johnson",
      agent_id: 52,
      deposit_count: 9,
      deposit_amount: 7000,
      deposit_amount_approved: 6800,
      deposit_amount_pending: 200,
      deposit_amount_rejected: 450,
      withdraw_amount: 3000,
      withdraw_amount_approved: 2800,
      withdraw_amount_pending: 200,
      withdraw_amount_rejected: 800,
      total_clients: 9,
      total_positions: 1201,
      transaction_chart: [1000, 900, 1200]
    },
    {
      agent_first_name: "David",
      agent_last_name: "Brown",
      agent_id: 53,
      deposit_count: 25,
      deposit_amount: 10000,
      deposit_amount_approved: 9500,
      deposit_amount_pending: 5000,
      deposit_amount_rejected: 300,
      withdraw_amount: 4000,
      withdraw_amount_approved: 3500,
      withdraw_amount_pending: 500,
      withdraw_amount_rejected: 150,
      total_clients: 9,
      total_positions: 21200,
      transaction_chart: [800, 1000, 1200]
    },
    {
      agent_first_name: "Sophie",
      agent_last_name: "Adams",
      agent_id: 58,
      deposit_count: 8,
      deposit_amount: 4000,
      deposit_amount_approved: 3800,
      deposit_amount_pending: 200,
      deposit_amount_rejected: 200,
      withdraw_amount: 1500,
      withdraw_amount_approved: 1400,
      withdraw_amount_pending: 100,
      withdraw_amount_rejected: 250,
      total_clients: 4,
      total_positions: 800,
      transaction_chart: [200, 300, 500]
    },
    {
      agent_first_name: "William",
      agent_last_name: "Taylor",
      agent_id: 60,
      deposit_count: 4,
      deposit_amount: 2000,
      deposit_amount_approved: 1800,
      deposit_amount_pending: 200,
      deposit_amount_rejected: 350,
      withdraw_amount: 500,
      withdraw_amount_approved: 400,
      withdraw_amount_pending: 100,
      withdraw_amount_rejected: 200,
      total_clients: 1,
      total_positions: 600,
      transaction_chart: [100, 200]
    },
    {
      agent_first_name: "Olivia",
      agent_last_name: "Martin",
      agent_id: 62,
      deposit_count: 3,
      deposit_amount: 1500,
      deposit_amount_approved: 1400,
      deposit_amount_pending: 100,
      deposit_amount_rejected: 150,
      withdraw_amount: 600,
      withdraw_amount_approved: 500,
      withdraw_amount_pending: 100,
      withdraw_amount_rejected: 250,
      total_clients: 2,
      total_positions: 900,
      transaction_chart: [300, 400]
    },
    {
      agent_first_name: "Henry",
      agent_last_name: "Turner",
      agent_id: 152,
      deposit_count: 1,
      deposit_amount: 500,
      deposit_amount_approved: 400,
      deposit_amount_pending: 100,
      deposit_amount_rejected: 250,
      withdraw_amount: 200,
      withdraw_amount_approved: 150,
      withdraw_amount_pending: 50,
      withdraw_amount_rejected: 80,
      total_clients: 3,
      total_positions: 1100,
      transaction_chart: [50, 100, 150]
    },
    {
      agent_first_name: "Sophia",
      agent_last_name: "Turner",
      agent_id: 167,
      deposit_count: 5,
      deposit_amount: 2500,
      deposit_amount_approved: 2400,
      deposit_amount_pending: 100,
      deposit_amount_rejected: 250,
      withdraw_amount: 1200,
      withdraw_amount_approved: 1100,
      withdraw_amount_pending: 100,
      withdraw_amount_rejected: 350,
      total_clients: 2,
      total_positions: 200,
      transaction_chart: [100, 150, 200]
    },
    {
      agent_first_name: "Ariana",
      agent_last_name: "Lang",
      agent_id: 168,
      deposit_count: 6,
      deposit_amount: 3000,
      deposit_amount_approved: 2800,
      deposit_amount_pending: 200,
      deposit_amount_rejected: 300,
      withdraw_amount: 1500,
      withdraw_amount_approved: 1400,
      withdraw_amount_pending: 100,
      withdraw_amount_rejected: 600,
      total_clients: 5,
      total_positions: 1000,
      transaction_chart: [250, 300, 400]
    }
  ],
  events: [
    {
      id: 1,
      agent_first_name: "Jane",
      agent_last_name: "Smith",
      avatar: "/assets/avatars/avatar-alcides-antonio.png",
      amount: 3500
    },
    {
      id: 2,
      agent_first_name: "John",
      agent_last_name: "Doe",
      avatar: "/assets/avatars/avatar-marcus-finn.png",
      amount: 4700
    },
    {
      id: 3,
      agent_first_name: "Alice",
      agent_last_name: "Johnson",
      avatar: "/assets/avatars/avatar-carson-darrin.png",
      amount: 3000
    }
  ]
};

export const mockedChatData = [
  {
    avatar: "/assets/avatars/avatar-carson-darrin.png",
    created_at: "2024-01-02T17:15:38.065Z",
    id: 246,
    last_message: 'Hello',
    name: "Jone Doe",
    participant_accounts: [{
      first_name: "Navid",
      id: 1,
      last_name: "F."
    }, {
      first_name: "super",
      id: 10,
      last_name: "man123"
    }],
  }, 
  {
    avatar: "/assets/avatars/avatar-marcus-finn.png",
    company_id: 1,
    created_at: "2024-08-27T16:09:25.108Z",
    id: 843,
    last_message: "Nice talking to you",
    name: "Alice Johnson",
    participant_accounts: [{
      first_name: "Navid",
      id: 1,
      last_name: "F."
    }, {
      first_name: "super",
      id: 10,
      last_name: "man123"
    }],
  }
];

export const mockedReport = {
  active_clients: 5,
  avg_bonus_amount: 250.50,
  avg_bonus_change: 3,
  avg_credit_in_amount: 500.75,
  avg_credit_in_change: 2,
  avg_credit_out_amount: 400.60,
  avg_credit_out_change: 1,
  avg_deposit_amount: 1000.00,
  avg_deposit_change: 5,
  avg_ftd_amount: 750.00,
  avg_ftd_change: 4,
  avg_total_wd_amount: 1200.00,
  avg_total_wd_change: 6,
  avg_wd_amount: 300.50,
  avg_wd_change: 2,
  brand_status: [
    {
      status_name: 'Brand A',
      total: 86245,
    },
    {
      status_name: 'Brand B',
      total: 25475,
    },
    {
      status_name: 'Brand C',
      total: 25957,
    },
  ],
  client_geo_report: [
    {
      "country": "US",
      "total": 50
    },
    {
      "country": "CA",
      "total": 45
    },
    {
      "country": "GB",
      "total": 30
    },
    {
      "country": "AU",
      "total": 70
    },
    {
      "country": "IN",
      "total": 65
    }
  ],
  close_position_change: 8,
  close_top_assets: [
    {
      asset_name: 'BTC-USD',
      asset_volume: 86245,
    },
    {
      asset_name: 'APE-USD',
      asset_volume: 25475,
    },
    {
      asset_name: 'AUD-CAD',
      asset_volume: 25957,
    },
    {
      asset_name: 'ADA-USD',
      asset_volume: 68512,
    },
  ],
  closed_leveraged_amount: 15000,
  closed_pnl: 2000,
  closed_position_chart: [780, 680, 350, 480, 680, 720, 450],
  closed_volume: "750.0",
  credit_out_amount: "1250.0",
  credit_out_approved: 7,
  credit_out_count: 10,
  credit_out_pending: 2,
  credit_out_rejected: 1,
  net_deposit: "5000.0",
  net_deposit_approved: 20,
  net_deposit_count: 25,
  net_deposit_pending: 3,
  net_deposit_rejected: 2,
  online_time: 3600,
  open_leveraged_amount: 10000,
  open_pnl: 1500,
  open_position_change: 5,
  open_position_chart: [150, 50, 350, 480, 680, 720, 900],
  open_top_assets: [
    {
      asset_name: 'AUD-CAD',
      asset_volume: 21838,
    },
    {
      asset_name: 'ADA-USD',
      asset_volume: 24957,
    },
    {
      asset_name: 'BTC-USD',
      asset_volume: 12575,
    },
    {
      asset_name: 'APE-USD',
      asset_volume: 15958,
    },
  ],
  open_volume: "600.0",
  total_balance: 15000,
  total_bonus_amount: "3000.0",
  total_bonus_approved: 12,
  total_bonus_count: 15,
  total_bonus_pending: 2,
  total_bonus_rejected: 1,
  total_client_count: 50,
  total_close_positions: 30,
  total_closed_positions: 28,
  total_credit_in_amount: "8000.0",
  total_credit_in_approved: 18,
  total_credit_in_count: 20,
  total_credit_in_pending: 2,
  total_credit_in_rejected: 1,
  total_deposit_amount: "25000.0",
  total_deposit_approved: 40,
  total_deposit_count: 45,
  total_deposit_pending: 3,
  total_deposit_rejected: 2,
  total_ftd: 15,
  total_ftd_amount: "5000.0",
  total_ftd_approved: 12,
  total_ftd_change: 3,
  total_ftd_count: 15,
  total_ftd_pending: 2,
  total_ftd_rejected: 1,
  total_leveraged_volume: 40000,
  total_open_positions: 20,
  total_pending_positions: 5,
  total_pnl: 3000,
  total_volume: 8000,
  total_wd_amount: "15000.0",
  total_wd_approved: 25,
  total_wd_count: 30,
  total_wd_pending: 3,
  total_wd_rejected: 2,
  wd_amount: "500.0",
  wd_approved: 10,
  wd_count: 12,
  wd_pending: 1,
  wd_rejected: 0
};

export const mockedAffilateReport = {
  brand_status: [
    {
      status_name: 'Brand A',
      total: 86245,
    },
    {
      status_name: 'Brand B',
      total: 25475,
    },
  ],
  client_geo_report: [
    {
      "country": "US",
      "total": 50
    },
    {
      "country": "CA",
      "total": 45
    },
    {
      "country": "GB",
      "total": 30
    },
  ],
  client_with_deposit_percentage: 75,
  closed_position_chart: [780, 680, 350, 480, 680, 720, 450],
  close_top_assets: [
    {
      asset_name: 'BTC-USD',
      asset_volume: 86245,
    },
    {
      asset_name: 'APE-USD',
      asset_volume: 25475,
    },
    {
      asset_name: 'AUD-CAD',
      asset_volume: 25957,
    },
    {
      asset_name: 'ADA-USD',
      asset_volume: 68512,
    },
  ],
  closed_leveraged_amount: 1500,
  closed_pnl: 320,
  closed_volume: "12345.67",
  net_deposit_amount: "15000.50",
  net_deposit_count: 25,
  online_client_count: 12,
  open_leveraged_amount: 2000,
  open_pnl: 450,
  open_position_chart: [150, 50, 350, 480, 680, 720, 900],
  open_top_assets: [
    {
      asset_name: 'AUD-CAD',
      asset_volume: 21838,
    },
    {
      asset_name: 'ADA-USD',
      asset_volume: 24957,
    },
    {
      asset_name: 'BTC-USD',
      asset_volume: 12575,
    },
    {
      asset_name: 'APE-USD',
      asset_volume: 15958,
    },
  ],
  open_volume: "54321.76",
  player_avg_value: 500,
  total_balance: 25000,
  total_client_count: 120,
  total_clients: 120,
  total_close_positions: 80,
  total_deposit_amount: "30000.00",
  total_deposit_count: 50,
  total_duplicate_leads: 5,
  total_duplicate_leads_change: 2,
  total_ftd_amount: "12000.00",
  total_ftd_amount_change: 1500,
  total_ftd_count: 30,
  total_ftd_count_change: 5,
  total_invalid_leads: 8,
  total_invalid_leads_change: 3,
  total_leads: 150,
  total_leads_change: 20,
  total_leveraged_volume: 35000,
  total_online_client_change: 10,
  total_open_positions: 45,
  total_pending_positions: 15,
  total_pnl: 820,
  total_verified_leads: 120,
  total_verified_leads_change: 15,
  total_volume: 45000,
  total_wd_amount: "5000.00",
  total_wd_change: 1200,
  total_wd_count: 18
}

export const complianceMockedList = [
  {
    id: 1,
    status: 'Flaged',
    status_color: 'error',
    full_name: 'Jane Smith',
    country: 'UA',
    sentiment: 60,
    company: "Visionary Dynamics Solutions Ltd.",
    emails: ['jane.smith@yahoo.com'],
    phone_numbers: ['20-1234-5678'],
    desk_name: 'Retention',
    desk_color: "#FF5733",
    internal_brand_name: 'Wuckert Inc',
    client_labels: [
      {
        name: 'Conversion',
        color: '#00A76F'
      }
    ],
    campaign: "Momentum Manifesto",
    balance: 750.50,
    affiliate_names: ['Angelique Morse'],
    affiliate_color: "#06AED4",
    agents: [{ name: 'Samuel Thomas', avatar: '/assets/avatars/avatar-alcides-antonio.png', age: 32 }],
    client_teams: [{ name: 'Software Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 2,
    status: 'Approved',
    status_color: 'success',
    full_name: 'John Doe',
    country: 'US',
    sentiment: 80,
    company: "Quantum Innovations Group",
    emails: ['john.doe@hotmail.com'],
    phone_numbers: ['30-9876-5432'],
    desk_name: 'Sales',
    desk_color: "#3366FF",
    internal_brand_name: 'ABC Corp.',
    client_labels: [
      {
        name: 'Sales',
        color: '#637381'
      }
    ],
    campaign: "Unity Uplift Project",
    balance: 500.75,
    affiliate_names: ['Affiliate Y'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Mia Garcia', avatar: '/assets/avatars/avatar-marcus-finn.png', age: 43 }],
    client_teams: [{ name: 'Customer Care' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 3,
    status: 'Notice',
    status_color: 'info',
    full_name: 'Alice Johnson',
    country: 'CA',
    sentiment: 68,
    company: "Nexus Synergy Technologies",
    emails: ['alice.johnson@gmail.com'],
    phone_numbers: ['40-5678-1234'],
    desk_name: 'Support',
    desk_color: "#FFC300",
    internal_brand_name: 'Supportive Solutions',
    client_labels: [
      {
        name: 'Customer Support',
        color: '#06AED4'
      }
    ],
    campaign: "Celestial Harmony Campaign",
    balance: 1200.25,
    affiliate_names: ['Affiliate Z'],
    affiliate_color: "#10B981",
    agents: [{ name: 'Benjamin Clark', avatar: '/assets/avatars/avatar-carson-darrin.png', age: 52 }],
    client_teams: [{ name: 'Support Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 4,
    status: 'Approved',
    status_color: 'success',
    full_name: 'Robert White',
    country: 'GB',
    sentiment: 56,
    company: "AlphaPulse Innovations",
    emails: ['robert.white@gmail.com'],
    phone_numbers: ['50-4321-8765'],
    desk_name: 'Marketing',
    desk_color: "#FF5733",
    internal_brand_name: 'Marketing Pro',
    client_labels: [
      {
        name: 'Marketing',
        color: '#22C55E'
      }
    ],
    campaign: "Galaxy Gala Event",
    balance: 900.75,
    affiliate_names: ['Affiliate Marketing'],
    affiliate_color: "#637381",
    agents: [{ name: 'Ava Martinez', avatar: '/assets/avatars/avatar-fran-perez.png', age: 34 }],
    client_teams: [{ name: 'Marketing Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 5,
    status: 'Caution',
    status_color: 'warning',
    full_name: 'Emily Johnson',
    country: 'AU',
    sentiment: 42,
    company: "Global Horizon Enterprises",
    emails: ['emily.johnson@gmail.com'],
    phone_numbers: ['60-9876-1234'],
    desk_name: 'Development',
    desk_color: "#3366FF",
    internal_brand_name: 'Tech Innovators',
    client_labels: [
      {
        name: 'Development',
        color: '#3366FF'
      }
    ],
    campaign: "Aurora Ascendancy",
    balance: 650.50,
    affiliate_names: ['Tech Solutions'],
    affiliate_color: "#10B981",
    agents: [{ name: 'James White', avatar: '/assets/avatars/avatar-penjani-inyene.png', age: 53 }],
    client_teams: [{ name: 'Development Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 6,
    status: 'Notice',
    status_color: 'info',
    full_name: 'David Brown',
    country: 'US',
    sentiment: 38,
    company: "OptiWave Systems Inc.",
    emails: ['david.brown@hotmail.com'],
    phone_numbers: ['70-5678-4321'],
    desk_name: 'HR',
    desk_color: "#FFC300",
    internal_brand_name: 'People Connect',
    client_labels: [
      {
        name: 'Human Resources',
        color: '#10B981'
      }
    ],
    campaign: "Lunar Luminary Launch",
    balance: 1100.25,
    affiliate_names: ['People Solutions'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Sophia Anderson', avatar: '/assets/avatars/avatar-omar-darboe.png', age: 48 }],
    client_teams: [{ name: 'HR Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 7,
    status: 'Approved',
    status_color: 'success',
    full_name: 'Sophie Adams',
    country: 'CA',
    sentiment: 40,
    company: "Infinity Nexus Holdings",
    emails: ['sophie.adams@gmail.com'],
    phone_numbers: ['80-8765-4321'],
    desk_name: 'IT',
    desk_color: "#FF5733",
    internal_brand_name: 'Tech Masters',
    client_labels: [
      {
        name: 'Information Technology',
        color: '#637381'
      }
    ],
    campaign: "Stellar Impact Drive",
    balance: 800.75,
    affiliate_names: ['Tech Wizards'],
    affiliate_color: "#06AED4",
    agents: [{ name: 'William Taylor', avatar: '/assets/avatars/avatar-siegbert-gottfried.png', age: 28 }],
    client_teams: [{ name: 'IT Support' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 8,
    status: 'Approved',
    status_color: 'success',
    full_name: 'William Taylor',
    country: 'GB',
    sentiment: 58,
    company: "QuantumLeap Dynamics",
    emails: ['william.taylor@outlook.com'],
    phone_numbers: ['90-1234-5678'],
    desk_name: 'Finance',
    desk_color: "#3366FF",
    internal_brand_name: 'Financial Experts',
    client_labels: [
      {
        name: 'Finance',
        color: '#3366FF'
      }
    ],
    campaign: "Zenith Zephyr Campaign",
    balance: 900.50,
    affiliate_names: ['Financial Partners'],
    affiliate_color: "#637381",
    agents: [{ name: 'Emma Wilson', avatar: '/assets/avatars/avatar-iulia-albu.png', age: 40 }],
    client_teams: [{ name: 'Finance Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 9,
    status: 'Notice',
    status_color: 'info',
    full_name: 'Olivia Martin',
    country: 'AU',
    sentiment: 68,
    company: "Synergetic Solutions Ltd.",
    emails: ['olivia.martin@gmail.com'],
    phone_numbers: ['100-5678-1234'],
    desk_name: 'Legal',
    desk_color: "#FFC300",
    internal_brand_name: 'Legal Advisors',
    client_labels: [
      {
        name: 'Legal',
        color: '#637381'
      }
    ],
    campaign: "Radiant Ripple Initiative",
    balance: 1100.25,
    affiliate_names: ['Legal Solutions'],
    affiliate_color: "#10B981",
    agents: [{ name: 'Robert Miller', avatar: '/assets/avatars/avatar-nasimiyu-danai.png', age: 36 }],
    client_teams: [{ name: 'Legal Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 10,
    status: 'Flaged',
    status_color: 'error',
    full_name: 'Henry Turner',
    country: 'US',
    sentiment: 89,
    company: "Astral Innovate Solutions",
    emails: ['henry.turner@gmail.com'],
    phone_numbers: ['110-9876-5432'],
    desk_name: 'Research',
    desk_color: "#FF5733",
    internal_brand_name: 'Research Hub',
    client_labels: [
      {
        name: 'Research',
        color: '#10B981'
      }
    ],
    campaign: "Velocity Vista Promo",
    balance: 850.75,
    affiliate_names: ['Research Partners'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Olivia Davis', avatar: '/assets/avatars/avatar-miron-vitold.png', age: 49 }],
    client_teams: [{ name: 'Research Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 11,
    status: 'Caution',
    status_color: 'warning',
    full_name: 'Ella Cooper',
    country: 'CA',
    sentiment: 24,
    company: "Zenith Dynamics Corporation",
    emails: ['ella.cooper@gmail.com'],
    phone_numbers: ['120-5678-1234'],
    desk_name: 'Quality',
    desk_color: "#FF5733",
    internal_brand_name: 'Quality Experts',
    client_labels: [
      {
        name: 'Quality Assurance',
        color: '#3366FF'
      }
    ],
    campaign: "Nebula Quest Campaign",
    balance: 600.50,
    affiliate_names: ['Quality Solutions'],
    affiliate_color: "#22C55E",
    agents: [{ name: 'Michael Brown', avatar: '/assets/avatars/avatar-miron-vitold.png', age: 42 }],
    client_teams: [{ name: 'QA Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 12,
    status: 'Approved',
    status_color: 'success',
    full_name: 'Jackie Miller',
    country: 'GB',
    sentiment: 45,
    company: "Eclipse Synergy Tech",
    emails: ['jackie.miller@gmail.com'],
    phone_numbers: ['130-1234-5678'],
    desk_name: 'Public Relations',
    desk_color: "#FFC300",
    internal_brand_name: 'PR Masters',
    client_labels: [
      {
        name: 'Public',
        color: '#06AED4'
      }
    ],
    campaign: "Eclipse Innovate Drive",
    balance: 950.25,
    affiliate_names: ['PR Partners'],
    affiliate_color: "#637381",
    agents: [{ name: 'Emily Johnson', avatar: '/assets/avatars/avatar-neha-punita.png', age: 52 }],
    client_teams: [{ name: 'PR Team' }],
    online: false,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 13,
    status: 'Notice',
    status_color: 'info',
    full_name: 'Daniel Turner',
    country: 'AU',
    sentiment: 78,
    company: "Pinnacle Ventures International",
    emails: ['daniel.turner@gmail.com'],
    phone_numbers: ['140-5678-1234'],
    desk_name: 'Security',
    desk_color: "#FF5733",
    internal_brand_name: 'Security Solutions',
    client_labels: [
      {
        name: 'Security',
        color: '#10B981'
      }
    ],
    campaign: "Horizon Harmony Campaign",
    balance: 800.75,
    affiliate_names: ['Security Experts'],
    affiliate_color: "#06AED4",
    agents: [{ name: 'Alex Smith', avatar: '/assets/avatars/avatar-omar-darboe.png', age: 43 }],
    client_teams: [{ name: 'Security Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 14,
    status: 'Flaged',
    status_color: 'error',
    full_name: 'Sophia Turner',
    country: 'US',
    sentiment: 98,
    company: "Omega Nexus Holdings",
    emails: ['sophia.turner@gmail.com'],
    phone_numbers: ['150-9876-5432'],
    desk_name: 'Analytics',
    desk_color: "#3366FF",
    internal_brand_name: 'Data Analytics Pro',
    client_labels: [
      {
        name: 'Analytics',
        color: '#3366FF'
      }
    ],
    campaign: "Quantum Leap Launch",
    balance: 700.50,
    affiliate_names: ['Analytics Solutions'],
    affiliate_color: "#00A76F",
    agents: [{ name: 'Jane Doe', avatar: '/assets/avatars/avatar-penjani-inyene.png', age: 35 }],
    client_teams: [{ name: 'Analytics Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
  {
    id: 15,
    status: 'Caution',
    status_color: 'warning',
    full_name: 'Ariana Lang',
    country: 'SA',
    sentiment: 25,
    company: "Nexus Synergy Technologies",
    emails: ['avery43@hotmail.com'],
    phone_numbers: ['408-439-8033'],
    desk_name: 'Support',
    desk_color: "#FFC300",
    internal_brand_name: 'Supportive Solutions',
    client_labels: [
      {
        name: 'Customer Support',
        color: '#06AED4'
      }
    ],
    campaign: "Skyline Showcase",
    balance: 1200.25,
    affiliate_names: ['Affiliate Z'],
    affiliate_color: "#3366FF",
    agents: [{ name: 'Agent Brown', avatar: '/assets/avatars/avatar-siegbert-gottfried.png', age: 54 }],
    client_teams: [{ name: 'Support Team' }],
    online: true,
    last_online: new Date(),
    created_at: new Date(),
    last_assigned_agent_at: new Date(),
    last_assigned_team_at: new Date(),
    last_assigned_desk_at: new Date(),
    first_lead_campaign: new Date(),
    first_lead_description: new Date(),
  },
];