import { useCallback, useEffect, useMemo, useState } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/hooks/use-popover';
import { NotificationsPopover } from './notifications-popover';
import { userApi } from 'src/api/user';

export const NotificationsButton = () => {
  const popover = usePopover();
  const [notifications, setNotifications] = useState([]);

  const getNotification = useCallback(async () => {
    try {
      const response = await userApi.getNotification();

      setNotifications(response?.notifications);
    } catch (error) {
      console.error("error :", error);
    }
  }, []);

  const setReadNotification = useCallback(async (ids) => {
    try {
      await userApi.setReadNotification({ ids });
      getNotification();
    } catch (error) {
      console.error('error: ', error);
    }
  }, [])

  const unread = useMemo(() => {
    const unreadCount = notifications?.filter(item => item?.seen == false)?.length;
    const lastId = notifications?.at(-1)?.id;
    const prevLastId = localStorage.getItem("last_notification_id");
    localStorage.setItem("last_notification_id", lastId);
    if (lastId != prevLastId) {
      if (unreadCount) popover.handleOpen();
    }
    return unreadCount;
  }, [notifications])

  useEffect(() => {
    getNotification();
  }, [])

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          ref={popover.anchorRef}
          onClick={() => {
            popover.handleOpen();
            getNotification();
          }}
          sx={{ '&:hover': { color: 'primary.main' }}}
        >
          <Badge
            color="error"
            badgeContent={unread}
          >
            <Iconify icon="line-md:bell-loop" width={26}/>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        unread={unread}
        onClose={popover.handleClose}
        setReadNotification={setReadNotification}
        open={popover.open}
      />
    </>
  );
};
